import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Drawer from "react-modern-drawer";
import "react-responsive-modal/styles.css";
import "react-modern-drawer/dist/index.css";
import "../../pages/DragDroplist/DragDropList.css";
import DragDropList from "../DragDroplist/DragDropList";

// import "react-confirm-alert/src/react-confirm-alert.css";

import "react-datepicker/dist/react-datepicker.css";


import Searchicon from '../../assets/images/dashboardimages/search.png'

import deleteicon from '../../assets/images/dashboardimages/delete.png'
import Editicon from '../../assets/images/dashboardimages/pencil.png'
import addicon from '../../assets/images/dashboardimages/product.png'
import Sequentialize from "../../assets/images/dashboardimages/Sequentialize.png";



// import DatePicker from 'react-date-picker';

class DesignList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MaterialData: [],
            open: false,
            view: false,
            PublishDate: "",
            Id: "",
            searchInput: "",
            ViewClick: "true",
            VerticleData: [],
            VariantData: [],

            Vertical: null,
            SearchField: null,

            DesignData: [],
            IsOpen: false,


        };
    }

    // onCloseModal = () => {
    //     this.setState({ open: false });
    //   };

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        Notiflix.Loading.Dots("Please wait...");





        GetApiCall.getRequest("GetProductDropdown").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    VerticleData: obj.data,
                });
            })
        );

        // ======Get Item List==========

        this.getDesign()
    }


    getDesign = () => {
        PostApiCall.postRequest(
            {

                whereClause: '',
                recordCount: '*'
            },
            "GetDesign"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    console.log(obj.data)
                    this.setState({
                        DesignData: obj.data.filter(val => val.fld_status == "Active"),
                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }





    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    toggleDrawer = () => {
        this.setState({
            IsOpen: !this.state.IsOpen,
        });
    };
    render() {
        const { text, suggestions } = this.state;
        // =========VariantList=============
        const designdata = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Design Name",
                    field: "designname",
                    sort: "disabled",
                    width: 400,
                },
                {
                    label: "Product Name",
                    field: "productname",
                    sort: "disabled",
                    width: 400,
                },
                {
                    label: "Variant Name",
                    field: "variantname",
                    sort: "disabled",
                    width: 150,
                },



                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ], // end for variant
            rows: this.state.DesignData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_design_name
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase()) ||

                        `${data.variantName
                            ? data.variantName.toLowerCase()
                            : ""
                            }`.includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    designname: (
                        data.fld_design_name
                    ),
                    productname: (
                        data.productName

                    ),
                    variantname: (
                        data.variantName
                    ),



                    action: (
                        <span>
                            <img src={Editicon} alt="View user" className="btnicons mr-3" onClick={() => {
                                localStorage.setItem(
                                    "DesignDetails",
                                    JSON.stringify(data)
                                );
                                window.location.href = "/add_design/update";
                            }}></img>
                            <img style={{ cursor: "pointer" }} src={deleteicon} alt="View user" className="btnicons" onClick={() => {
                                confirmAlert({
                                    title: "Confirm to Delete",
                                    message: "Are you sure you want to delete design.",
                                    buttons: [
                                        {
                                            label: "Yes",
                                            onClick: () => {
                                                Notiflix.Loading.Dots("");
                                                PostApiCall.postRequest({
                                                    designid: data.fld_design_id,
                                                    status: "Inactive"
                                                }, "UpdateDesignStatus").then((results) =>
                                                    results.json().then((obj) => {
                                                        if (results.status == 200 || results.status == 201) {
                                                            Notiflix.Notify.Success("Design deleted successfully")
                                                            Notiflix.Loading.Remove();
                                                            this.getDesign()
                                                        }
                                                    }))
                                            },
                                        },
                                        {
                                            label: "No",
                                            // onClick: () => alert('Click No')
                                        },
                                    ],
                                });
                            }}></img>

                        </span>
                    )



                }
            })
        }




        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}

                        <Breadcrumbs breadcrumbItem={"Design Master"} urlPath={"/design_master"} />

                        <Row>
                            <Col xl="12">
                                <Card className="pagebackground">
                                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px', }}>
                                        <div className="row my-1">
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <h4 className="FilterCard" style={{ marginTop: '10px' }}>Filter Data By</h4>
                                                </div>
                                            </div>

                                            <div className="col-10">
                                                <div className="position-relative">


                                                    <button
                                                        onClick={() => {

                                                            window.location.href = "/add_design/create";
                                                        }}

                                                        className="btn align-items-center btn Bechofy-btn AddNewBtn"
                                                    >
                                                        Add New Design{" "}
                                                        <span className=""><img src={addicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

                                                    </button>




                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                    <Card style={{ padding: '5px 20px' }} >
                                        <div className="row my-1 ">


                                            <div className="col-4">

                                                <div className="form-group">

                                                    <select className="form-control"
                                                        placeholder="Select Vertical"
                                                        value={this.state.Item}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Item: text.target.value
                                                            },
                                                                () => {
                                                                    Notiflix.Loading.Dots("Please wait...");

                                                                    PostApiCall.postRequest(
                                                                        {

                                                                            whereClause: `where fld_productid=${this.state.Item
                                                                                }`,
                                                                            recordCount: 'fld_variantid as value,fld_variantname as label'
                                                                        },
                                                                        "GetVariantData"
                                                                    ).then((results) =>
                                                                        // const objs = JSON.parse(result._bodyText)
                                                                        results.json().then((obj) => {
                                                                            if (results.status == 200 || results.status == 201) {
                                                                                this.setState({
                                                                                    VariantData: obj.data,
                                                                                }, () => {
                                                                                    PostApiCall.postRequest(
                                                                                        {

                                                                                            whereClause: `where fld_product_id=${this.state.Item
                                                                                                }`,
                                                                                            recordCount: '*'
                                                                                        },
                                                                                        "GetDesign"
                                                                                    ).then((results) =>
                                                                                        results.json().then((obj) => {
                                                                                            if (results.status == 200 || results.status == 201) {
                                                                                                this.setState({
                                                                                                    DesignData: obj.data,

                                                                                                });
                                                                                                Notiflix.Loading.Remove();
                                                                                            }
                                                                                        }))

                                                                                });

                                                                                Notiflix.Loading.Remove();
                                                                            }
                                                                        })
                                                                    );
                                                                }
                                                            )
                                                        }}

                                                    >
                                                        <option value="">
                                                            Select Item
                                                        </option>
                                                        {this.state.VerticleData.map(
                                                            (product) => (
                                                                <option
                                                                    key={product.value}
                                                                    value={product.value}
                                                                >
                                                                    {product.label}
                                                                </option>
                                                            )
                                                        )}

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-4">

                                                <div className="form-group">

                                                    <select className="form-control"
                                                        placeholder="Select Variant"
                                                        value={this.state.Variant}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Variant: text.target.value
                                                            }, () => {
                                                                PostApiCall.postRequest(
                                                                    {

                                                                        whereClause: `where fld_variant_id=${this.state.Variant}`,
                                                                        recordCount: '*'
                                                                    },
                                                                    "GetDesign"
                                                                ).then((results) =>
                                                                    // const objs = JSON.parse(result._bodyText)
                                                                    results.json().then((obj) => {
                                                                        if (results.status == 200 || results.status == 201) {
                                                                            this.setState({
                                                                                DesignData: obj.data,

                                                                            });
                                                                            Notiflix.Loading.Remove();
                                                                        }
                                                                    }))
                                                            })
                                                        }}


                                                    >
                                                        <option value="">
                                                            Select Variant
                                                        </option>
                                                        {this.state.VariantData.map(
                                                            (variant) => (
                                                                <option
                                                                    key={variant.value}
                                                                    value={variant.value}
                                                                >
                                                                    {variant.label}
                                                                </option>
                                                            )
                                                        )}


                                                    </select>
                                                </div>
                                            </div>






                                        </div>
                                    </Card>

                                    <Card >
                                        <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                            <div className="row my-1">
                                                <div className="col-4">
                                                    <div className="form-group" >
                                                        <h4 className="FilterCard" style={{ marginTop: '10px' }}>Design List</h4>
                                                    </div>

                                                </div>
                                                {/* <div className="col-8">
                                                    <div className="Bechofy-text position-relative">
                                                        <h5
                                                            className="Bechofy-text sequ-btn-tooltip"
                                                            id="upload-card"
                                                        >
                                                            <a
                                                                onClick={this.toggleDrawer.bind(this)}
                                                                className="align-items-center mt-1 Bechofy-btn vertical-sequentialize-btn"
                                                                id="app-title"
                                                            >
                                                                <span>
                                                                    <img
                                                                        src={Sequentialize}
                                                                        alt="block user"
                                                                        className="btnicons"
                                                                        style={{ marginRight: "5px" }}
                                                                    ></img>{" "}
                                                                </span>
                                                                Sequentialize
                                                            </a>
                                                        </h5>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                        <CardBody className="pt-0">
                                            <Row>


                                                <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                                    <div class="input-group mb-3">


                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                            onChange={(e) => this.seachBarHandler(e)}
                                                            value={this.state.searchInput}

                                                        />
                                                    </div>
                                                </MDBCol>

                                                <Col md="12">

                                                    {this.state.DesignData.length !== 0 ?
                                                        <MDBDataTable
                                                            hover
                                                            disableRetreatAfterSorting={true}
                                                            striped
                                                            bordered
                                                            data={designdata}
                                                            seachTop={false}
                                                            entriesOptions={[25, 50, 100]}

                                                        >

                                                            <MDBTableHead columns={designdata.columns} />
                                                            <MDBTableBody rows={designdata.rows} />
                                                        </MDBDataTable>
                                                        :
                                                        <p>
                                                            No Data Found
                                                        </p>
                                                    }


                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Card>

                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                        {/* </Card>

							</Col>
						</Row> */}
                    </Container>
                </div >
                {console.log(this.state.VerticleData)}
                <Drawer
                    open={this.state.IsOpen}
                    onClose={this.toggleDrawer.bind(this)}
                    direction="right"
                    lockBackgroundScroll="no"
                    width={450}
                    className="sidebar-verticalmaster drawer"
                >
                    <DragDropList
                        verticalData={this.state.DesignData}
                        verticalDropdown={this.state.VerticleData}
                        for="Vertical"
                        htmlFor="verticle"
                    />
                </Drawer>
            </React.Fragment >
        );
    }
}
export default DesignList;
