import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

class Breadcrumbs extends Component {


    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-6">
                        <div>
                            <ul class="breadcrumbs">
                                <li style={{ fontWeight: "900" }}><a href={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_website}>{JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_companyname}</a></li>
                                <li><a href={this.props.urlPath}>{this.props.breadcrumbItem}</a></li>
                                <li><a href={this.props.urlPath1 ? this.props.urlPath1 : ""}>{this.props.breadcrumbItem1 ? this.props.breadcrumbItem1 : ""}</a></li>
                            </ul>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="AccountExpiry">
                            <p style={{ fontSize: "17px", fontWeight: "600" }}>Account expires in <sapn>XX{" "}</sapn>days <span style={{ fontWeight: "500" }}>(Expiry Date: DD/MM/YYYY)</span></p>
                        </div>
                    </div>


                </div>
            </React.Fragment>
        );
    }
}

export default Breadcrumbs;