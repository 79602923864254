import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";


import Notiflix from "notiflix";
import PostApiCall from "../../../Api";

import moment from "moment";

import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import GtLogo from "../../../assets/images/bechofylogo.png";


class purchaseList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,

			UserData: [],
			Id: "",
			searchInput: "",
			ItemData: [],

			Vendor: [],
			PurchaseOrderDate: null,
			PurchaseOrderNumber: '',
			UploadFile: '',
			ItemName: [],
			Quantity: '',
			VendorData: [],
			PurchasePDf: [],
			ManufacturingDate: null,
			ExpiryDate: null,
			BatchNumber: null,
			ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "/AddPdf",
			StockData: [],
			WarehouseData: [],
			WarehouseName: null
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("");


		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				whereClause: `where fld_status='Active'`,
				recordCount: 'fld_name as label,fld_warehouseid as value'

			},
			"GetWarehouseByState"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data.length != 0) {
						this.setState({
							WarehouseData: obj.data,


						});
					}
				}
			}))


		PostApiCall.postRequest(
			{
				whereClause: "where fld_vendor='True'",

			},
			"Get_VendorDropdown"
		).then((results1) =>
			results1.json().then((obj1) => {
				if (results1.status == 200 || results1.status == 201) {
					this.setState({
						VendorData: obj1.data,
					});

					Notiflix.Loading.Remove();
				}
			})
		);

		Notiflix.Loading.Dots("");


		PostApiCall.postRequest(
			{
				whereClause: '',
				recordCount: "fld_variantid as value,fld_variantname as label"
			},
			"GetVariantData"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						ItemData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))

		this.GetStockList()

	}

	GetStockList = () => {

		Notiflix.Loading.Dots("");


		PostApiCall.postRequest(
			{
				whereClause: `where fld_status ='NEW'`,
				Recordcount: "*"
			},
			"Get_Stock_List_By_Id"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						StockData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))
	}

	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};


	AddMore = () => {

		if (this.state.PurchaseOrderNumber != '') {
			if (this.state.PurchaseOrderDate != null) {
				if (this.state.Vendor != '') {
					if (JSON.stringify(this.state.PurchasePDf) != "[]") {
						if (this.state.ItemName != '') {
							if (this.state.Quantity != '') {
								if (this.state.ManufacturingDate != null) {
									if (this.state.ExpiryDate != null) {
										if (this.state.BatchNumber != null) {
											if (this.state.WarehouseName != null) {
												this.onPost()
											}
											else {
												Notiflix.Notify.Failure('Please select warehouse name');
											}


										}
										else {
											Notiflix.Notify.Failure('Please enter batch number');
										}

									}
									else {
										Notiflix.Notify.Failure('Please select expiry date of item');
									}

								}
								else {
									Notiflix.Notify.Failure("Please select manufacturing date of item");
								}

							}
							else {
								Notiflix.Notify.Failure("Please enter Quantity");
							}

						}
						else {
							Notiflix.Notify.Failure("Please select item name");
						}
					}
					else {
						Notiflix.Notify.Failure("Please upload purchase order file");
					}

				}
				else {
					Notiflix.Notify.Failure("Please select vendor name");
				}
			}
			else {
				Notiflix.Notify.Failure("Please select purchase order date");
			}

		} else {
			Notiflix.Notify.Failure("Please enter purchase order number");
		}
	}

	onPost = () => {

		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		if (JSON.stringify(this.state.PurchasePDf) != "[]") {
			const form = new FormData();

			form.append("file", this.state.PurchasePDf);
			form.append("foldername", "StockPDf");
			form.append(
				"filename", moment().format('hhmmss') + this.state.PurchasePDf.name

			);

			fetch(this.state.ImageApiUrl, {
				method: "POST",
				body: form,
			}).then((image) => {
				image
					.json()
					.then((data) => ({
						data: data,
						status: image.status,
					}))
					.then((res) => {

						PostApiCall.postRequest(
							{
								stockid: null,
								varientid: this.state.ItemName.value,
								quantity: this.state.Quantity,
								costprice: this.state.CP,
								totalamt: this.state.TotalAmount,
								purchse_order_no: this.state.PurchaseOrderNumber,
								purchse_order_date: moment(this.state.PurchaseOrderDate).format('YYYY-MM-DD'),
								stockstatus: 'Purchase Order',
								purchasefile: localStorage.getItem("ClientStoreURL") + "/images/PDF/StockPDf/" +
									res.data.Message.split(",")[2].split("=")[1].trim(),
								userid: details[0].fld_userid,
								action: 'INSERT',
								batchno: this.state.BatchNumber,
								vendorid: this.state.Vendor.value,
								expirydate: moment(this.state.ExpiryDate).format('YYYY-MM-DD'),
								mnfcturedate: moment(this.state.ManufacturingDate).format('YYYY-MM-DD'),
								status: 'NEW',
								warehouseid: this.state.WarehouseName,
								source: 'PURCHASEORDER'

							},
							"Add_Stocks"
						).then((results1) =>
							results1.json().then((obj1) => {
								if (results1.status == 200 || results1.status == 201) {
									// //console.log(obj1)
									Notiflix.Loading.Remove();
									Notiflix.Notify.Success("Stock successfully added.");
									this.GetStockList()
									// window.location.href = "/stock_management";
								}
								else {
									Notiflix.Loading.Remove();
									Notiflix.Notify.Failure(obj1.data);
								}
							})
						);
					});
			});
		}
		else {

			Notiflix.Notify.Failure("Please upload purchase order file");
		}

	}


	render() {
		// //console.log(this.state.StockData)

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}

						<div className="row">
							<div className="col-md-6">

								<div>
									<ul class="breadcrumbs">
										<li><a href={localStorage.getItem("ClientStoreURL")}>store.bechofy.in</a></li>
										<li><a href="/item-management">Stock Management</a></li>
										<li>Add Purchase Order</li>
									</ul>
								</div>

							</div>
							<div className="col-md-6">
								<img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>

							</div>


						</div>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
										<div className="row my-1">
											<div className="col-2">
												<div className="form-group">
													<h4 className="FilterCard" style={{ marginTop: '10px' }}>Add Stock</h4>
												</div>
											</div>


										</div>
									</div>


									<Card className="usercarddata">
										<div className="col col-12" style={{ marginTop: '15px', marginBottom: '15px' }}>
											<div className="row">
												<div className="col-4">
													<div className="form-group">
														<label for="CategoryName">Purchase Order Number<span className="mandatory">*</span></label>
														<input type="text" id="CategoryName" className="form-control" name="Name"
															value={this.state.PurchaseOrderNumber}
															onChange={(text) => {
																this.setState({
																	PurchaseOrderNumber: text.target.value
																})
															}}
														/>
													</div>
												</div>
												<div className="col-4">
													<div className="form-group">
														<label for="CategoryName">Purchase Order Date<span className="mandatory">*</span></label>
														<DatePicker
															placeholderText="Select To Date"
															dateFormat="dd/MM/yyyy"
															selected={this.state.PurchaseOrderDate}
															onChange={(date) => {
																this.setState({
																	PurchaseOrderDate: date
																})
															}}
															isClearable
															className="form-control"
														/>
													</div>
												</div>
												<div className="col-4">
													<div className="form-group">
														<label for="CategoryName">Vendor<span className="mandatory">*</span></label>
														<Select
															options={this.state.VendorData}


															value={this.state.Vendor}
															onChange={(value) => {

																this.setState({

																	Vendor: value
																})

															}}




															isSearchable={true}
															isClearable={true} />
													</div>
												</div>
												<div className="col-6">
													<div className="form-group">
														<label for="CategoryName">Upload File<span className="mandatory">*</span></label>
														<input
															type="file"
															// accept="application/pdf"
															id="CategoryName"
															className="form-control"
															style={{ display: 'block' }}
															onChange={(e) => {
																if (
																	e.target.files[0].size <
																	10000000
																) {
																	const reader = new FileReader();
																	const file =
																		e.target.files[0];
																	reader.onloadend = () => {
																		this.setState({
																			PurchasePDf: file,
																		});
																	};
																	reader.readAsDataURL(file);
																} else {
																	Notiflix.Notify.Failure(
																		"File too large, upload file less than 10 Mb."
																	);
																}
															}}
														/>
													</div>
												</div>
											</div>
										</div>
									</Card>
									<Card className="usercarddata">
										<div className="col col-12" style={{ marginTop: '15px', marginBottom: '15px' }}>
											<div className="row">
												<div className="col-4">
													<div className="form-group">
														<label for="CategoryName">Item<span className="mandatory">*</span></label>
														<Select
															options={this.state.ItemData}


															value={this.state.ItemName}
															onChange={(value) => {

																this.setState({
																	ItemName: value
																})

															}}




															isSearchable={true}
															isClearable={true} />
													</div>
												</div>
												<div className="col-4">
													<div className="form-group">
														<label for="CategoryName">Quantity<span className="mandatory">*</span></label>
														<input type="text" id="CategoryName" className="form-control" name="Name"
															value={this.state.Quantity}
															onChange={(text) => {
																this.setState({
																	Quantity: text.target.value
																})
															}}
														/>
													</div>
												</div>
												<div className="col-4">
													<div className="form-group">
														<label for="CategoryName">Manufacturing Date<span className="mandatory">*</span></label>
														<DatePicker
															placeholderText="Select To Date"
															dateFormat="dd/MM/yyyy"
															selected={this.state.ManufacturingDate}
															onChange={(date) => {
																this.setState({
																	ManufacturingDate: date
																})
															}}
															isClearable
															className="form-control"
														/>
													</div>
												</div>
												<div className="col-4">
													<div className="form-group">
														<label for="CategoryName">Expiry Date<span className="mandatory">*</span></label>
														<DatePicker
															placeholderText="Select To Date"
															dateFormat="dd/MM/yyyy"
															selected={this.state.ExpiryDate}
															onChange={(date) => {
																this.setState({
																	ExpiryDate: date
																})
															}}
															isClearable
															className="form-control"
														/>
													</div>
												</div>
												<div className="col-4">
													<div className="form-group">
														<label for="CategoryName">Batch Number<span className="mandatory">*</span></label>
														<input type="text" id="CategoryName" className="form-control" name="Name"
															value={this.state.BatchNumber}
															onChange={(text) => {
																this.setState({
																	BatchNumber: text.target.value
																})
															}}
														/>
													</div>
												</div>

												<div className="col-4">
													<div className="form-group">
														<label for="CategoryName">Warehouse<span className="mandatory">*</span></label>
														<select id="CategoryName" className="form-control" name="Name"
															value={this.state.WarehouseName}
															onChange={(text) => {
																this.setState({
																	WarehouseName: text.target.value
																})
															}}
														>
															<option value=''>Select Warehouse</option>
															{this.state.WarehouseData.map((data =>
																<option key={data.value} value={data.value}>{data.label}</option>
															))}
														</select>
													</div>
												</div>
											</div>
										</div>
										<Row>
											<Col xs="12">
												<div className="row">
													<div className="col-12">
														<button
															style={{ float: "right", marginBottom: '15px' }}
															className="btn align-items-center Bechofy-btn "
															onClick={this.AddMore}
														>
															Add More
														</button>
													</div>
												</div>
											</Col>
										</Row>

									</Card>


									<Card style={{ display: this.state.StockData.length == 0 ? 'none' : '' }}>
										<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
											<div className="row my-1">
												<div className="col-2">
													<div className="form-group">
														<h4 className="FilterCard" style={{ marginTop: '10px' }}>Item List</h4>
													</div>
												</div>


											</div>
										</div>

										<CardBody className="py-1    my-1">
											<Row>
												<Col xs="12">
													<div className="row">
														<div className="col-12">
															<table class="table table-bordered table-hover table-striped dataTable">

																<thead>
																	<tr>
																		<th>S.No.</th>
																		<th>Purchase Order No.</th>
																		<th>Vendor Name</th>
																		<th>Item Name</th>
																		<th>Quantity</th>
																		<th>Manufacturing Date</th>
																		<th>Expiry Date</th>
																		<th>Batch Number</th>
																		<th>View Purchase Order File</th>
																	</tr>
																</thead>
																<tbody>
																	{this.state.StockData.map((data, i) => (
																		<tr>
																			<td>{i + 1}</td>
																			<td>{data.fld_purchase_order_no}</td>
																			<td>{data.fld_name}</td>
																			<td>{data.fld_variantname}</td>
																			<td>{data.fld_qty}</td>
																			<td>{data.fld_mnfcturedate}</td>
																			<td>{data.fld_expirydate}</td>
																			<td>{data.fld_batchno}</td>
																			<td><a
																				style={{ float: "right" }}
																				className="btn align-items-center Bechofy-btn "
																				href={data.fld_purchase_pdf}
																				target="_blank"
																			>
																				View Document
																			</a></td>

																		</tr>
																	))}

																</tbody>
															</table>

														</div>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>

									<Card style={{ display: this.state.StockData.length == 0 ? 'none' : '' }}>
										<CardBody className="py-1    my-1">
											<Row>
												<Col xs="12">
													<div className="row">
														<div className="col-12">
															<button
																style={{ float: "right" }}
																className="btn align-items-center Bechofy-btn "
																onClick={() => {
																	Notiflix.Loading.Dots("Please wait...");
																	var login = localStorage.getItem("LoginDetail");
																	var details = JSON.parse(login);
																	for (var i = 0; i < this.state.StockData.length; i++) {
																		PostApiCall.postRequest(
																			{
																				stockid: this.state.StockData[i].fld_stock_id,
																				varientid: this.state.StockData[i].fld_variant_id,
																				quantity: this.state.StockData[i].fld_qty,
																				costprice: this.state.StockData[i].fld_cost_price,
																				totalamt: this.state.StockData[i].fld_total_amt,
																				purchse_order_no: this.state.StockData[i].fld_purchase_order_no,
																				purchse_order_date: this.state.StockData[i].fld_purchase_order_date,
																				stockstatus: this.state.StockData[i].fld_stock_status,
																				purchasefile: this.state.StockData[i].fld_purchase_pdf,
																				userid: details[0].fld_userid,
																				action: 'ADDSTOCK',
																				batchno: this.state.StockData[i].fld_batchno,
																				vendorid: this.state.StockData[i].fld_vendorid,
																				expirydate: this.state.StockData[i].fld_expirydate,
																				mnfcturedate: this.state.StockData[i].fld_mnfcturedate,
																				status: 'NEW',
																				warehouseid: null,
																				source: 'PURCHASEORDER'

																			},
																			"Add_Stocks"
																		).then((results1) =>
																			results1.json().then((obj1) => {
																				if (results1.status == 200 || results1.status == 201) {
																					// //console.log(obj1)
																					Notiflix.Loading.Remove();
																					Notiflix.Notify.Success("Stock successfully added.");
																					window.location.reload()
																					// window.location.href = "/stock_management";
																				}
																				else {
																					Notiflix.Loading.Remove();
																					Notiflix.Notify.Failure(obj1.data);
																				}
																			})
																		);
																	}
																}}


															>
																Save Stock
															</button>
														</div>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Card>


								{/* <WelcomeComp /> */}
							</Col>
						</Row>
						{/* </Card>

							</Col>
						</Row> */}
					</Container>
				</div >
			</React.Fragment >
		);
	}
}
export default purchaseList;
