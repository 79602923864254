import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";


import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import Parser from "html-react-parser";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "react-responsive-modal/styles.css";

import Searchicon from '../../assets/images/dashboardimages/search.png'
import GtLogo from "../../assets/images/bechofylogo.png";


class UOMPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            searchInput: "",
            UOMData: [],
            ViewClick: "true",
            open: false,
            Status: "Active",
            UOMName: "",
            openedit: false,
            Id: null,
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("");

        PostApiCall.postRequest(
            {
                WhereClause: '',
                RecordCount: '*'
            },
            "GetUOM"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    //console.log(obj.data)
                    this.setState({
                        UOMData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }

    onSaveData() {
        if (this.state.UOMName != "") {
            this.onPost();
        } else {
            Notiflix.Notify.Failure("Please enter unit of measurement name");
        }
    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");

        var login = localStorage.getItem("LoginDetail");
        var details = JSON.parse(login);

        PostApiCall.postRequest(
            {
                flduomid: this.state.UOMid,
                flduom: this.state.UOMName,
                fldstatus: this.state.Status,
                updated_by: details[0].fld_userid,

            },
            "AddUOM"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    //   //console.log(obj)
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Success("New Unit of Measurement successfully added.");
                    window.location.reload();
                } else {
                    Notiflix.Notify.Failure(obj);
                }
            })
        );
    };





    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <div className="row">
                            <div className="col-md-6">

                                <div>
                                    <ul class="breadcrumbs">
                                        <li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>
                                        <li>Master Management</li>
                                        <li>Unit of Measurement List</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>

                            </div>


                        </div>





                        <Row>

                            <Col xl="12">
                                <Card className="overflow-hidden pagebackground">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="6">
                                                <div className="Bechofy-text p-3">
                                                    <h5 className="Bechofy-text">
                                                        Unit of Measurement List
                                                    </h5>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                    <Card style={{ padding: '10px 20px' }}>
                                        <div className="row my-1 ">


                                            <div className="col-12">
                                                <div class="input-group">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </div>


                                        </div>
                                    </Card>
                                    <Row>
                                        <Col xl="4">
                                            <Card>

                                                <CardBody className="pt-0">
                                                    <Row>
                                                        <Col xs="12">
                                                            {this.state.Id == null ?
                                                                <h5 style={{ marginTop: '12px' }}>Add New Unit of Measurement</h5>
                                                                :
                                                                <h5 style={{ marginTop: '12px' }}>Edit Unit of Measurement</h5>
                                                            }

                                                            <Row className="card-row">
                                                                <Col sm="12">
                                                                    <div className="col-12">
                                                                        <label class="my-1 mr-2">Unit of Measurement Name</label>

                                                                        <input
                                                                            className="form-control"
                                                                            name="name"
                                                                            type="text"
                                                                            placeholder="Enter Unit of Measurement Name"
                                                                            value={this.state.UOMName}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    UOMName: text.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="col-12" style={{ marginTop: '10px' }}>
                                                                        <div className="form-group">
                                                                            <label for="CategoryName">
                                                                                Status
                                                                                <span className="mandatory">*</span>
                                                                            </label>
                                                                            <br />
                                                                            <label class="radio-inline">
                                                                                <input
                                                                                    type="radio"
                                                                                    name="optradio"
                                                                                    //  disabled={!this.state.IsVisible}
                                                                                    checked={
                                                                                        this.state.Status == "Active"
                                                                                            ? true
                                                                                            : false
                                                                                    }

                                                                                    onChange={() => {
                                                                                        this.setState({
                                                                                            Status: "Active",
                                                                                        });
                                                                                    }}
                                                                                />{" "}
                                                                                Active
                                                                            </label>
                                                                            <label
                                                                                class="radio-inline"
                                                                                style={{ marginLeft: "10px" }}
                                                                            >
                                                                                <input
                                                                                    type="radio"
                                                                                    name="optradio"
                                                                                    //  disabled={!this.state.IsVisible}
                                                                                    checked={
                                                                                        this.state.Status == "InActive"
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onChange={() => {
                                                                                        this.setState({
                                                                                            Status: "InActive",
                                                                                        });
                                                                                    }}
                                                                                />{" "}
                                                                                Inactive
                                                                            </label>
                                                                        </div>


                                                                    </div>
                                                                </Col>
                                                                <hr />
                                                            </Row>
                                                            <Row>
                                                                <Col sm="6">
                                                                </Col>
                                                                <Col sm="6">

                                                                    {this.state.Id == null ?
                                                                        <button
                                                                            onClick={this.onSaveData.bind(this)}
                                                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                            style={{ marginTop: "5px", color: "white" }}
                                                                        >
                                                                            Save
                                                                        </button> :
                                                                        <button
                                                                            // onClick={this.UpdateAttribute.bind(this)}
                                                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                            style={{ marginTop: "5px", color: "white" }}
                                                                        >
                                                                            Update
                                                                        </button>
                                                                    }
                                                                </Col>
                                                            </Row>

                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>

                                        </Col>
                                        <Col xl="8">
                                            <Row>


                                                {/* ======Attribute List */}
                                                {this.state.UOMData.filter((data) => {
                                                    if (this.state.searchInput == "") {
                                                        return data;
                                                    }
                                                    if (
                                                        this.state.searchInput !== "" &&
                                                        (data.fld_uom
                                                            .toLowerCase()
                                                            .includes(this.state.searchInput.toLowerCase()) ||

                                                            `${data.fld_status
                                                                ? data.fld_status.toLowerCase()
                                                                : ""
                                                                }`.includes(this.state.searchInput.toLowerCase()))
                                                    ) {
                                                        return data;
                                                    }
                                                }).map((data, i) => (

                                                    <Col xs="4">
                                                        <Card>
                                                            <CardBody className="categoryCard">

                                                                <Row className="card-row">

                                                                    <Col sm="12">
                                                                        <div className="row">
                                                                            <div className="col-12">

                                                                                <p >
                                                                                    <b>UOM Name</b> -{" "}
                                                                                    <span

                                                                                    >
                                                                                        {data.fld_uom}
                                                                                    </span>
                                                                                </p>
                                                                            </div>


                                                                            <div className="col-12" style={{ marginTop: '5px' }}>
                                                                                <div className="form-group">
                                                                                    <p >
                                                                                        <b>Status</b> -{" "}
                                                                                        <span
                                                                                            style={{
                                                                                                color:
                                                                                                    data.fld_status == "Active"
                                                                                                        ? "green"
                                                                                                        : "red",
                                                                                            }}
                                                                                        >
                                                                                            {data.fld_status}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>


                                                                            </div>






                                                                        </div>
                                                                    </Col>
                                                                    <hr />
                                                                </Row>
                                                                <Row>
                                                                    <Col sm="6" className="BrandStatus">

                                                                    </Col>
                                                                    <Col sm="6">

                                                                        <button
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    Status: data.fld_status,

                                                                                    Id: data.fld_uomid,
                                                                                    UOMName: data.fld_uom,
                                                                                });
                                                                            }}
                                                                            className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
                                                                            style={{ marginTop: "5px", color: "white" }}
                                                                        >
                                                                            Edit UOM
                                                                        </button>
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>








                                </Card>
                            </Col>
                        </Row>
                    </Container >
                </div >
            </React.Fragment >
        );
    }
}
export default UOMPage;
