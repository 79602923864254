import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";

import Notiflix from "notiflix";
import PostApiCall from "../../../Api";
import GetApiCall from "../../../GETAPI";


import "react-datepicker/dist/react-datepicker.css";

import Searchicon from '../../../assets/images/dashboardimages/search.png'
import Editicon from '../../../assets/images/dashboardimages/pencil.png'

import GtLogo from '../../../assets/images/bechofylogo.png';
import Uploadicon from '../../../assets/images/dashboardimages/upload-file.png'
import Select from 'react-select';


class PriceList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,

			UserData: [],
			Id: "",
			searchInput: "",
			PriceData: [],
			StateData: [],
			PriceState: '',


			VariantData: [],
			Variant: []
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("");


		PostApiCall.postRequest(
			{
				whereClause: '',
				recordCount: "fld_variantid as value,fld_variantname as label"
			},
			"GetVariantData"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						VariantData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))



		GetApiCall.getRequest("GetPriceState").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					StateData: obj.data,
				});
				Notiflix.Loading.Remove();
			}))
	}


	onPost = () => {


		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				stateid: this.state.PriceState,
				variantid: this.state.Variant.value
			},
			"downloadcsv"
		).then((results) =>

			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						PriceData: obj.data,

					});

					Notiflix.Loading.Remove();
				}
			}))

	}








	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "SKU",
					field: "sku",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Product Name",
					field: "name",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Variant Name",
					field: "variant",
					sort: "disabled",
					width: 400,
				},
				{
					label: "MRP",
					field: "MRP",
					sort: "disabled",
					width: 400,
				},

				{
					label: "Cost Price",
					field: "CostPrice",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Selling Price",
					field: "SellingPrice",
					sort: "disabled",
					width: 400,
				},




				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
				{
					label: "Edit Item",
					field: "edit",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.PriceData.filter((data) => {
				if (this.state.searchInput == "") {
					return data;
				}
				// if (
				// 	this.state.searchInput !== "" &&
				// 	(data.fld_itemname
				// 		.toLowerCase()
				// 		.includes(this.state.searchInput.toLowerCase()) ||
				// 		`${
				// 			data.fld_categoryname ? data.fld_categoryname.toLowerCase() : ""
				// 		}`.includes(this.state.searchInput.toLowerCase()) ||
				// 		`${
				// 			data.fld_subcategoryname
				// 				? data.fld_subcategoryname.toLowerCase()
				// 				: ""
				// 		}`.includes(this.state.searchInput.toLowerCase()))
				// ) {
				// 	return data;
				// }
			}).map((data, i) => {
				return {

					serial: (i + 1),
					sku: (data.SKU),
					name: (data.ProductName),
					variant: (data.VariantName),
					MRP: (data.MRP == null ? 0 : "₹" + data.MRP),
					CostPrice: (data.CostPrice == null ? 0 : "₹" + data.CostPrice),
					SellingPrice: (data.SellingPrice == null ? 0 : "₹" + data.SellingPrice),

					action: (
						<td className="text-center actionuser" style={{ border: '0px!important', paddingTop: '0px' }}>
							<button
								onClick={() => {
									localStorage.setItem("variantid", JSON.stringify(data.VariantId_Dontupdate));
									localStorage.setItem("stateId", JSON.stringify(this.state.PriceState));

									window.location.href = '/addPrice'
								}}
								style={{

									color: 'white'
								}}
								className="btn align-items-center btn Bechofy-btn "
							>
								Add Price
							</button>



						</td>

					),
					edit: (
						<span>


							<img src={Editicon} alt="Edit Variant" className="btnicons" style={{ cursor: "pointer" }} onClick={() => {
								localStorage.setItem("VariantDetails", JSON.stringify(data.VariantId_Dontupdate));
								window.location.href = "/addnewvariant";
							}}></img>

						</span>
					)
				}
			})
		}

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<div className="row">
							<div className="col-md-6">

								<div>
									<ul class="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>
										<li>Price Management</li>
										<li>Price List</li>
									</ul>
								</div>

							</div>
							<div className="col-md-6">
								<img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>
							</div>


						</div>
						<Row>
							<Col xl="12">
								<Card className="pagebackground">
									<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
										<div className="row my-1">
											<div className="col-2">
												<div className="form-group">
													<h4 className="FilterCard" style={{ marginTop: '10px' }}>Filter Data By</h4>
												</div>
											</div>


										</div>
									</div>


									<Card style={{
										paddingLeft: '10px',
										paddingBottom: '10px', paddingRight: '10px'
									}}>
										<div className="row my-1 ">

											<div className="col-4">
												<select className="form-control gridinput"
													value={this.state.PriceState}
													onChange={(text) => {
														this.setState({
															PriceState: text.target.value
														},

														)
													}}

												>
													<option value=''>Select State</option>
													{this.state.StateData.map((data =>
														<option key={data.value} value={data.value}>{data.label}</option>
													))}
												</select>


											</div>
											<div className="col-4">

												<Select
													className="gridinput"
													options={this.state.VariantData}

													placeholder="Select Variant"
													value={this.state.Variant}
													onChange={(value) => {

														this.setState({
															Variant: value
														})

													}}




													isSearchable={true}
													isClearable={true} />


											</div>

											<div className="col-3">
												<button
													onClick={() => {
														if (this.state.PriceState != '') {


															this.onPost()


														}
														else {
															Notiflix.Notify.Failure('Please select state')

														}

													}}

													style={{

														marginTop: "15px",
														color: 'white',
														marginLeft: '0px',
														marginRight: '4px'
													}}
													className="btn align-items-center btn Bechofy-btn "
												>
													Filter & Display
												</button>



											</div>


										</div>
									</Card>
									<Card className="pagebackground">
										<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
											<div className="row my-1">
												<div className="col-2">
													<div className="form-group">
														<h4 className="FilterCard" style={{ marginTop: '10px' }}>Price List</h4>
													</div>
												</div>

												<div className="col-10">
													<div className="position-relative">

														<a href='/uploadprice' style={{
															float: "right",
															marginTop: "10px",
															background: 'white',
															color: 'grey',
															marginRight: '20px'
														}}
															className="btn align-items-center btn Bechofy-btn "
														>
															<span className=""><img src={Uploadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
															Upload Price
														</a>

													</div>
												</div>


											</div>
										</div>



										<Card style={{ padding: '10px 20px' }}>

											<div className="row my-1 ">


												<div className="col-12">
													<div class="input-group">

														<div class="input-group-prepend">
															<span class="input-group-text" id="basic-addon1">
																<span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
															onChange={(e) => this.seachBarHandler(e)}
															value={this.state.searchInput}

														/>
													</div>
												</div>






											</div>
										</Card>

										<Card >

											<CardBody className="pt-0">
												<Row>



													<Col md="12">

														{this.state.PriceData.length != 0 ?
															<MDBDataTable
																hover
																// scrollY
																striped
																bordered
																data={data}
																seachTop={false}
																entriesOptions={[10, 20, 40, 60, 80, 100, 120, 140]}
															>

																<MDBTableHead columns={data.columns} />
																<MDBTableBody rows={data.rows} />
															</MDBDataTable>
															:
															<p>No Data Found</p>}
													</Col>

												</Row>
											</CardBody>
										</Card>


									</Card>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default PriceList;

