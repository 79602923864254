import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";


import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "react-confirm-alert/src/react-confirm-alert.css";

import "react-datepicker/dist/react-datepicker.css";


import Searchicon from '../../assets/images/dashboardimages/search.png'


import Editicon from '../../assets/images/dashboardimages/pencil.png'
import addicon from '../../assets/images/dashboardimages/product.png'



// import DatePicker from 'react-date-picker';

class PCD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MaterialData: [],
            open: false,
            view: false,
            PublishDate: "",
            Id: "",
            searchInput: "",
            ViewClick: "true",
            ProductData: [],
            VariantData: [],

            Vertical: null,
            SearchField: null,

            PSMData: [],


        };
    }

    // onCloseModal = () => {
    //     this.setState({ open: false });
    //   };

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        Notiflix.Loading.Dots("Please wait...");

        localStorage.removeItem('PSMDetails')







        PostApiCall.postRequest(
            {

                whereClause: ` where fld_status = 'Active'`,
                recordCount: '*'
            },
            "GetCoatingPrice"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    console.log(obj.data)
                    this.setState({
                        PSMData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))


    }







    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    render() {
        const { text, suggestions } = this.state;





        // =========VariantList=============
        const PSMData = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Process",
                    field: "Costing",
                    sort: "disabled",
                    width: 400,
                },
                {
                    label: "Coating Type",
                    field: "CoatingType",
                    sort: "disabled",
                    width: 400,
                },
                {
                    label: "Base Price",
                    field: "BasePrice",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "No. of Sheet",
                    field: "nosheet",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Additional Cost Per Sheet",
                    field: "AdditionalCostPerSheet",
                    sort: "disabled",
                    width: 400,
                },

                

                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ], // end for variant
            rows: this.state.PSMData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }if (
					this.state.searchInput !== "" &&
					(data.fld_costingheader
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase()) ||
						`${data.coating_name!=null ? data.coating_name.toLowerCase() : ""
							}`.includes(this.state.searchInput.toLowerCase()) )){
                                return data
                            }

            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    Costing:(
                        data.fld_costingheader
                    ),
                    CoatingType: (
                        data.coating_name
                    ),
                    BasePrice: (
                        data.fld_base_price

                    ),
                    AdditionalCostPerSheet: (
                        data.fld_additional_cost_per_sheet
                    ),
                    nosheet:(
                        data.fld_no_of_sheets
                    ),
                  
                    action: (
                        <span>
                            <img src={Editicon} alt="View user" className="btnicons" onClick={() => {
                                localStorage.setItem(
                                    "CPDetails",
                                    JSON.stringify(data)
                                );
                                window.location.href = "/add_coating_price/update";
                            }}></img>

                        </span>
                    )



                }
            })
        }




        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}

                        <Breadcrumbs breadcrumbItem={"Costing Price Master"} urlPath={"/costing_price_master"} />

                        <Row>
                            <Col xl="12">
                                <Card>
                                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px', }}>
                                        <div className="row my-1">
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <h4 className="FilterCard" style={{ marginTop: '10px', marginLeft: '5%'}}>Costing Price List</h4>
                                                </div>
                                            </div>

                                            <div className="col-9">
                                                <div className="position-relative">


                                                    <button
                                                        onClick={() => {

                                                            window.location.href = "/add_coating_price/create";
                                                        }}

                                                        className="btn align-items-center btn Bechofy-btn AddNewBtn"
                                                    >
                                                        Add New Costing Price{" "}
                                                        <span className=""><img src={addicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

                                                    </button>




                                                </div>
                                            </div>


                                        </div>
                                    </div>





                                    <CardBody className="pt-0">
                                        <Row>


                                            <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                                <div class="input-group mb-3">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </MDBCol>

                                            <Col md="12">

                                                {this.state.PSMData.length !== 0 ?
                                                    <MDBDataTable
                                                        hover
                                                        // scrollY
                                                        striped
                                                        bordered
                                                        data={PSMData}
                                                        seachTop={false}
                                                        entriesOptions={[25, 50, 100]}

                                                    >

                                                        <MDBTableHead columns={PSMData.columns} />
                                                        <MDBTableBody rows={PSMData.rows} />
                                                    </MDBDataTable>
                                                    :
                                                    <p>
                                                        No Data Found
                                                    </p>
                                                }


                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>


                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                        {/* </Card>

							</Col>
						</Row> */}
                    </Container>
                </div >
            </React.Fragment >
        );
    }
}
export default PCD;
