import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import warehouseicon from '../../assets/images/dashboardimages/warehouse.png'
class WarehouseList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			WarehouseData: [],
			Id: "",
			searchInput: "",
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("");



		PostApiCall.postRequest(
			{
				whereClause: '',
				recordCount: '*'
			},
			"GetWarehouseByState"
		).then((results) =>
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					console.log(obj.data)
					if (obj.data.length != 0) {

						this.setState({
							WarehouseData: obj.data,
						});
					}
					Notiflix.Loading.Remove();
				}
			}))
	}


	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Warehouse Name",
					field: "warehouse_name",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Contact Person Name",
					field: "person_name",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Contact Person Email",
					field: "email",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Country",
					field: "country",
					sort: "disabled",
					width: 200,
				},
				{
					label: "State",
					field: "state_country",
					sort: "disabled",
					width: 200,
				},
				{
					label: "City",
					field: "city",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Pincode",
					field: "pincode",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Status",
					field: "status",
					sort: "disabled",
					width: 200,
				},
				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],

			rows: this.state.WarehouseData.filter((data) => {
				if (this.state.searchInput == "") {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_name
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_contact_person
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_contact_email
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}
				// if (
				// 	this.state.searchInput !== "" &&
				// 	(data.countryName
				// 		.toLowerCase()
				// 		.includes(this.state.searchInput.toLowerCase())
				// 	)
				// ) {
				// 	return data;
				// }
				// if (
				// 	this.state.searchInput !== "" &&
				// 	(data.stateName
				// 		.toLowerCase()
				// 		.includes(this.state.searchInput.toLowerCase())
				// 	)
				// ) {
				// 	return data;
				// }
				// if (
				// 	this.state.searchInput !== "" &&
				// 	(data.cityName
				// 		.toLowerCase()
				// 		.includes(this.state.searchInput.toLowerCase())
				// 	)
				// ) {
				// 	return data;
				// }
				if (
					this.state.searchInput !== "" &&
					(data.fld_status
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase())
					)
				) {
					return data;
				}

			}).map((data, i) => {
				return {
					serial: (i + 1),
					warehouse_name: (data.fld_name),
					person_name: (data.fld_contact_person),
					email: (data.fld_contact_email),
					country: (data.countryname),
					state_country: (data.statename),
					city: (data.cityname),
					pincode: (data.fld_pincode),
					status: (data.fld_status),
					action: (
						<i
							className="fas fa-edit btn"
							style={{ fontSize: "15px", marginTop: "-11px" }}
							onClick={() => {
								localStorage.setItem("warehouseId", JSON.stringify(data));
								window.location.href = "/add_warehouse";
							}}
						></i>

					),
				};
			}),
		};

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs breadcrumbItem={"Warehouse List"} urlPath={"/warehouse_master"} />

						<Row>
							<Col xl="12">

								<Card className="overflow-hidden">
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
													<div className="row my-1">
														<div className="col-2">
															<div className="form-group">
																<h4 className="FilterCard" style={{ marginTop: '15px' }}>Warehouse List</h4>
															</div>
														</div>
														<div className="col-10">
															<button
																onClick={() => {
																	window.location.href = "/add_warehouse";
																}}

																className="btn align-items-center btn Bechofy-btn AddNewBtn"
															>
																Add New Warehouse{" "}
																<span className=""><img src={warehouseicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

															</button>
														</div>

													</div>

												</div>

												<Card className="overflow-hidden">
													<Row>
														<Col xs="12">
															<div className="Bechofy-text  p-3">
																<input
																	className="form-control"
																	type="text"
																	placeholder="Search"
																	aria-label="Search"
																	onChange={(e) => this.seachBarHandler(e)}
																	value={this.state.searchInput}
																/>
															</div>
														</Col>


													</Row>




													<CardBody className="pt-0">
														<Row>
															<MDBCol md="6" style={{ marginBottom: "10px" }}>

															</MDBCol>

															<Col md="12">
																{/* <MDBDataTableV5
																// scrollX
																// scrollY
																// maxHeight="200px"
																striped
																bordered
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																> */}
																<MDBDataTable
																	hover
																	scrollY
																	striped
																	bordered
																	data={data}
																	seachTop={false}
																>

																	<MDBTableHead columns={data.columns} />
																	<MDBTableBody rows={data.rows} />
																</MDBDataTable>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
								</Card>

							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default WarehouseList;
