import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";




class AddCP extends Component {
    constructor(props) {
        super(props);
        const { action } = props.match.params;
        this.state = {
            Status: "Yes",
            CoatingName: null,
            BasePrice: null,
            Sheet: null,
            AdditonalCost: null,
            DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
            Action: action,
            CoatingData: [],
            CoatingPriceID: null,
            Costingheader: null,
            CostingData: []


        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {
                recordCount: '*',
                whereClause: ``,

            },
            "GetCoastingHead"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    console.log(obj.data)
                    this.setState({
                        CostingData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))

        // PostApiCall.postRequest(
        //     {
        //         recordCount: 'fld_coating_name as label,fld_coating_id as value',
        //         whereClause: `where fld_status='Active'`,

        //     },
        //     "GetCoatingdata"
        // ).then((results) =>
        //     // const objs = JSON.parse(result._bodyText)
        //     results.json().then((obj) => {
        //         if (results.status == 200 || results.status == 201) {

        //             this.setState({
        //                 CoatingData: obj.data,

        //             });
        //             Notiflix.Loading.Remove();
        //         }
        //     }))


        var det = localStorage.getItem("CPDetails");
        var CoatingPriceData = JSON.parse(det);

        if (CoatingPriceData != null) {
            if (this.state.Action == "update") {
                this.setState({
                    CoatingName: CoatingPriceData.fld_coating_type,
                    BasePrice: CoatingPriceData.fld_base_price,
                    Sheet: CoatingPriceData.fld_no_of_sheets,
                    AdditonalCost: CoatingPriceData.fld_additional_cost_per_sheet,
                    CoatingPriceID: CoatingPriceData.fld_cal_dtl_id,
                    Costingheader: CoatingPriceData.fld_cal_hdr_id

                },()=>{
                    if (CoatingPriceData.fld_cal_hdr_id == 2 || CoatingPriceData.fld_cal_hdr_id == 3) {
                        PostApiCall.postRequest(
                            {
                                recordCount: 'fld_coating_name as label,fld_coating_id as value',
                                whereClause: `where fld_status='Active'`,

                            },
                            "GetCoatingdata"
                        ).then((results) =>
                            // const objs = JSON.parse(result._bodyText)
                            results.json().then((obj) => {
                                if (results.status == 200 || results.status == 201) {

                                    this.setState({
                                        CoatingData: obj.data,

                                    });
                                    Notiflix.Loading.Remove();
                                }
                            }))

                    }

                });
            }

        }
    }

    // ==Image Upload onChange Function=======




    onSaveData() {
        
           if (this.state.BasePrice != null) {
                if (this.state.Sheet != null) {
                    if (this.state.AdditonalCost != null) {
                        this.onPost();

                    }
                    else {
                        Notiflix.Notify.Failure("Please enter the additional cost per sheet");
                    }
                }
                else {
                    Notiflix.Notify.Failure("Please enter number of sheet");
                }
            } else {
                Notiflix.Notify.Failure("Please enter the Base Price");
            }
        // } else {
        //     Notiflix.Notify.Failure("Please select coating name");
        // }

    }

    onPost = () => {
        Notiflix.Loading.Dots("Please wait...");
        PostApiCall.postRequest(
            {
                caldtlid: this.state.CoatingPriceID,
                calhdrid: this.state.Costingheader,
                coatingtype: this.state.CoatingName,
                baseprice: this.state.BasePrice,
                noofsheets: this.state.Sheet,
                additionalcostpersheet: this.state.AdditonalCost

            },
            "ProductPriceCalculationDetails"
        ).then((resultcategory) =>
            resultcategory.json().then((obj) => {
                if (resultcategory.status == 200 || resultcategory.status == 201) {
                    Notiflix.Loading.Remove()
                    Notiflix.Notify.Success("Costing Price successfully added.")
                    window.location.href = "/price_calculation_header";

                } else {
                    Notiflix.Loading.Remove();
                    Notiflix.Notify.Failure(obj.data);
                }
            })
        );



    }









    render() {
        // //console.log(this.state.imagePreviewUrl)

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs breadcrumbItem={"Costing Price Master"} breadcrumbItem1={this.state.CoatingPriceID == null ? "Add New Costing Price" : "Update Costing Price"} urlPath={"/costing_price_master"} urlPath1={this.state.CoatingPriceID == null ? "/add_coating_price/create" : "/add_coating_price/update"} />
                        <Row>
                            <Col xl="12">


                                <Card className="overflow-hidden">
                                    <div className="Bechofy-bg-soft-pink">
                                        <Row>
                                            <Col xs="3">
                                                <div className="Bechofy-text p-3">
                                                    {this.state.CoatingPriceID == null ?
                                                        <h5 className="Bechofy-text">
                                                            Add New Costing Price
                                                        </h5>
                                                        :
                                                        <h5 className="Bechofy-text">
                                                            Update Costing Price
                                                        </h5>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <Row>
                                            <Col xs="12">
                                                <div className="form my-4">
                                                    <div className="row  ">
                                                        <div className="col col-12">
                                                            <div className="row">

                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="SheetSize">
                                                                            Costing Header
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <select className="form-control"
                                                                            value={this.state.Costingheader}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    Costingheader: text.target.value
                                                                                }, () => {
                                                                                    if (this.state.Costingheader == 2 || this.state.Costingheader==3) {
                                                                                        PostApiCall.postRequest(
                                                                                            {
                                                                                                recordCount: 'fld_coating_name as label,fld_coating_id as value',
                                                                                                whereClause: `where fld_status='Active'`,

                                                                                            },
                                                                                            "GetCoatingdata"
                                                                                        ).then((results) =>
                                                                                            // const objs = JSON.parse(result._bodyText)
                                                                                            results.json().then((obj) => {
                                                                                                if (results.status == 200 || results.status == 201) {
              
                                                                                                    this.setState({
                                                                                                        CoatingData: obj.data,

                                                                                                    });
                                                                                                    Notiflix.Loading.Remove();
                                                                                                }
                                                                                            }))

                                                                                    }
                                                                                    else{
                                                                                        this.setState({
                                                                                            CoatingData:[],
                                                                                            CoatingName:null

                                                                                        }); 
                                                                                    }
                                                                                })
                                                                            }}
                                                                        >
                                                                            {this.state.CostingData.map(data =>
                                                                                <option key={data.fld_cal_hdr_id} value={data.fld_cal_hdr_id}>{data.fld_name}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                                <div className="col-3" style={{ display: this.state.Costingheader == 2 || this.state.Costingheader == 3?'':'none'}}>
                                                                    <div className="form-group">
                                                                        <label for="SheetSize">
                                                                            Coating Name
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <select className="form-control"
                                                                            value={this.state.CoatingName}
                                                                            onChange={(text) => {
                                                                                this.setState({
                                                                                    CoatingName: text.target.value
                                                                                })
                                                                            }}
                                                                        >
                                                                            {this.state.CoatingData.map(data =>
                                                                                <option key={data.value} value={data.value}>{data.label}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="BasePrice">
                                                                            Base Price
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="BasePrice"
                                                                            className="form-control"
                                                                            value={this.state.BasePrice}
                                                                            onChange={(text) => {
                                                                                if (this.state.DecimalRegex.test(text.target.value)) {
                                                                                    this.setState({
                                                                                        BasePrice: text.target.value,
                                                                                    });
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="Sheet">
                                                                            No. of Sheet
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="Sheet"
                                                                            className="form-control"
                                                                            value={this.state.Sheet}
                                                                            onChange={(text) => {
                                                                                if (this.state.DecimalRegex.test(text.target.value)) {
                                                                                    this.setState({
                                                                                        Sheet: text.target.value,
                                                                                    })
                                                                                };
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="form-group">
                                                                        <label for="AdditonalCost">
                                                                            Additional Cost Per Sheet
                                                                            <span className="mandatory">*</span>
                                                                        </label>
                                                                        <input
                                                                            type="text"
                                                                            id="AdditonalCost"
                                                                            className="form-control"
                                                                            value={this.state.AdditonalCost}
                                                                            onChange={(text) => {
                                                                                if (this.state.DecimalRegex.test(text.target.value)) {
                                                                                    this.setState({
                                                                                        AdditonalCost: text.target.value,
                                                                                    })
                                                                                };
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody className="py-1 my-1">
                                        <Row>
                                            <Col xs="12">
                                                <div className="row">
                                                    <div className="col-3 offset-9">
                                                        <button
                                                            style={{ float: "right" }}
                                                            className="btn align-items-center Bechofy-btn "
                                                            onClick={this.onSaveData.bind(this)}
                                                        >
                                                            {this.state.CoatingPriceID == null ? <span>Save Coating Price</span> : <span>Update Coating Price</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    {/*                
                </Card>
       
                              
                               
                               
                            </Col>
                               </Row> */}
                                    {/* </Card> */}
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default AddCP;