import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Modal } from "react-responsive-modal";


class DeliveryManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            OrderData: [],
            FromDate: null,
            ToDate: null,
            searchInput: "",
            DeliveryStatusData: [
                {
                    label: "Delivered",
                    value: "Delivered"
                },
                {
                    label: "Not Delivered",
                    value: "Not Delivered"
                }
            ],
            DeliveryStatus: "",
            DispatchTypeData: [
                { label: 'Self Dispatch', value: 'Self Dispatch' },
                { label: 'Courier Partner', value: 'Courier Partner' },

            ],
            DispatchData: null,
            open: false,
            DispatchDate: null,
            ShippingDate: null,
            TrackingNumber: null,
            DispatchId: null,
            ItemDetails: [],
            OrderNumber: null,
            Email: null
        }
    }
    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });

        this.getDeliveryDetails()



    }

    getDeliveryDetails = () => {
        Notiflix.Loading.Dots("");
        PostApiCall.postRequest(
            {
                whereClause: 'Order By fld_dispatch_id DESC',
                select: ` distinct fld_delivery_challan,fld_order_number,fld_order_date,customer_name,total_items,fld_payment_status,
                fld_order_status,fld_dispatch_id,fld_order_amount,fld_dispatch_status,Total_Inclusive_Tax,fld_email,fld_total_to_bepaid,Shipping_Charg,Shipping_Charg_gst   `

            },
            "GetDispatchDetails"
        ).then((results4) =>
            results4.json().then((obj4) => {
                if (results4.status == 200 || results4.status == 201) {
                    console.log(obj4.data);
                    this.setState({
                        OrderData: obj4.data,
                        DispatchId: obj4.data[0].fld_dispatch_id.Amount,
                        Email: obj4.data[0].fld_email
                    }
                    );

                    Notiflix.Loading.Remove();
                }
            })
        );
    }




    onPost = () => {
        Notiflix.Loading.Dots("");
        PostApiCall.postRequest(
            {
                WhereClause: `where convert(datetime,fld_order_date,103) BETWEEN cast('${moment(this.state.FromDate == null ? null : this.state.FromDate).format('MM-DD-YYYY')}' as date)
    AND cast('${moment(this.state.ToDate == null ? null : this.state.ToDate).format('MM-DD-YYYY')}' as date)`

            },
            "GetDispatchDetails"
        ).then((results4) =>
            results4.json().then((obj4) => {
                if (results4.status == 200 || results4.status == 201) {
                    this.setState({
                        OrderData: obj4.data,
                    });

                    Notiflix.Loading.Remove();
                }
            })
        );
    }

    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };

    sendMailer = () => {

        PostApiCall.postRequest({
            orderid: this.state.OrderNumber,
            disptachdate: moment(this.state.DispatchDate).format("DD/MM/YYYY"),
            trackingno: this.state.TrackingNumber,
            shippingdate: moment(this.state.ShippingDate).format("DD/MM/YYYY"),
            total_amount: this.state.Amount,
            payment_mode: this.state.Payment_Mode == "COD" ? "Cash On Delivery" : this.state.Payment_Mode,
            email: this.state.Email,
        }, "Mailer_Dispatch").then((result) => {
            Notiflix.Loading.Remove();
            Notiflix.Notify.Success('Mail has been successfully sent to the customer with tracking details.')
            window.location.reload()
        }
        )
    }

    sendMailerDelivered = () => {
        console.log(this.state.Email)
        PostApiCall.postRequest({
            orderid: this.state.OrderNumber,
            deliverdate: moment().format("DD/MM/YYYY"),
            email: this.state.Email,
        }, "Mailer_Delivered").then((result) => {
            Notiflix.Loading.Remove();
            Notiflix.Notify.Success('Mail has been successfully sent to the customer.')
            this.getDeliveryDetails()
        }
        )
    }

    render() {
        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "sn",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Dispatch Number",
                    field: "deliveryNumber",
                    sort: 'enable',
                    width: 200,
                },
                {
                    label: "Order Number",
                    field: "orderno",
                    sort: 'enable',
                    width: 200,
                },
                {
                    label: "Order Date",
                    field: "order",
                    sort: 'enable',
                    width: 200,
                },
                {
                    label: "Customer Name",
                    field: "name",
                    sort: 'enable',
                    width: 100,
                },
                {
                    label: "Number of Items",
                    field: "items",
                    sort: 'enable',
                    width: 150,
                },
                {
                    label: "Order Value",
                    field: "value",
                    sort: 'enable',
                    width: 100,
                },
                {
                    label: "Payment Status",
                    field: "paymentstatus",
                    sort: 'enable',
                    width: 100,
                },
                {
                    label: "Order Status",
                    field: "orderstatus",
                    sort: 'enable',
                    width: 100,
                },
                {
                    label: "Delivery Status",
                    field: "deliverystatus",
                    sort: 'enable',
                    width: 200,
                },
                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ],
            rows: this.state.OrderData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.customer_name
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_order_number
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_payment_status
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_order_status
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase())
                    )
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    sn: (i + 1),
                    deliveryNumber: (data.fld_delivery_challan),
                    orderno: (data.fld_order_number),
                    order: (data.fld_order_date),
                    name: (data.customer_name),
                    items: (data.total_items),
                    value: (parseFloat(data.fld_total_to_bepaid + data.Shipping_Charg + data.Shipping_Charg_gst).toFixed(2)),
                    paymentstatus: (data.Payment_Mode == "COD" ? "Cash On Delivery" : data.fld_payment_status),
                    orderstatus: (data.fld_order_status),
                    deliverystatus: <div className="form-group">
                        <select
                            class="custom-select my-1 mr-sm-2"
                            value={data.fld_dispatch_status}
                            onChange={(text) => {
                                this.setState({
                                    DeliveryStatus: text.target.value,
                                    OrderNumber: data.fld_order_number
                                },
                                    () => {
                                        if (this.state.DeliveryStatus == "Delivered") {
                                            Notiflix.Loading.Dots()
                                            PostApiCall.postRequest({
                                                dispatchid: data.fld_dispatch_id,
                                                deliverystatus: this.state.DeliveryStatus
                                            },
                                                "UpdateDeliveryStatus"
                                            ).then((resultcategory) =>
                                                resultcategory.json().then((obj) => {
                                                    if (resultcategory.status == 200 || resultcategory.status == 201) {
                                                        Notiflix.Loading.Remove();
                                                        Notiflix.Notify.Success("Order status updated successfully")
                                                        this.sendMailerDelivered()
                                                        // this.getDeliveryDetails()
                                                    } else {
                                                        Notiflix.Notify.Failure(obj)
                                                    }

                                                })
                                            );
                                        } else {
                                            Notiflix.Loading.Dots()
                                            PostApiCall.postRequest({
                                                dispatchid: data.fld_dispatch_id,
                                                deliverystatus: this.state.DeliveryStatus
                                            },
                                                "UpdateDeliveryStatus"
                                            ).then((resultcategory) =>
                                                resultcategory.json().then((obj) => {
                                                    if (resultcategory.status == 200 || resultcategory.status == 201) {
                                                        Notiflix.Loading.Remove();
                                                        Notiflix.Notify.Success("Order status updated successfully")
                                                        this.getDeliveryDetails()
                                                    } else {
                                                        Notiflix.Notify.Failure(obj)
                                                    }

                                                })
                                            );
                                        }

                                    }
                                )
                            }}
                        >
                            <option>Select Status   </option>
                            {
                                this.state.DeliveryStatusData.map((data, i) => {
                                    return (
                                        <option
                                            key={data.value}
                                            value={data.value}
                                        >
                                            {data.label}
                                        </option>
                                    )
                                })
                            }
                        </select>
                    </div>,
                    action: (
                        <div>

                            <div className="view-invoice">
                                <button
                                    className="btn align-items-center btn globalTrendzEcomm-btn"
                                    style={{ backgroundColor: "#777f80", color: "#fff" }}
                                    onClick={() => {
                                        window.location.href = `/view-invoice/${data.fld_dispatch_id}`
                                    }}
                                >view invoice</button>

                                <span><button
                                    className="btn align-items-center btn globalTrendzEcomm-btn mt-2"
                                    style={{ backgroundColor: "#777f80", color: "#fff" }}
                                    onClick={() => {
                                        //localStorage.setItem("Dispatchdetails", JSON.stringify(data.fld_order_id));
                                        window.location.href = `/view-delivery-chalan/${data.fld_dispatch_id}`
                                    }}
                                >View Delivery Chalan</button></span>
                                <span><button
                                    className="btn align-items-center btn globalTrendzEcomm-btn mt-2"
                                    style={{ backgroundColor: "#777f80", color: "#fff" }}
                                    onClick={() => {
                                        this.setState({
                                            open: true,
                                            DispatchId: data.fld_dispatch_id,
                                            OrderNumber: data.fld_order_number

                                        }, () => {

                                            PostApiCall.postRequest(
                                                {
                                                    whereClause: `where fld_dispatch_id=${this.state.DispatchId}`,
                                                    select: "*"
                                                },
                                                "GetDispatchDetails"
                                            ).then((results) =>
                                                // const objs = JSON.parse(result._bodyText)
                                                results.json().then((obj) => {
                                                    if (results.status == 200 || results.status == 201) {
                                                        console.log(obj.data)
                                                        this.setState({
                                                            ItemDetails: obj.data,
                                                            Email: obj.data[0].fld_email

                                                        });

                                                        Notiflix.Loading.Remove();
                                                    }
                                                }))

                                        });
                                    }}
                                >Add tracking Details</button></span>
                            </div>

                        </div>
                    )
                    //< td > {
                    //     data.fld_payment_status == "Payment Initiated" ? <span><i
                    //         className="fas fa-eye-slash btn"
                    //         style={{ fontSize: "15px", paddingTop: '2px', cursor: "auto" }}


                    //     ></i></span> : <span><i
                    //         className="fas fa-eye btn"
                    //         style={{ fontSize: "15px", paddingTop: '2px' }}
                    //         onClick={() => {
                    //             //localStorage.setItem("Dispatchdetails", JSON.stringify(data.fld_order_id));
                    //             window.location.href = `/view-delivery-chalan/${data.fld_dispatch_id}`
                    //         }}
                    //     ></i></span>
                    // }</td>
                };
            }),
        };

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Modal
                            open={this.state.open}
                            onClose={() => {
                                this.setState({ open: false });
                            }}
                            center
                        >
                            <div class="modal-content modelcontent2">
                                <div
                                    class="modal-header Bechofy-bg-soft-pink"
                                    style={{ background: "#777f80" }}
                                >
                                    <h4 class="modal-title text-white">
                                        Add Tracking Details
                                    </h4>
                                </div>
                                <div class="modal-body">
                                    <div className="row">
                                        <div className="col-12 mb-3">

                                            <div className="form-group my-1 mr-sm-2">
                                                {/* <label>Dispatch</label> */}
                                                <select className="form-control"
                                                    value={this.state.DispatchData}
                                                    onChange={(text) => {
                                                        this.setState({
                                                            DispatchData: text.target.value
                                                        })
                                                    }}

                                                >
                                                    <option value=''>Select Dispatch Type</option>
                                                    {this.state.DispatchTypeData.map(type => (
                                                        <option
                                                            key={type.value}
                                                            value={type.value}
                                                        >
                                                            {type.label}
                                                        </option>
                                                    ))}
                                                </select>

                                            </div>
                                        </div>


                                        <div className="col-12">
                                            <div className="form-group">
                                                <label for="CategoryName">Dispatch Date<span className="mandatory">
                                                    *
                                                </span></label>

                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.DispatchDate}
                                                    onChange={(date) => {
                                                        this.setState({
                                                            DispatchDate: date
                                                        })
                                                    }}
                                                    isClearable
                                                    className="form-control"
                                                    minDate={new Date()}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                />


                                            </div>
                                        </div>


                                        <div className="col-12">
                                            <div className="form-group">
                                                <label for="CategoryName">Shipping Date<span className="mandatory">
                                                    *
                                                </span></label>

                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.ShippingDate}
                                                    onChange={(date) => {
                                                        this.setState({
                                                            ShippingDate: date
                                                        })
                                                    }}
                                                    isClearable
                                                    className="form-control"
                                                    minDate={new Date()}
                                                // onKeyDown={(e) => e.preventDefault()}
                                                />


                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label for="CategoryName">Tracking URL<span className="mandatory">
                                                    *
                                                </span></label>
                                                <input type="text" id="CategoryName" className="form-control"
                                                    value={this.state.TrackingNumber}
                                                    onChange={(number) => {
                                                        this.setState({
                                                            TrackingNumber: number.target.value
                                                        })

                                                    }}
                                                />




                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button
                                        className="btn align-items-center Bechofy-btn"
                                        type="submit"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                            this.setState({
                                                open: false,

                                            });

                                        }}
                                    >
                                        Close
                                    </button>

                                    <button
                                        style={{ float: "right" }}
                                        className="btn align-items-center Bechofy-btn "
                                        onClick={() => {
                                            if (this.state.DispatchData != null) {
                                                if (this.state.DispatchDate != null) {
                                                    if (this.state.ShippingDate != null) {
                                                        if (this.state.TrackingNumber != null) {
                                                            let details = JSON.parse(localStorage.getItem("LoginDetail"));
                                                            Notiflix.Loading.Dots()
                                                            PostApiCall.postRequest(
                                                                {

                                                                    dispatchid: this.state.DispatchId,
                                                                    disptachdate: this.state.DispatchDate,
                                                                    dispatchmode: this.state.DispatchData,
                                                                    status: 'Dispatched',
                                                                    trackingno: this.state.TrackingNumber,
                                                                    shippingdate: this.state.ShippingDate,
                                                                    deliveryagent: null,

                                                                    createdBy: details[0].fld_userid,
                                                                    updatedby: details[0].fld_userid,
                                                                },
                                                                "UpdateDispatchTrackingDetails"
                                                            ).then((resultcategory) =>
                                                                resultcategory.json().then((obj) => {
                                                                    if (resultcategory.status == 200 || resultcategory.status == 201) {

                                                                        this.sendMailer()
                                                                        // Notiflix.Loading.Remove();
                                                                        // Notiflix.Notify.Success('Tracking Details has been updated.')
                                                                        // window.location.reload()

                                                                    } else {

                                                                        Notiflix.Loading.Remove()

                                                                        Notiflix.Notify.Failure(obj.data)

                                                                    }
                                                                })
                                                            );
                                                        } else {
                                                            Notiflix.Notify.Failure("Please enter Tracking Number")
                                                        }

                                                    } else {
                                                        Notiflix.Notify.Failure("Please select Shipping Date")
                                                    }

                                                } else {
                                                    Notiflix.Notify.Failure("Please select Dispatch Date")
                                                }
                                            } else {
                                                Notiflix.Notify.Failure("Please select Dispatch Type")
                                            }
                                        }}
                                    >
                                        Save Tracking Details
                                    </button>
                                    <span></span>
                                </div>
                            </div>
                        </Modal>

                        <Breadcrumbs
                            title={"grocery4uretail.com /"}
                            breadcrumbItem={"Delivery Management"}
                        />
                        <Row>
                            <Col xl="12">

                                <Card className="overflow-hidden">
                                    <Row>
                                        <Col xl="12">
                                            <div className="Bechofy-bg-soft-pink" style={{ borderRadius: "3px" }}>
                                                <div className="row my-1">
                                                    <div className="col-2">
                                                        <div className="form-group">
                                                            <h4 className="FilterCard" style={{ marginTop: '25px' }}>Filter Data By</h4>
                                                        </div>
                                                    </div>


                                                    <div className="col-3" style={{ marginTop: "16px" }}>

                                                        <div className="form-group">
                                                            {/* <label for="inputType" className="FilterCard">From Date</label> */}
                                                            <DatePicker
                                                                placeholderText="From Date"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={this.state.FromDate}
                                                                onChange={(date) => {
                                                                    this.setState({
                                                                        FromDate: date
                                                                    })
                                                                }}
                                                                isClearable
                                                                className="form-control"
                                                                maxDate={new Date()}
                                                            // onKeyDown={(e) => e.preventDefault()}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">

                                                        <div className="form-group" style={{ marginTop: "16px" }}>
                                                            {/* <label for="inputType" className="FilterCard">To Date</label> */}
                                                            <DatePicker
                                                                dateFormat="dd/MM/yyyy"
                                                                placeholderText="To Date"
                                                                selected={this.state.ToDate}
                                                                onChange={(date) => {
                                                                    this.setState({
                                                                        ToDate: date
                                                                    })
                                                                }}
                                                                isClearable
                                                                className="form-control"
                                                                maxDate={new Date()}
                                                            // onKeyDown={(e) => e.preventDefault()}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-1">
                                                        <div className="position-relative">
                                                            <a

                                                                onClick={() => {
                                                                    if (this.state.FromDate != null || this.state.ToDate != null) {
                                                                        this.onPost()
                                                                    }
                                                                    else {
                                                                        Notiflix.Notify.Failure("Please select From Date and To Date")
                                                                    }
                                                                }}

                                                                style={{
                                                                    float: "right",
                                                                    marginTop: "16px",
                                                                    background: 'white',
                                                                    color: 'grey'
                                                                }}
                                                                className="btn align-items-center btn MaxXchange-btn "
                                                            >
                                                                Search
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className="position-relative">
                                                            <a

                                                                style={{
                                                                    float: "right",
                                                                    marginTop: "16px",
                                                                    background: 'white',
                                                                    color: 'grey',
                                                                    marginRight: '10px'
                                                                }}
                                                                className="btn align-items-center btn MaxXchange-btn "
                                                            >
                                                                <CSVLink filename={"Dispatch_List.csv"} data={this.state.OrderData}>Download Report (.csv)</CSVLink>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <Card className="overflow-hidden">
                                                <Row>
                                                    <Col xs="6">
                                                        <div className="MaxXchange-text  p-3">
                                                            <h5 className="MaxXchange-text" style={{ color: '#495057' }}>
                                                                Dispatch List
                                                            </h5>
                                                        </div>
                                                    </Col>

                                                    {/* <Col xs="6">
															<div className="MaxXchange-text  p-3">
																<button className="btn align-items-center MaxXchange-btn" style={{float:'right'}} 
                                                                onClick={()=>{
                                                                    window.location.href='/add_stock'
                                                                }}>Add Stock</button>
															</div>
														</Col> */}

                                                </Row>




                                                <CardBody className="pt-0">
                                                    <Row>
                                                        <MDBCol md="12" style={{ marginBottom: "10px" }}>
                                                            <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                                onChange={(e) => this.seachBarHandler(e)}
                                                                value={this.state.searchInput}

                                                            />
                                                        </MDBCol>

                                                        <Col md="12">
                                                            {/* <MDBDataTableV5
																// scrollX
																// scrollY
																// maxHeight="200px"
																striped
																bordered
																	data={data}
																	searchBottom={false}
																	seachTop={true}
																> */}
                                                            <MDBDataTable
                                                                hover
                                                                // scrollY
                                                                striped
                                                                bordered
                                                                data={data}
                                                                seachTop={false}
                                                                entriesOptions={[10, 20, 40, 60, 80, 100, 120, 140]}
                                                            >

                                                                <MDBTableHead columns={data.columns} />
                                                                <MDBTableBody rows={data.rows} />
                                                            </MDBDataTable>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                                {/* </Card> */}
                                            </Card>

                                            {/* <WelcomeComp /> */}
                                        </Col>
                                    </Row>
                                </Card>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default DeliveryManagement;

