
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import Bechofylogo from '../../assets/images/bechofylogo.png'

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer" style={{ height: "73px" }}>
                <Container fluid={true}>
                    <Row>
                        <Col md={6} style={{ marginTop: '-10px' }}>
                            Powered by
                            <Link onClick={() => { window.location.href = "https://www.bechofy.in/" }} style={{ color: '#777f80' }} target="_blank">
                                <img src={Bechofylogo} alt="" style={{ width: '50px' }} /> <span>A product of Global Trendz.</span></Link>

                        </Col>
                        <Col md={6}>
                            <div className="text-sm-right d-none d-sm-block">
                                {new Date().getFullYear()} © <Link
                                    to={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_website} style={{ color: '#777f80' }}>{JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_companyname}
                                </Link> . All Rights Reserved.

                            </div>    </Col>

                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
