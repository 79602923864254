import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";


import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "react-confirm-alert/src/react-confirm-alert.css";

import "react-datepicker/dist/react-datepicker.css";


import Searchicon from '../../assets/images/dashboardimages/search.png'


import Editicon from '../../assets/images/dashboardimages/pencil.png'
import addicon from '../../assets/images/dashboardimages/product.png'



// import DatePicker from 'react-date-picker';

class PSM extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MaterialData: [],
            open: false,
            view: false,
            PublishDate: "",
            Id: "",
            searchInput: "",
            ViewClick: "true",
            ProductData: [],
            VariantData: [],
            Vertical: null,
            SearchField: null,
            PSMData: [],
            inputValue: null,
        };
    }

    // onCloseModal = () => {
    //     this.setState({ open: false });
    //   };


    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        Notiflix.Loading.Dots("Please wait...");

        localStorage.removeItem('PSMDetails')







        PostApiCall.postRequest(
            {

                whereClause: '',
                recordCount: '*'
            },
            "GetPrintingSheet"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    console.log(obj.data)
                    this.setState({
                        PSMData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))


    }







    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    render() {
        const { text, suggestions } = this.state;





        // =========VariantList=============
        const PSMData = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Sheet Size",
                    field: "sheetsize",
                    sort: "disabled",
                    width: 400,
                },
                {
                    label: "Sheet Length",
                    field: "length",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Sheet Breadth",
                    field: "breadth",
                    sort: "disabled",
                    width: 400,
                },

                {
                    label: "Sheet Size LB",
                    field: "SheetSizeLB",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Existing Sequence",
                    field: "exsitingsequence",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "New Sequence",
                    field: "newsequence",
                    sort: "disabled",
                    width: 150,
                },


                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ], // end for variant
            rows: this.state.PSMData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                } if (
                    this.state.searchInput !== "" &&
                    (data.fld_sheet_size.toString()
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase()) ||
                        `${data.fld_sheet_breadth != null ? data.fld_sheet_breadth.toString().toLowerCase() : ""
                            }`.includes(this.state.searchInput.toLowerCase()))) {
                    return data
                }


            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    sheetsize: (
                        data.fld_sheet_size
                    ),
                    breadth: (
                        data.fld_sheet_breadth

                    ),
                    length: (
                        data.fld_sheet_length
                    ),
                    SheetSizeLB: (
                        data.fld_sheet_size_lb
                    ),
                    exsitingsequence: (
                        data.fld_sequence
                    ),
                    newsequence: (

                        <input style={{
                            width: "11%",
                            borderRadius: "6px",
                            borderColor: "#0000003d",
                            padding: "4px",
                            width: "14%"
                        }}
                            onBlur={(e) => {
                                PostApiCall.postRequest(
                                    {
                                        sheetid: data.fld_sheet_id,
                                        sheetsize: data.fld_sheet_size,
                                        sheetlength: data.fld_sheet_length,
                                        sheetbreadth: data.fld_sheet_breadth,
                                        sheetsizelb: data.fld_sheet_size_lb,
                                        costperkg: data.fld_cost_per_kg,
                                        sequence: e.target.value,
                                    },
                                    "PrintingSheetMaster"
                                ).then((resultcategory) =>
                                    resultcategory.json().then((obj) => {
                                        if (resultcategory.status == 200 || resultcategory.status == 201) {
                                            Notiflix.Notify.Success("Sequence successfully changed.")
                                            PostApiCall.postRequest(
                                                {

                                                    whereClause: '',
                                                    recordCount: '*'
                                                },
                                                "GetPrintingSheet"
                                            ).then((results) =>
                                                // const objs = JSON.parse(result._bodyText)
                                                results.json().then((obj) => {
                                                    if (results.status == 200 || results.status == 201) {
                                                        this.setState({
                                                            PSMData: obj.data,

                                                        });
                                                    }
                                                }))


                                        } else {
                                            Notiflix.Loading.Remove();
                                            Notiflix.Notify.Failure(obj.data);
                                        }
                                    })
                                );
                            }}

                        />
                    ),




                    action: (
                        <span>
                            <img src={Editicon} alt="View user" className="btnicons" onClick={() => {
                                localStorage.setItem(
                                    "PSMDetails",
                                    JSON.stringify(data)
                                );
                                window.location.href = "/add_printing_sheet/update";
                            }}></img>

                        </span>
                    )



                }
            })
        }




        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}

                        <Breadcrumbs breadcrumbItem={"Printing Sheet Master"} urlPath={"/design_master"} />

                        <Row>
                            <Col xl="12">
                                <Card>
                                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px', }}>
                                        <div className="row my-1">
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <h4 className="FilterCard" style={{ marginTop: '10px' }}>Printing Sheet List</h4>
                                                </div>
                                            </div>

                                            <div className="col-10">
                                                <div className="position-relative">


                                                    <button
                                                        onClick={() => {

                                                            window.location.href = "/add_printing_sheet/create";
                                                        }}

                                                        className="btn align-items-center btn Bechofy-btn AddNewBtn"
                                                    >
                                                        Add New Printing Sheet{" "}
                                                        <span className=""><img src={addicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

                                                    </button>




                                                </div>
                                            </div>


                                        </div>
                                    </div>





                                    <CardBody className="pt-0">
                                        <Row>


                                            <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                                <div class="input-group mb-3">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </MDBCol>

                                            <Col md="12">

                                                {this.state.PSMData.length !== 0 ?
                                                    <MDBDataTable
                                                        hover
                                                        // scrollY
                                                        striped
                                                        bordered
                                                        data={PSMData}
                                                        seachTop={false}
                                                        entriesOptions={[25, 50, 100]}
                                                        className="printingsheet-master-table"
                                                    >

                                                        <MDBTableHead columns={PSMData.columns} class="printingsheet-master-table" />
                                                        <MDBTableBody rows={PSMData.rows} class="printingsheet-master-table" />
                                                    </MDBDataTable>
                                                    :
                                                    <p>
                                                        No Data Found
                                                    </p>
                                                }


                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>


                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                        {/* </Card>

							</Col>
						</Row> */}
                    </Container>
                </div >
            </React.Fragment >
        );
    }
}
export default PSM;
