import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import { Link } from "react-router-dom";
import moment from "moment";

class LatestReviews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ReviewData: []
        }
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest({

            whereClause: "order by fld_review_id desc",
            recordCount: "Top 10 *"


        }, "GetReviews").then((results) =>

            results.json().then((obj1) => {

                if (results.status == 200 || results.status == 201) {
                    console.log(obj1.data)
                    if (obj1.data.length != 0) {
                        this.setState({
                            ReviewData: obj1.data,
                            AverageRating: obj1.data[0].avg_rating,
                            Loading: false
                        });
                    } else {
                        this.setState({
                            ReviewData: obj1.data,
                            Loading: false
                        })
                    }
                }
            })
        );


    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <div>
                        <Row>


                            <Col xs="6">
                                <div className="Bechofy-text p-3">
                                    <h4 className="Bechofy-text" style={{ color: '#495057' }}>New Reviews</h4>

                                </div>
                            </Col>
                            <Col xs="6">
                                <div className="Bechofy-text">
                                    <Link
                                        to="/viewallreviews"
                                        style={{
                                            float: "right",

                                            background: 'black',
                                            color: 'white',
                                            marginTop: '13px',
                                            marginRight: '13px'
                                        }}
                                        className="btn align-items-center btn Bechofy-btn "
                                    >
                                        View All Reviews{" "}

                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <CardBody className="pt-0">
                        <Row>

                            <Col xs="12">

                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th className="centered" style={{ width: '10px' }}>S.No.</th>
                                            <th className="centered" style={{ width: '200px' }}>Customer Name</th>
                                            <th className="centered" style={{ width: '200px' }}>Customer Email</th>
                                            <th className="centered" style={{ width: '300px' }}>Variant Name</th>
                                            <th className="centered" style={{ width: '145px' }}>Rating</th>
                                            <th className="centered" style={{ width: '300px' }}>Review</th>
                                            <th className="centered" style={{ width: '145px' }}>Review Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.ReviewData.map((data, i) => (
                                            <tr>
                                                <td className="centered">{i + 1}</td>
                                                <td className="centered">{data.fld_name}</td>
                                                <td className="centered">{data.fld_email}</td>
                                                <td className="centered">{data.fld_variantname}</td>
                                                <td className="centered">{data.fld_rating} / 5</td>
                                                <td className="centered">{data.fld_review_description}</td>
                                                <td className="centered">{moment(data.fld_review_date).format("DD/MM/YYYY")}</td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>

                            </Col>

                        </Row>
                    </CardBody>
                </Card>


                {/* ==========Latest Pickups ==========*/}

            </React.Fragment>
        );
    }
}

export default LatestReviews;
