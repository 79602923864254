import React, { Component } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
// import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import Parser from "html-react-parser";
// import {XSquare} from 'react-feather';
import addicon from '../../assets/images/dashboardimages/product.png'
import photoicon from '../../assets/images/dashboardimages/photo.png'

import Select from 'react-select';
import GtLogo from "../../assets/images/bechofylogo.png";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Editicon from '../../assets/images/dashboardimages/pencil.png'
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";


class AddVariant extends Component {
	constructor(props) {
		super(props);
		this.state = {
			enabled: false,
			startDate: new Date(),
			endDate: new Date(),
			imageValue: "",
			isImageValid: null,
			Photos: [],



			CategoryName: "",
			CategoryData: [],
			SubCatgeoryData: [],
			SubCategory: "",
			AttributData: [],
			ItemName: [],
			HSNCode: "",
			GSTRate: "",
			GSTData: [
				{ value: "5", label: "5" },
				{ value: "10", label: "10" },
				{ value: "12", label: "12" },
				{ value: "15", label: "15" },
				{ value: "18", label: "18" },
			],



			UOM: "",
			Status: "Yes",
			SKU: "",
			Barcode: "",
			VariantName: "",
			ShortDescription: "",
			LongDescription: "",
			AttributeValue: [],
			StockInHand: 0,
			CostPrice: 0,
			MRP: 0,
			SellingPrice: 0,
			Websitediscountprice: 0,
			ItemStatus: "Active",
			ProductID: "",
			PRoductData: [],
			SaveVariantData: [],
			FormStatus: "",
			fields: "",
			finaldata: "",
			name: "",
			groupArray: [],
			AttributeMappingId: null,
			VariantimageId: null,
			VariantPriceId: null,
			VariantID: null,
			Variantsdata: "",
			ItemData: [],
			VariantIdForImage: null,
			VariantDataForimage: [],
			ShippingCharge: 0,
			ImageUrl: ""

		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});


		localStorage.removeItem("VariantImageID")
		localStorage.removeItem("VariantIdForImage")

		Notiflix.Loading.Dots("Please wait...");



		// //console.log(localStorage.getItem('VariantDetails'))

		GetApiCall.getRequest("GetProductDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					ItemData: obj.data,
				});
			})
		);

		if (localStorage.getItem('VariantDetails') != null) {
			// //console.log('notnull')


			Notiflix.Loading.Dots("Please wait...");
			PostApiCall.postRequest(
				{

					whereClause: `where fld_variantid=${JSON.parse(localStorage.getItem('VariantDetails'))}`,
					recordCount: '*'
				},
				"GetVariantData"
			).then((results) =>
				// const objs = JSON.parse(result._bodyText)
				results.json().then((obj) => {

					if (results.status == 200 || results.status == 201) {
						console.log(obj.data)

						this.setState({
							VariantData: obj.data,
							ViewVariantList: true,
							ViewProductList: false

						});
						if (obj.data.length != 0) {
							this.setState({
								VariantID: obj.data[0].fld_variantid,
								VariantName: obj.data[0].fld_variantname,
								SKU: obj.data[0].fld_sku,
								Barcode: obj.data[0].fld_barcode,
								CostPrice: obj.data[0].fld_cost_price,
								MRP: obj.data[0].fld_mrp,
								SellingPrice: obj.data[0].fld_website_discount_price,
								Websitediscountprice: obj.data[0].fld_website_discount_price,
								Status: obj.data[0].fld_status == 'TRUE' ? 'Yes' : 'No',
								AttributeMappingId: obj.data[0].fld_attributemappingid,
								ItemStatus: obj.data[0].fld_status,
								ShippingCharge: obj.data[0].fld_shipping

							})
							// populate attribute
							PostApiCall.postRequest(
								{
									id: obj.data[0].fld_categoryid,
									sfor: 'NA'
								},
								"GetCategoryAttributeValue"
							).then((results2) =>
								// const objs = JSON.parse(result._bodyText)
								results2.json().then((obj2) => {
									console.log(obj2.data);
									if (results2.status == 200 || results2.status == 201) {
										this.setState({
											AttributData: obj2.data,
										});

										Notiflix.Loading.Remove();
									}
								})
							);

							var item = []
							item.push({
								label: obj.data[0].fld_itemname,
								value: obj.data[0].fld_productid
							})

							new Promise((resolve, reject) => {
								setTimeout(resolve, 1000);
							}).then(() => {
								this.setState({
									ShortDescription: obj.data[0].fld_short_description,
									LongDescription: obj.data[0].fld_long_description,
									ItemName: item
									// TermsCondition:OfferData.fld_termscondition,
								});
							});
							if (obj.data) {
								obj.data.map((data, i) => {
									this.setState({
										VariantDataForimage: data
									})
								})
							}






						}
					}


					Notiflix.Loading.Remove();
				}
				))


			PostApiCall.postRequest(
				{
					whereClause: `where fld_variantid=${JSON.parse(localStorage.getItem('VariantDetails'))}`
				},
				"Get_VariantImage"
			).then((results8) =>
				results8.json().then((obj8) => {
					if (
						results8.status == 200 ||
						results8.status == 201
					) {
						console.log(obj8.data)

						if (obj8.data != null) {
							this.setState({
								Photos: obj8.data,


							});
						}


					}
				}))



		}

		if (localStorage.getItem("ProductID") != null) {
			Notiflix.Loading.Dots('Please wait...');
			PostApiCall.postRequest(
				{

					whereClause: `where fld_productid=${JSON.parse(localStorage.getItem('ProductID'))}`,
					recordCount: '*'
				},
				"GetVariantData"
			).then((results) =>
				// const objs = JSON.parse(result._bodyText)
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						//console.log(obj.data[0].fld_variantid)
						this.setState({
							SaveVariantData: obj.data

						}
						);
						Notiflix.Loading.Remove();
					}
				}))


		}
	}


	onChangesubCategory(text) {
		this.setState(
			{
				SubCategory: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						id: this.state.SubCategory,
					},
					"Get_Category_Attribute_value"
				).then((results2) =>
					// const objs = JSON.parse(result._bodyText)
					results2.json().then((obj2) => {
						console.log(obj2.data);
						if (results2.status == 200 || results2.status == 201) {
							this.setState({
								AttributData: obj2.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}










	photoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 100000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result,
					ImageData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 100 kb.");
		}
	};



	onSaveVariant() {
		// //console.log("value"+this.state.ItemName.value)
		if (this.state.ItemName.length != 0) {
			if (this.state.SKU != "") {
				if (this.state.VariantName != "") {
					if (this.state.ShortDescription != "") {
						if (this.state.ShortDescription.split(" ").length <= 300) {
							if (this.state.LongDescription != "") {
								if (this.state.ShortDescription.split(" ").length <= 700) {

									if (this.state.imageCount != 0) {
										if (this.state.MRP != "") {
											if (this.state.CostPrice != "") {
												if (this.state.Websitediscountprice != "") {
													this.onPostVariantData();

												} else {
													Notiflix.Notify.Failure(
														"Please enter website discounted Price"
													);
												}

											} else {
												Notiflix.Notify.Failure("Please enter Cost Price");
											}
										} else {
											Notiflix.Notify.Failure("Please enter MRP");
										}
									} else {
										Notiflix.Notify.Failure(
											"Please upload atleast one product image."
										);
									}

								} else {
									Notiflix.Notify.Failure(
										"Please enter long Description with less than 700 characters"
									);
								}
							} else {
								Notiflix.Notify.Failure("Please enter long Description");
							}
						} else {
							Notiflix.Notify.Failure(
								"Please enter short description with less than 300 characters."
							);
						}
					} else {
						Notiflix.Notify.Failure("Please enter ShortDescription");
					}
				} else {
					Notiflix.Notify.Failure("Please enter variant name");
				}
			} else {
				Notiflix.Notify.Failure("Please enter sku");
			}
		}
		else {
			Notiflix.Notify.Failure("Please select item name");
		}
	}


	onPostVariantData = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		var sp = ''
		for (var i = 0; i < this.state.ItemName.length; i++) {
			if (i == 0) {
				sp = this.state.ItemName[i].value
			}

		}
		PostApiCall.postRequest(
			{
				productid: sp == '' ? this.state.ItemName.value : sp,
				varientid: this.state.VariantID,
				sku: this.state.SKU,
				barcode: this.state.Barcode,
				longdescripiton: this.state.LongDescription,
				variantname: this.state.VariantName,
				shortdescripiton: this.state.ShortDescription,
				shipping: this.state.ShippingCharge,
				stockInHand: this.state.StockInHand,
				status: this.state.ItemStatus,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
			},
			"AddVarient"
		).then((resultcategory1) =>
			resultcategory1.json().then((obj1) => {
				if (resultcategory1.status == 200 || resultcategory1.status == 201) {
					if (this.state.VariantID == null) {
						localStorage.setItem("VariantID", JSON.stringify(obj1.data))
						localStorage.setItem("ProductID", JSON.stringify(this.state.ItemName.value))
						this.onPostPrice(obj1);
					}
					else {
						this.onPostPrice(obj1);
					}

				} else {
					Notiflix.Loading.Remove();

					Notiflix.Notify.Failure(obj1.data);
				}
			})
		);
	};

	onPostPrice(obj1) {
		// //console.log(JSON.parse(JSON.stringify(obj1.data[0])).VariantId);
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		PostApiCall.postRequest(
			{
				variantpriceid: this.state.VariantPriceId,
				variantid: JSON.parse(JSON.stringify(obj1.data[0])).VariantId,
				mrp: this.state.MRP,
				costPrice: this.state.CostPrice,
				sellingPrice: this.state.SellingPrice,
				websiteDiscountPrice: this.state.Websitediscountprice,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
			},
			"AddVariantPrice"
		).then((resultcategory2) =>
			resultcategory2.json().then((obj2) => {
				if (resultcategory2.status == 200 || resultcategory2.status == 201) {

					this.onPostAttribute(obj1)

				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj2.data);
				}
			})
		);
	}
	onPostAttribute(obj1) {
		//console.log('attribute')

		Notiflix.Loading.Dots('Please wait...');

		var login = localStorage.getItem('LoginDetail');
		var details = JSON.parse(login)
		var addattribute = ''
		for (var i = 0; i < this.state.AttributeValue.length; i++) {

			if (i == 0) {
				addattribute = this.state.AttributeValue[i].value
			} else {
				addattribute = addattribute + ', ' + this.state.AttributeValue[i].value
			}

		}

		PostApiCall.postRequest({
			attributemappingid: this.state.AttributeMappingId,
			productid: this.state.ItemName.value,
			variantid: (JSON.parse(JSON.stringify(obj1.data[0]))).VariantId,
			attributeValue: addattribute,
			createdOn: moment().format("YYYY-MM-DD"),
			updatedOn: moment().format("YYYY-MM-DD"),
			updatedBy: details[0].fld_userid

		}, "AddAttributeMasterMapping").then((resultcategory3 =>
			resultcategory3.json().then(obj3 => {
				if (resultcategory3.status == 200 || resultcategory3.status == 201) {
					console.log("obj3 " + obj3)
					//this.onPostUploadImage(obj1);
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success("Product Details Successfully Added");
					window.location.reload();
				} else {
					//this.onPostUploadImage(obj1);
				}
			})
		))

	}


	// onPostUploadImage(obj1) {


	// 	var login = localStorage.getItem("LoginDetail");
	// 	var details = JSON.parse(login);

	// 	var empty = 0;

	// 	var count = 0;

	// 	for (var i = 0; i < this.state.Photos.length; i++) {


	// 		if (this.state.Photos[i].value != "" && this.state.Photos[i].data != "") {
	// 			const form1 = new FormData();



	// 			form1.append("file", this.state.Photos[i].data);
	// 			form1.append("foldername", "ProductImages");
	// 			form1.append(
	// 				"filename", moment().format('sshhmm') + i +
	// 			this.state.Photos[i].data.name
	// 			);
	// 			fetch(this.state.ImageApiUrl, {
	// 				method: "POST",
	// 				body: form1,
	// 				// eslint-disable-next-line no-loop-func
	// 			}).then((image1) => {
	// 				image1
	// 					.json()
	// 					.then((data1) => ({
	// 						data: data1,
	// 						status: image1.status,
	// 					}))
	// 					.then((res1) => {
	// 						PostApiCall.postRequest(
	// 							{
	// 								variantimageid: this.varientImageId,
	// 								variantid: JSON.parse(JSON.stringify(obj1.data[0])).VariantId,
	// 								imageurl:
	// 									JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/ProductImages/" +
	// 									res1.data.Message.split(",")[2].split("=")[1].trim(),
	// 								createdOn: moment().format("YYYY-MM-DD"),
	// 								updatedOn: moment().format("YYYY-MM-DD"),
	// 								updatedBy: details[0].fld_userid,
	// 							},
	// 							"AddVariantImage"
	// 						).then((results2) =>
	// 							// const objs = JSON.parse(result._bodyText)
	// 							results2.json().then((obj2) => {
	// 								if (results2.status == 200 || results2.status == 201) {
	// 									count = count + 1;

	// 									if (count == this.state.Photos.length) {
	// 										//Notiflix.Loading.Remove();
	// 										Notiflix.Notify.Success("Product Details Saved");
	// 										window.location.reload();
	// 									}
	// 								}
	// 							})
	// 						);
	// 					});
	// 			});
	// 		} else if (
	// 			this.state.Photos[i].value != "" &&
	// 			this.state.Photos[i].data == ""
	// 		) {

	// 			this.varientImageId1 = this.state.Photos[i].imageId;
	// 			PostApiCall.postRequest(
	// 				{
	// 					variantimageid: this.varientImageId1,
	// 					variantid: JSON.parse(JSON.stringify(obj1.data[0])).VariantId,
	// 					imageurl: this.state.Photos[i].value,
	// 					createdOn: moment().format("YYYY-MM-DD"),
	// 					updatedOn: moment().format("YYYY-MM-DD"),
	// 					updatedBy: details[0].fld_userid,
	// 				},
	// 				"AddVariantImage"
	// 			).then((results2) =>
	// 				// const objs = JSON.parse(result._bodyText)
	// 				results2.json().then((obj2) => {
	// 					if (results2.status == 200 || results2.status == 201) {
	// 						count = count + 1;
	// 						empty = empty + 1;
	// 						if (count == this.state.Photos.length) {
	// 							Notiflix.Loading.Remove();

	// 							Notiflix.Notify.Success('Product variant added successfully.')

	// 							window.location.reload();
	// 						}
	// 					}
	// 				})
	// 			);
	// 		} else {
	// 			//console.log("id");
	// 			count = count + 1;

	// 			if (count == this.state.Photos.length) {
	// 				Notiflix.Loading.Remove();

	// 				Notiflix.Notify.Success('Product variant updated successfully.')
	// 				window.location.reload();
	// 			}
	// 		}
	// 	}
	// }

	groupBy = (objectArray, property) => {
		return objectArray.reduce((acc, obj) => {
			const key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}
			// Add object to list for given key's value
			acc[key].push(obj);
			return acc;
		}, {});
	};

	onPostGetAttribute = () => {
		// //console.log(this.state.ItemName.value)
		// var Productid = ''
		// for (var i = 0; i < this.state.ItemName.length; i++) {
		// 	if (i == 0) {
		// 		Productid = this.state.ItemName[i].value
		// 	}

		// }


		PostApiCall.postRequest(
			{
				id: this.state.ItemName.value,
				sfor: 'ProductID'
			},
			"GetCategoryAttributeValue"
		).then((results2) =>
			// const objs = JSON.parse(result._bodyText)
			results2.json().then((obj2) => {
				if (results2.status == 200 || results2.status == 201) {
					//console.log(obj2.data)
					this.setState({
						AttributData: obj2.data,
					});
				}
			})
		);


		// this.state.AttributData.map((data, i) => {
		// 	//console.log("data.fld_attributename" + data.fld_attributename)
		// 	temp.push(data.fld_attributename);
		// });
		// temp = [...new Set(temp)];
		// groupTemp = this.groupBy(this.state.AttributData, "fld_attributename");

		// KeysArray = Object.keys(groupTemp);
		// ValuesArray = Object.values(groupTemp);






	}

	// DeleteImageFromServer = () =>{
	// 	Notiflix.Loading.Dots("Please wait...");
	//    PostApiCall.postRequest({
	// 	foldername: "ProductImages",
	// 	filename: this.state.ImageUrl.split("/").pop()
	//    }, "DeleteImageFromServer")
	//    .then((results2) =>
	// 		// const objs = JSON.parse(result._bodyText)
	// 		results2.json().then((obj2) => {
	// 			if (results2.status == 200 || results2.status == 201) {
	// 				Notiflix.Loading.Remove();
	// 				Notiflix.Notify.Success("Variant image deleted successfully");
	// 				window.location.reload();
	// 			}
	// 		})
	// 	);

	// }





	render() {




		const settings = {
			dots: false,
			infinite: true,
			arrows: false,
			speed: 800,
			autoplay: true,
			slidesToShow: 4,
			slidesToScroll: 1,
		};

		const variantImages = {
			dots: false,
			infinite: true,
			arrows: false,
			speed: 800,
			autoplay: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		let temp = [];

		this.state.AttributData.map((data, i) => {
			temp.push(data.fld_attributename);
		});
		temp = [...new Set(temp)];
		let groupTemp = this.groupBy(this.state.AttributData, "fld_attributename");

		let KeysArray = Object.keys(groupTemp);
		let ValuesArray = Object.values(groupTemp);


		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>

						<div className="row">
							<div className="col-md-6">

								<div>
									<ul class="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>										<li><a href="/item-management">Variant List</a></li>
										<li>Add New Variant</li>
									</ul>
								</div>

							</div>
							<div className="col-md-6">
								<img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>

							</div>
						</div>



						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"

									>
										<Row>
											<Col xs="3">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text">
														Variant Information
													</h5>
												</div>
											</Col>
											<Col xs="9">
												<button
													onClick={() => {
														window.location.href = "/add_item";
													}}

													className="btn align-items-center btn Bechofy-btn AddNewBtn"
												>
													Add New Item{" "}
													<span className=""><img src={addicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

												</button>


											</Col>

										</Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">

												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row  ">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Item Name
																						<span className="mandatory">*</span>
																					</label>
																					<Select
																						options={this.state.ItemData}


																						value={this.state.ItemName}
																						onChange={(value) => {

																							this.setState({
																								ItemName: value
																							}, () => {
																								this.onPostGetAttribute()
																							})
																						}}





																						isSearchable={true}
																						isClearable={true} />
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Item Sku
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.SKU}
																						onChange={(text) => {
																							this.setState({
																								SKU: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Barcode
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.Barcode}
																						onChange={(text) => {
																							this.setState({
																								Barcode: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Variant Name
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.VariantName}
																						onChange={(text) => {
																							this.setState({
																								VariantName: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>

																			<div className="col-12">
																				<div class="form-group">
																					<label for="sw-arrows-first-name">
																						Short Description(maximum 300
																						Character)
																						<span className="mandatory">*</span>
																					</label>

																					<div class="niceeditors">
																						<CKEditor
																							config={{
																								extraPlugins:
																									"justify,font,colorbutton",
																							}}
																							data={this.state.ShortDescription}
																							onChange={(text) => {
																								this.setState({
																									ShortDescription:
																										text.editor.getData(),
																								});
																							}}
																						//
																						/>
																					</div>
																				</div>
																			</div>

																			<div className="col-12">
																				<div class="form-group">
																					<label for="sw-arrows-first-name">
																						Item Description(maximum 700
																						Character)
																						<span className="mandatory">*</span>
																					</label>

																					<div class="niceeditors">
																						<CKEditor
																							config={{
																								extraPlugins:
																									"justify,font,colorbutton",
																							}}
																							data={this.state.LongDescription}
																							onChange={(text) => {
																								this.setState({
																									LongDescription:
																										text.editor.getData(),
																								});
																							}}
																						/>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<div
													className="Bechofy-bg-soft-pink"

												>
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Attribute Information
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">


															<div className="row">
																{
																	KeysArray.map((attri, aindex) => (
																		<div className="col-3">
																			<div className="form-group">
																				<label for="CategoryName">{attri}</label>

																				<select
																					className="custom-select my-1 mr-sm-2"
																					value={attri.selectedvalue}
																					onChange={(e) => {
																						let tempObj = {
																							name: attri,
																							value: e.target.value,
																						};
																						let index =
																							this.state.AttributeValue.findIndex(
																								(data) => {
																									return (
																										tempObj.name == data.name
																									);
																								}
																							);
																						let TempAttributeArray = [
																							...this.state.AttributeValue,
																						];

																						if (index !== -1) {
																							TempAttributeArray.splice(index, 1);
																							TempAttributeArray.push(tempObj);
																						} else {
																							TempAttributeArray.push(tempObj);
																						}
																						this.setState({
																							...this.state,
																							AttributeValue: TempAttributeArray,
																						});
																					}}
																				>
																					<option value="">Select</option>
																					{ValuesArray[aindex].map((data, i) => {
																						return (
																							<option
																								key={data.label}
																								value={data.value}
																							>
																								{data.label}
																							</option>
																						);
																					})}
																					))
																				</select>
																			</div>
																		</div>
																	))}


															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>


											<Card className="overflow-hidden">
												<div
													className="Bechofy-bg-soft-pink"

												>
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Price{" "}
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row  ">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						MRP
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.MRP}
																						onChange={(text) => {
																							this.setState({
																								MRP: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Cost Price
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.CostPrice}
																						onChange={(text) => {
																							this.setState({
																								CostPrice: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			{/* <div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Selling Price
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.SellingPrice}
																						onChange={(text) => {
																							this.setState({
																								SellingPrice: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div> */}
																			<div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Website Discounted Price
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={
																							this.state.Websitediscountprice
																						}
																						onChange={(text) => {
																							this.setState({
																								Websitediscountprice:
																									text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Shipping Charge

																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={
																							this.state.ShippingCharge
																						}
																						onChange={(text) => {
																							this.setState({
																								ShippingCharge:
																									text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>





											<Card>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-4">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Show on Website
																			<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.ItemStatus == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						ItemStatus: "Active",
																					});
																				}}
																			/>{" "}
																			Yes
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.ItemStatus == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						ItemStatus: "Inactive",
																					});
																				}}
																			/>{" "}
																			No
																		</label>
																	</div>
																</div>
																<div className="col-8">

																	<button
																		style={{
																			float: "right",
																			marginTop: "5px",
																		}}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.onSaveVariant.bind(this)}
																	>
																		Save & Add More
																	</button>


																	<button
																		style={{
																			float: "right",
																			marginTop: "5px",
																			marginRight: '5px',
																			display: this.state.VariantDataForimage.length == 0 ? 'none' : 'block'
																		}}
																		className="btn align-items-center Bechofy-btn "
																		onClick={() => {
																			localStorage.setItem('VariantIdForImage', JSON.stringify(this.state.VariantDataForimage))
																			window.location.href = '/addimages'
																		}}
																	>
																		<img src={photoicon} alt="block user" className="btnicons" style={{ marginLeft: '5px', width: '20px', float: 'right' }}
																		></img>
																		Add Image
																	</button>


																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card className="overflow-hidden" style={{ display: this.state.SaveVariantData.length !== 0 ? 'block' : 'none' }}>
												<div
													className="Bechofy-bg-soft-pink"

												>
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Variant List{" "}
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<Row>
													{this.state.SaveVariantData.length !== 0 ? (
														this.state.SaveVariantData.map((data, index) => (
															<Col xs="4">
																<CardBody className="categoryCard">
																	<CardTitle className="mb-4">
																		<h4>{data.fld_variantname}</h4>
																	</CardTitle>
																	<Row className="card-row">
																		<Col sm="6">
																			<div className="">
																				<p>
																					{" "}
																					<b>MRP</b> ₹{data.fld_mrp}{" "}
																				</p>
																				<p>
																					{" "}
																					<b>Cost Price:</b> ₹
																					{data.fld_cost_price}
																				</p>

																				<p>
																					<b>Selling Price</b> ₹
																					{data.fld_selling_price}
																				</p>
																				<p>
																					<b>Website Discount Price</b> ₹
																					{data.fld_website_discount_price}
																				</p>
																				<p>
																					<b>Stock</b> {data.fld_stock_in_hand}
																				</p>
																			</div>
																		</Col>
																		<Col sm="6">
																			<div>
																				<Slider {...variantImages}>
																					{data.VariantImage.split('#').map((img, i) => (
																						<div class="best-products">
																							<div class="img-container" width="50%">
																								<img src={img} alt="product image"
																									onClick={() => {
																										localStorage.setItem("VariantImageID", JSON.stringify(data.fld_variantimageid));
																										window.location.href = '/addimages'
																									}}
																								></img>


																							</div>
																						</div>
																					)

																					)}







																				</Slider>

																			</div>

																		</Col>
																		<hr />
																	</Row>
																	<Row>
																		<Col sm="6">
																			<p className="categorystatus">
																				<b>Status</b> -{" "}
																				<span
																					style={{
																						color:
																							data.fld_status == "Active"
																								? "green"
																								: "red",
																					}}
																				>
																					{data.fld_status}
																				</span>
																			</p>
																		</Col>
																		<Col sm="6">


																			<span className=""><img src={Editicon} alt="block user" className="btnicons" style={{ marginRight: '5px', marginTop: '15px', width: '30px', float: 'right' }}
																				onClick={() => {
																					var data = [
																						...this.state.SaveVariantData,
																					];

																					this.setState({
																						SKU: data[index].fld_sku,
																						Barcode: data[index].fld_barcode,
																						VariantName:
																							data[index].fld_variantname,
																						StockInHand:
																							data[index].fld_stock_in_hand,
																						Websitediscountprice:
																							data[index]
																								.fld_website_discount_price,
																						SellingPrice:
																							data[index].fld_selling_price,
																						CostPrice:
																							data[index].fld_cost_price,
																						MRP: data[index].fld_mrp,
																						VariantID:
																							data[index].fld_variantid,
																						AttributeMappingId:
																							data[index]
																								.fld_attributemappingid,
																						VariantimageId:
																							data[index].fld_variantimageid,
																						VariantPriceId:
																							data[index].fld_variantpriceid,

																					});

																					new Promise((resolve, reject) => {
																						setTimeout(resolve, 1000);
																					}).then(() => {
																						this.setState({
																							LongDescription:
																								data[index]
																									.fld_long_description,
																							ShortDescription:
																								data[index]
																									.fld_short_description,
																						});

																					}

																					);
																					// var GetProductID = []
																					// fld_productid
																					var sp = []

																					sp.push({
																						label: data[index].fld_itemname,
																						value: data[index].fld_productid
																					})


																					this.setState({
																						ItemName: sp
																					})
																					//console.log(data[index].fld_productid)
																					PostApiCall.postRequest(
																						{
																							id: data[index].fld_productid,
																							sfor: null
																						},
																						"GetCategoryAttributeValue"
																					).then((results2) =>
																						// const objs = JSON.parse(result._bodyText)
																						results2.json().then((obj2) => {
																							if (results2.status == 200 || results2.status == 201) {
																								// //console.log(obj2.data)
																								this.setState({
																									AttributData: obj2.data,
																								});
																							}
																						})
																					);





																				}}
																			></img> </span>

																			<span className=""><img src={photoicon} alt="block user" className="btnicons" style={{ marginRight: '5px', marginTop: '15px', width: '30px', float: 'right' }}
																				onClick={() => {
																					localStorage.setItem('VariantIdForImage', JSON.stringify(data))
																					window.location.href = '/addimages'
																				}}></img> </span>
																		</Col>
																	</Row>
																</CardBody>
															</Col>
														))
													) : (
														<p></p>
													)}
												</Row>
											</Card>

											<Card style={{ display: this.state.Photos.length != 0 ? 'block' : 'none' }}>
												<div
													className="Bechofy-bg-soft-pink"

												>
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Variant Images
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody>
													<div className="row">

														{this.state.Photos.map((data, i) => (


															<div className="col-3">
																<div class="best-products">
																	<div class="product-item">
																		<div class="img-container" width="50%">
																			<img src={data.fld_imageurl} alt="product image"
																				onClick={() => {
																					localStorage.setItem("VariantImageID", JSON.stringify(data.fld_variantimageid));
																					window.location.href = '/addimages'
																				}}
																			/>
																			<span style={{ position: "absolute", left: "35px", top: "28px", cursor: "pointer" }}
																				onClick={() => {
																					this.setState({
																						ImageUrl: data.fld_imageurl
																					}, () => {
																						confirmAlert({
																							title: "Confirm to Delete",
																							message:
																								"Are you sure you want to delete the image?",
																							buttons: [
																								{
																									label: "Yes",
																									onClick: () => {
																										Notiflix.Loading.Dots("");

																										PostApiCall.postRequest(
																											{
																												variantimageid: data.fld_variantimageid,

																											},
																											"Delete_VariantImage"
																										).then((results) =>
																											// const objs = JSON.parse(result._bodyText)
																											results.json().then((obj) => {
																												if (
																													results.status == 200 ||
																													results.status == 201
																												) {
																													Notiflix.Loading.Remove();
																													Notiflix.Notify.Success(
																														"Image successfully deleted."
																													);
																													window.location.reload()
																													// this.DeleteImageFromServer()
																												} else {
																													Notiflix.Loading.Remove();
																													Notiflix.Notify.Failure(
																														"Something went wrong, try again later."
																													);
																												}
																											})
																										);
																									},
																								},
																								{
																									label: "No",
																									// onClick: () => alert('Click No')
																								},
																							],
																						});
																					})
																				}
																				}
																			><span class="material-symbols-outlined">
																					backspace
																				</span></span>
																		</div>
																	</div>
																</div>
															</div>


														)

														)}
													</div>








												</CardBody>
											</Card>

											{/* <WelcomeComp /> */}
										</Col>
									</Row>
									{/* -row */}
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddVariant;
