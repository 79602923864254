import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import GetApiCall from "../../GETAPI";
import PostApiCall from "../../Api";
import { confirmAlert } from "react-confirm-alert";
import Drawer from "react-modern-drawer";
import "react-responsive-modal/styles.css";
import "react-modern-drawer/dist/index.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import Searchicon from '../../assets/images/dashboardimages/search.png'
import imageCompression from "browser-image-compression";
import Notiflix from "notiflix";
import Editicon from '../../assets/images/dashboardimages/pencil.png'
import Saveicon from '../../assets/images/dashboardimages/filesave.png'
import deleteicon from '../../assets/images/dashboardimages/delete.png'
import GtLogo from "../../assets/images/bechofylogo.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Sequentialize from "../../assets/images/dashboardimages/Sequentialize.png";
import DragDropList from "../DragDroplist/DragDropList";
import DragDropVertical from "../DragDroplist/DargDropVertical";

const ImgUpload = ({ onChange, src }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas" style={{ marginBottom: '5px' }}>
		<div className="img-wrap brandimages img-upload">
			<img
				for="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
	</label>
);
class verticleMater extends Component {
	state = {
		data: [],
		searchInput: "",
		VerticleData: [],
		ViewClick: "true",
		open: false,

		Name: "",
		openedit: false,
		Id: "",
		Isvisible: false,

		imagePreviewUrl: 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png',

		ImageApiUrl: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AddImage",

		Status: "Yes",

		VerticalName: null,
		originalImage: [],
		originalLink: "",
		clicked: false,
		uploadImage: false,
		VerticleId: null,
		VerticalLogo: null,
		uploadImage: false,
		IsOpen: false,
	};

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("");

		GetApiCall.getRequest("GetVerticleMaster").then((resultdes) =>
			resultdes.json().then((obj) => {
				// //console.log(obj.data);

				this.setState({
					VerticleData: obj.data,
				});

				Notiflix.Loading.Remove();
			})
		);
	}


	// ==Image Upload onChange Function=======
	photoUpload = (e) => {
		e.preventDefault();
		const imageFile = e.target.files[0];
		this.setState({
			imagePreviewUrl: URL.createObjectURL(imageFile),
			originalImage: imageFile,
			outputFileName: imageFile.name,
			uploadImage: true
		});
	};



	// ===function for input field validation

	onSaveData() {
		if (this.state.VerticalName != null) {
			if (this.state.imagePreviewUrl != 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png') {
				this.onPostVertical();

			} else {
				Notiflix.Notify.Failure("Please upload vertical image");
			}

		} else {
			Notiflix.Notify.Failure("Please enter vertical name");
		}
	}


	// onCompressPost = () => {

	// 	const options = {
	// 		maxSizeMB: 0.05,
	// 		useWebWorker: true
	// 	};

	// 	let output;
	// 	imageCompression(this.state.originalImage, options).then(x => {
	// 		output = x;

	// 		const downloadLink = URL.createObjectURL(output);

	// 		this.setState({
	// 			compressedLink: downloadLink
	// 		});
	// 	});

	// 	this.setState({

	// 	}, () => {
	// 		this.onPost()
	// 	})
	// 	return 1;
	// }


	// ===save vertical data on Database==


	onPostVertical = () => {

		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		if (this.state.VerticalLogo != this.state.imagePreviewUrl) {
			if (this.state.outputFileName != undefined) {

				//console.log('Add Vertical')
				PostApiCall.postRequest(
					{
						verticleid: this.state.VerticleId,
						verticlename: this.state.VerticalName,
						status: this.state.Status === "Yes" ? "Active" : "Inactive",
						updatedby: details[0].fld_userid,
						createdby: details[0].fld_userid,
						verticalimage: JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/VerticalImages/" +
							this.state.VerticalName.trim().replace(/\s/g, "-") + "-" + this.state.outputFileName,
						action: this.state.VerticleId == null ? 'insert' : 'update'

					},
					"AddVerticleMaster"
				).then((resultcategory) =>
					resultcategory.json().then((obj) => {
						if (resultcategory.status == 200 || resultcategory.status == 201) {
							//console.log('Vertical added')
							Notiflix.Loading.Remove();
							Notiflix.Notify.Success("Vertical successfully added. Image upload in process");
							this.onUploadImage(obj);
						}
						else {
							Notiflix.Loading.Remove();
							Notiflix.Notify.Failure(obj.data);
						}
					})
				);

			} else {
				Notiflix.Loading.Remove();
				Notiflix.Notify.Failure("Please Upload Vertical Image");
			}
		} else {
			this.updateVerticalWithOldImage()
		}
	}






	// =====Upload image on server====

	async onUploadImage(obj) {


		Notiflix.Loading.Dots("Uploading Images...");

		let response;

		if (this.state.BrandLogo != this.state.imagePreviewUrl) {
			if (this.state.outputFileName != undefined) {
				var options = {
					maxSizeMB: 0.05,
					maxWidthOrHeight: 1920,
					useWebWorker: true
				}
				imageCompression(this.state.originalImage, options)
					.then((compressedFile) => {

						const form = new FormData();

						form.append("file", compressedFile);
						form.append("foldername", "VerticalImages");
						form.append(
							"filename", this.state.VerticalName.trim().replace(/\s/g, "-") +
							"-" + compressedFile.name

						);

						response = fetch(this.state.ImageApiUrl, {
							method: "POST",
							body: form,
						}).then(response => response.json())
							.then(data => {
								this.setState({
									ImageName: data.Message.split(",")[2].split("=")[1].trim()
								})
								Notiflix.Loading.Remove()
								Notiflix.Notify.Success("Image successfully uploaded.")
								window.location.reload();
							}

							)


					})
			} else {
				Notiflix.Loading.Remove();
				Notiflix.Notify.Failure("Please Upload Brand Image");
			}
		} else {
			this.updateVerticalWithOldImage()
		}
	}



	// ====Update vertical data without image change

	updateVerticalWithOldImage = () => {

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{
				verticleid: this.state.VerticleId,
				verticlename: this.state.VerticalName,
				status: this.state.Status === "Yes" ? "Active" : "Inactive",
				updatedby: details[0].fld_userid,
				createdby: details[0].fld_userid,
				verticalimage: this.state.imagePreviewUrl,
				action: this.state.VerticleId == null ? 'insert' : 'update'
			},
			"AddVerticleMaster"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					//   //console.log(obj)
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success(" Vertical successfully updated.");
					window.location.reload();
				}
				else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data);
				}
			})
		);
	}


	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	toggleDrawer = () => {
		this.setState({
			IsOpen: !this.state.IsOpen,
		});
	};

	render() {

		// //console.log(JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_image_url_path + "/VerticalImages/" +
		// 	this.state.VerticalName.trim().replace(/\s/g, "-") + "-" + this.state.outputFileName)
		return (
			<React.Fragment>
				<Drawer
					open={this.state.IsOpen}
					onClose={this.toggleDrawer.bind(this)}
					direction="right"
					lockBackgroundScroll="no"
					width={450}
					className="sidebar-verticalmaster drawer"
				>
					<DragDropVertical
						verticalData={this.state.VerticleData}
						for="Vertical"
						htmlFor="verticle"
					/>
				</Drawer>
				<div className="page-content">
					<Container fluid>
						{/* Render Br1eadcrumb */}
						<Breadcrumbs breadcrumbItem={"Vertical Management"} urlPath={"/vertical-master"} />

						{/* ==========Vertical======= */}



						<Row>

							<Col xl="12">
								<Card className="overflow-hidden pagebackground">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text" id="upload-card">
														Vertical List
													</h5>
												</div>
											</Col>
											{/* <Col xs="6">
												<div className="Bechofy-text position-relative">
													<h5
														className="Bechofy-text sequ-btn-tooltip"
														id="upload-card"
													>
														<a
															onClick={this.toggleDrawer.bind(this)}
															className="align-items-center mt-1 Bechofy-btn vertical-sequentialize-btn"
															id="app-title"
														>
															<span>
																<img
																	src={Sequentialize}
																	alt="block user"
																	className="btnicons"
																	style={{ marginRight: "5px" }}
																></img>{" "}
															</span>
															Sequentialize
														</a>
													</h5>
												</div>
											</Col> */}

										</Row>
									</div>

									<Card style={{ padding: '10px 20px' }}>
										<div className="row my-1 ">


											<div className="col-12">
												<div class="input-group">


													<div class="input-group-prepend">
														<span class="input-group-text" id="basic-addon1">
															<span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
													</div>
													<input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
														onChange={(e) => this.seachBarHandler(e)}
														value={this.state.searchInput}

													/>
												</div>
											</div>


										</div>
									</Card>
									<Card >

										<CardBody className="pt-0">
											<Row>
												<Col xs="4">
													<CardBody className="categoryCard">

														<Row className="card-row">
															<Col sm="6">
																<div className="imagecard">
																	<div className="form-group ">
																		<label for="sw-arrows-first-name">
																			Upload Vertical Image
																			<span className="mandatory">*</span>
																		</label>
																		<div class="div1">
																			<ImgUpload
																				onChange={this.photoUpload}
																				src={this.state.imagePreviewUrl}
																			/>
																		</div>
																	</div>
																</div>
																{/* <hr style={{border:'1px solid #fff'}}/> */}
															</Col>
															<Col sm="6">
																<div className="row">
																	<h5>Add New Vertical</h5>
																	<div className="col-12">
																		<label class="my-1 mr-2">Vertical Name *</label>

																		<input
																			className="form-control"
																			name="name"
																			type="text"
																			placeholder="Enter Vertical Name"
																			value={this.state.VerticalName}
																			onChange={(text) => {
																				this.setState({
																					VerticalName: text.target.value,
																				});
																			}}
																		/>
																	</div>

																	<div className="col-12" style={{ marginTop: '10px' }}>
																		<div className="form-group">
																			<label for="CategoryName">
																				Status
																				<span className="mandatory">*</span>
																			</label>
																			<br />
																			<label class="radio-inline">
																				<input
																					type="radio"
																					name="optradio"
																					//  disabled={!this.state.IsVisible}
																					checked={
																						this.state.Status == "Yes"
																							? true
																							: false
																					}

																					onChange={() => {
																						this.setState({
																							Status: "Yes",
																						});
																					}}
																				/>{" "}
																				Active
																			</label>
																			<label
																				class="radio-inline"
																				style={{ marginLeft: "10px" }}
																			>
																				<input
																					type="radio"
																					name="optradio"
																					//  disabled={!this.state.IsVisible}
																					checked={
																						this.state.Status == "No"
																							? true
																							: false
																					}
																					onChange={() => {
																						this.setState({
																							Status: "No",
																						});
																					}}
																				/>{" "}
																				Inactive
																			</label>
																		</div>
																	</div>

																</div>
															</Col>
															<hr />
														</Row>
														<Row>
															<Col sm="6">
															</Col>
															<Col sm="6">

																<a
																	onClick={this.onSaveData.bind(this)}
																	className="btn Bechofy-btn waves-effect waves-light btn-sm float-right"
																	style={{ marginTop: "5px", color: "white" }}
																>
																	<span className=""><img src={Saveicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

																	Save Vertical
																</a>
															</Col>
														</Row>
													</CardBody>
												</Col>


												{/* ======Brand List */}
												{this.state.VerticleData.filter((data) => {
													if (this.state.searchInput == "") {
														return data;
													}
													if (
														this.state.searchInput !== "" &&
														(data.fld_verticlename
															.toLowerCase()
															.includes(this.state.searchInput.toLowerCase()) ||

															`${data.fld_status
																? data.fld_status.toLowerCase()
																: ""
																}`.includes(this.state.searchInput.toLowerCase()))
													) {
														return data;
													}
												}).map((data, i) => (

													<Col xs="4">
														<CardBody className="categoryCard">

															<Row className="card-row">
																<Col sm="5" style={{ marginTop: '15px' }}>
																	<div className="imagecard">
																		<div className="form-group custom-file-upload fas">

																			<div class="div1 img-wrap brandimages">
																				<img

																					src={data.fld_verticalImage}
																					style={{ width: "100%", height: "100%", borderRadius: "5%" }}
																					alt=""
																				/>
																			</div>

																		</div>
																	</div>
																	{/* <hr style={{border:'1px solid #fff'}}/> */}
																</Col>
																<Col sm="7">
																	<div className="row">
																		<div className="col-12" style={{ marginTop: '10px' }}>
																			<p>
																				<b>Vertical Name</b> -{" "}
																				<span

																				>
																					{data.fld_verticlename}
																				</span>
																			</p>

																		</div>

																		<div className="col-12">

																			<p>
																				<b>Total Items</b> -{" "}
																				<span>
																					{data.total_items}
																				</span>
																			</p>
																		</div>





																	</div>
																</Col>
																<hr />
															</Row>
															<Row>
																<Col sm="6" className="BrandStatus">
																	<p >
																		<b>Status</b> -{" "}
																		<span
																			style={{
																				color:
																					data.fld_status == "Active"
																						? "green"
																						: "red",
																			}}
																		>
																			{data.fld_status}
																		</span>
																	</p>
																</Col>
																<Col sm="6">



																	<span>
																		<a
																			onClick={() => {
																				this.setState({
																					VerticalName: data.fld_verticlename,
																					VerticleId: data.fld_verticleid,
																					Status: data.fld_status == 'Active' ? "Yes" : "No"
																				})
																				if (data.fld_verticalImage != null) {

																					this.setState({
																						imagePreviewUrl: data.fld_verticalImage,
																						VerticalLogo: data.fld_verticalImage
																					})
																				}
																				else {
																					this.setState({
																						imagePreviewUrl: 'https://icons.iconarchive.com/icons/pelfusion/flat-folder/256/Upload-Folder-icon.png',

																					})
																				}
																				window.location.href = "#upload-card"
																			}}
																			// onClick={this.onSaveData.bind(this)}
																			className="btn Bechofy-btn waves-effect waves-light btn-sm float-left"
																			style={{ marginTop: "5px", color: "white" }}
																		>
																			<span className=""><img src={Editicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

																			Edit Vertical
																		</a>
																	</span>
																	<span><img src={deleteicon} alt="block user" className="btnicons float-right mt-2"

																		onClick={() => {
																			confirmAlert({
																				title: "Confirm to Delete",
																				message:
																					"Are you sure you want to delete vertical.",
																				buttons: [
																					{
																						label: "Yes",
																						onClick: () => {
																							Notiflix.Loading.Dots("");
																							var login = localStorage.getItem("LoginDetail");
																							var details = JSON.parse(login);

																							PostApiCall.postRequest(
																								{
																									verticleid: data.fld_verticleid,
																									verticlename: data.fld_verticlename,
																									status: "Inactive",
																									updatedby: details[0].fld_userid,
																									createdby: details[0].fld_userid,
																									verticalimage: data.fld_verticalImage,
																									action: 'DELETE'
																								},
																								"AddVerticleMaster"
																							).then((resultcategory) =>
																								resultcategory.json().then((obj) => {
																									if (resultcategory.status == 200 || resultcategory.status == 201) {
																										// //console.log(obj)
																										Notiflix.Loading.Remove();
																										Notiflix.Notify.Success("This Vertical successfully deleted.");
																										window.location.reload();
																									}
																									else {
																										Notiflix.Loading.Remove();
																										Notiflix.Notify.Failure(obj.data);
																									}
																								})
																							);
																						},
																					},
																					{
																						label: "No",
																						// onClick: () => alert('Click No')
																					},
																				],
																			});
																		}}
																	></img></span>

																</Col>
															</Row>
														</CardBody>
													</Col>
												))}
											</Row>
										</CardBody>
									</Card>






								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default verticleMater;