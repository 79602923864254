import Notiflix from "notiflix";

var urlString;
if (localStorage.getItem("ClientDetails") != null) {
	urlString = JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress;
}

// var urlString = "http://localhost:8051/API/";

// var urlString = "http://localhost:8049/flosil-api/";

const GetApiCall = {
	getRequest(url) {
		// //console.log(urlString+url)
		return fetch(urlString + url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "*",
				"Content-Type": "application/json",
				"x-auth-token": JSON.parse(localStorage.getItem("access")),
			},
		}).then((response) => {
			if (response.status == 401) {
				Notiflix.Notify.failure("You are not authorized to access this page. Please login and try again.")
				window.location.href = "/"
			} else {
				return response;
			}
		})
			.catch((error) => {
				//console.log("request failed", error);
				return error;
			});
	},
};

export default GetApiCall;
