import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, Media } from "reactstrap";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import user1 from '../../assets/images/helpcenter.png';
import Blockicon from '../../assets/images/dashboardimages/blocked.png'
import OrderGraph from './OrderSummary'
import CustomerOrderList from './CustomerOrderList'
import CustomerCardHistory from './CustomerCardHistory'
import CustomerFrequentlyBought from './CustomerFrequentlyBought'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import ReactApexChart from "react-apexcharts";

class AddUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			imagePreviewUrl: "http://demo.globaltrendz.online/maxxchange/images/User/Shivani-1.jpg",

			// ImageApiUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddImage`,
			Status: "Active",
			ImageData: [],

			UnitofMeasurement: "",
			Price: 0,
			Points: 0,
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			RawMaterialName: "",
			GenderData: [
				{ value: "Male", label: "Male" },
				{ value: "Female", label: "Female" },
				{ value: "Others", label: "Others" },
			],
			Gender: "",
			CountryData: [],
			CityData: [],
			StateData: [],
			CountryId: 0,
			StateId: 0,
			CityId: 0,
			Name: "",
			Mobile: "",
			Email: "",
			DOB: "",
			AnniversaryDate: "",
			Designation: "",
			Department: "",
			UserType: "",
			Address: "",
			Country: "",
			State: "",
			City: "",
			Pincode: "",
			AadharCard: "",
			PANCard: "",
			DrivingLicenses: "",
			BankName: "",
			Branch: "",
			AccountNumber: "",
			IFSCCode: "",
			Salary: "",
			Password: "",
			ConfirmPassword: "",
			UserData: [
				{ value: "Admin", label: "Admin" },
				{ value: "Super Admin", label: "Super Admin" },
				{ value: "Users", label: "Users" },
			],
			// DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			NumRegex: /^[0-9]*$/,
			AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
			EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			UrlRegex: /^(https:\/\/www\.|https:\/\/www\.|https:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
			DepartmentData: [],
			AadharData: [],
			// DrivingUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddPdf`,
			DesignationData: [],
			BankData: [],
			isNewPasswordVisible: false,
			isConPasswordVisible: false,
			Details: [],
			Logintime: '',
			CustomerData: [],
			AddressData: [],
			OrderData: [],
			Landmark: "",
			Status: ""

		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		//   this.props.setclearbrand()
		var customer = localStorage.getItem('customerDetails');
		var CustomerDetails = JSON.parse(customer)
		// //console.log(CustomerDetails)


		var lastlogin = localStorage.getItem('lastlogin');
		var LastDateTime = JSON.parse(lastlogin)

		// //console.log(LastDateTime)
		this.setState({
			//   Details:details[0],
			Logintime: LastDateTime
		})


		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				WhereClause: `where fld_customerid=${CustomerDetails}`
			},
			"Get_customerData_by_id"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					console.log(obj.data)
					this.setState({
						CustomerData: obj.data,
						Name: obj.data[0].fld_name,
						Email: obj.data[0].fld_email_address,
						Mobile: obj.data[0].fld_phone_number,
						Gender: obj.data[0].fld_gender,
						DOB: obj.data[0].fld_dob,
						Address: obj.data[0].fld_address_1,
						Country: obj.data[0].CountryName,
						State: obj.data[0].StateName,
						City: obj.data[0].CityName,
						Pincode: obj.data[0].fld_pincode,
						Landmark: obj.data[0].fld_landmark,
						Status: obj.data[0].fld_status

					});
					Notiflix.Loading.Remove();
				}
			}))

		// ===============Address Data=================


		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				whereClause: `where fld_customerid=${CustomerDetails}`
			},
			"Get_Customer_Address"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						AddressData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))


		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{
				whereClause: `where fld_customerid=${CustomerDetails}`
			},
			"GetCustomerOrderDetails"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					console.log(obj.data)
					this.setState({
						OrderData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))


	}

	render() {
		//  //console.log(this.state)

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<Breadcrumbs breadcrumbItem={"Customer Profile"} urlPath={"/customer_profile"} />

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden pagebackground">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Customer Profile
													</h5>
												</div>
											</Col>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													{this.state.Status !== "Suspended" ?
														<button

															style={{
																float: "right",
																marginTop: "-5px",
																background: 'white',
																color: 'grey'
															}}
															className="btn align-items-center btn Bechofy-btn "
															onClick={() => {

																Notiflix.Loading.Dots()

																var customer = localStorage.getItem('customerDetails');
																var CustomerDetails = JSON.parse(customer)
																let details = JSON.parse(localStorage.getItem("LoginDetail"));


																PostApiCall.postRequest({
																	customerid: CustomerDetails,
																	name: this.state.Name,
																	email: this.state.Email,
																	mobile: this.state.Mobile,
																	gender: this.state.Gender,
																	dateofbirth: this.state.DOB,
																	presentaddress: this.state.Address,
																	presentcountry: this.state.Country,
																	presentstate: this.state.State,
																	presentcity: this.state.City,
																	presentpincode: this.state.Pincode,
																	password: null,
																	status: "Suspended",
																	updatedon: moment().format("DD/MM/YYYY"),
																	updatedby: details[0].fld_userid,
																	landmark: null,
																}, "UpdateCustomer").then((resultcategory) =>
																	resultcategory.json().then((obj) => {

																		if (
																			obj &&
																			obj.data[0] &&
																			obj.data[0].Result == "Successfully UPDATED"
																		) {
																			Notiflix.Loading.Remove();
																			Notiflix.Notify.Success("Customer Successfully Suspended");
																			window.location.reload()
																		} else {
																			Notiflix.Loading.Remove();
																			Notiflix.Notify.Failure("Something went wrong, please try again later");
																		}
																	})
																);
															}}
														>
															<span className=""><img src={Blockicon} alt="block user" className="btnicons"></img></span>	Suspend User{" "}
														</button>

														:
														<button

															style={{
																float: "right",
																marginTop: "-5px",
																background: 'white',
																color: 'grey'
															}}
															className="btn align-items-center btn Bechofy-btn "
															onClick={() => {

																Notiflix.Loading.Dots()

																var customer = localStorage.getItem('customerDetails');
																var CustomerDetails = JSON.parse(customer)
																let details = JSON.parse(localStorage.getItem("LoginDetail"));


																PostApiCall.postRequest({
																	customerid: CustomerDetails,
																	name: this.state.Name,
																	email: this.state.Email,
																	mobile: this.state.Mobile,
																	gender: this.state.Gender,
																	dateofbirth: this.state.DOB,
																	presentaddress: this.state.Address,
																	presentcountry: this.state.Country,
																	presentstate: this.state.State,
																	presentcity: this.state.City,
																	presentpincode: this.state.Pincode,
																	password: null,
																	status: "Active",
																	updatedon: moment().format("DD/MM/YYYY"),
																	updatedby: details[0].fld_userid,
																	landmark: null,
																}, "UpdateCustomer").then((resultcategory) =>
																	resultcategory.json().then((obj) => {

																		if (
																			obj &&
																			obj.data[0] &&
																			obj.data[0].Result == "Successfully UPDATED"
																		) {
																			Notiflix.Loading.Remove();
																			Notiflix.Notify.Success("Customer activated succesfully");
																			window.location.reload()
																		} else {
																			Notiflix.Loading.Remove();
																			Notiflix.Notify.Failure("Something went wrong, please try again later");
																		}
																	})
																);
															}}
														>
															Activate User{" "}
														</button>
													}
												</div>
											</Col>
										</Row>
									</div>

									<Row style={{ marginTop: '15px' }}>
										<Col xl="4" >
											<Card className="overflow-hidden" style={{ height: '390px' }}>
												<div className="Bechofy-bg-soft-pink" style={{ height: '85px' }}
												>
													{this.state.CustomerData.map((data, i) => {
														if (i == 0) {
															return (<Row>
																<Col xs="7">
																	<div className="Bechofy-text p-3" >
																		<h5 className="Bechofy-text" style={{ display: 'flex' }}>{data.fld_name}</h5>
																		<p>Customer Since {moment(data.fld_created_on).format("DD/MM/YYYY")}</p>

																	</div>

																</Col>
																<Col xs="5" className="align-self-end">
																	<img src={data.fld_image == null ? user1 : data.fld_image} alt="" className="img-fluid customerProfile" />
																</Col>
															</Row>
															)
														}
													}

													)}
												</div>


												<CardBody className="pt-6" style={{ paddingTop: '2rem' }}>

													<Row>
														<Col xs="5">
															<p>Date of Birth</p>
															<p>Marital Status</p>
															<p>Date of Anniversary</p>
															<p>Mobile</p>
															<p>Email</p>
															<p>Location</p>

														</Col>

														{this.state.CustomerData.map((data, i) => {
															if (i == 0) {
																return (
																	<Col xs="7">
																		<p>{moment(data.fld_dob).format("DD/MM/YYYY")} </p>
																		<p>{data.maritalStatus == null ? 'N/A' : data.maritalStatus}</p>
																		<p>{data.fld_doa == null ? 'N/A' : moment(data.fld_doa).format("DD/MM/YYYY")}</p>
																		<p>{data.fld_phone_number}</p>
																		<p>{data.fld_email_address}</p>
																		<p>{data.fld_address_1}, {data.fld_address_2}, {data.CityName}, {data.StateName}, {data.CountryName}</p>

																	</Col>
																)
															}
														})
														}

													</Row>
												</CardBody>
											</Card>

										</Col>

										<Col md="3">
											<Card className="mini-stats-wid ">
												<CardBody>
													<Media>
														<Media body>
															<div className="col-md-12">
																<div className="row">
																	<div className="col-md-8" >
																		<h4 className="mb-0 productTitle" >Order Summary
																		</h4>
																	</div>

																	<div className="col-md-4">
																		<i class="fa fa-list-alt OrdersIcons" aria-hidden="true"></i>

																		{/* <img src={UserIcon} alt="customer" className="ProductIcons"></img> */}

																	</div>

																</div>
															</div>
															<hr />
															<p className="text-muted font-weight-medium">
																Total Sales Value
																{this.state.OrderData.map((data, i) => {
																	if (i === 0) {
																		return (
																			<span className="text-muted font-weight-medium float-right Bechofy-count2 d-flex justify-content-center align-items-center">
																				{data.TotalSalesValue}
																			</span>
																		)
																	}
																})}
															</p>
															<hr />
															<p className="text-muted font-weight-medium">
																Order Value
																{this.state.OrderData.map((data, i) => {
																	if (i === 0) {
																		return (
																			<span className="text-muted font-weight-medium float-right Bechofy-count2 d-flex justify-content-center align-items-center">
																				{data.OrderValue}
																			</span>
																		)
																	}
																})}
															</p>
															<hr />
															<p className="text-muted font-weight-medium">
																Today's Orders
																{this.state.OrderData.map((data, i) => {
																	if (i === 0) {
																		return (
																			<span className="text-muted font-weight-medium float-right Bechofy-count2 d-flex justify-content-center align-items-center">
																				{data.Todays_order}
																			</span>
																		)
																	}
																})}
															</p>
															<hr />
															<p className="text-muted font-weight-medium">
																Today's Order Value
																{this.state.OrderData.map((data, i) => {
																	if (i === 0) {
																		return (
																			<span className="text-muted font-weight-medium float-right Bechofy-count2 d-flex justify-content-center align-items-center">
																				{data.Todays_order_value == null ? 0 : data.Todays_order_value}
																			</span>
																		)
																	}
																})}
															</p>
															<hr />
															<p className="text-muted font-weight-medium">
																This Month Order
																{this.state.OrderData.map((data, i) => {
																	if (i === 0) {
																		return (
																			<span className="text-muted font-weight-medium float-right Bechofy-count2 d-flex justify-content-center align-items-center">
																				{data.thisMonth_order}
																			</span>
																		)
																	}
																})}
															</p>
														</Media>
													</Media>
												</CardBody>
											</Card>
										</Col>



										<Col md="5">


											<OrderGraph />

											{/* <ReactApexChart options={this.state.options} series={this.state.series} type="donut" width={450} /> */}





										</Col>
									</Row>

									{/* Address -book */}
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden ">



												<CardBody className="pt-0 ">
													<CardTitle className="usertitle">
														Address Book
													</CardTitle>
													{this.state.AddressData.length != 0 ?
														<Row>
															{this.state.AddressData.map((data, i) => (


																<Col xs="4">
																	<CardBody className="categoryCard ">
																		<CardTitle style={{ display: 'flex' }}>
																			{data.fld_contact_name}
																			<span style={{ position: 'absolute', right: '30px' }}><p>Address Type : {data.fld_address_type}</p></span>

																		</CardTitle>

																		<Row className="card-row">

																			<Col sm="12">
																				<div className="">
																					<p>
																						{data.fld_address_1}
																						<br />
																						{data.fld_address_2} {data.fld_address_3}
																						<br />
																						{data.CityName} - {data.fld_pincode}. {data.CountryName}.
																						<br />
																						<b> Landmark:</b> {data.fld_landmark}
																					</p>
																					<p>
																						<b> Mobile </b> {data.fld_contact_number}
																						<br />
																						<b> Email ID </b> {data.fld_email_address}
																						<br />    <b> GSTIN </b> {data.fld_gstin}
																					</p>

																				</div>
																			</Col>

																		</Row>

																	</CardBody>
																</Col>
															))}


														</Row>
														:
														<Row>
															<p>No Address Data found for this customer.</p>
														</Row>
													}
												</CardBody>

											</Card>
										</Col>
									</Row>

									{/* Address -book */}
									{/* --incart */}
									<Row>
										<Col xl="12">
											<CustomerCardHistory />
											{/* <Card className="overflow-hidden ">

												<CardBody className="pt-0">
													<CardTitle className="usertitle">
														In Cart
													</CardTitle>

													<section class="product-list">
														<div class="container-fluid">
															<div class="row ">
																<div class="product-list">
																	<div class="card product-card shadow">
																										<img class="card-img-top img-fluid" src={productimage} alt="Card image cap" />
																		<div class="card-body">
																			<h5 class="card-title product-name">Nike Air Max 20</h5>
																			<h3 class="price">₹ 240.00</h3>
																			
																		</div>
																	</div>
																</div>

															</div>
														</div>

													</section>
												</CardBody>

											</Card> */}
										</Col>
									</Row>

									{/* ====in order */}
									<Row>
										<Col xl="12">
											<CustomerOrderList />

										</Col>
									</Row>

									{/* ==FRequently= */}
									{/* --incart */}
									<Row>
										<Col xl="12">
											<CustomerFrequentlyBought />

										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}



export default AddUser
