import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";


import Notiflix from "notiflix";
import PostApiCall from "../../Api";

import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Filtericon from '../../assets/images/dashboardimages/filter.png'
import Searchicon from '../../assets/images/dashboardimages/search.png'
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png'
import Calendericon from '../../assets/images/dashboardimages/calendar.png'
import Refreshicon from '../../assets/images/dashboardimages/refresh.png'

import GtLogo from "../../assets/images/bechofylogo.png";


// import DatePicker from 'react-date-picker';

class invoicesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            UserData: [],
            Id: "",
            searchInput: "",
            FromDate: null,
            ToDate: null,
            InvoiceData: [],
            SearchField: null
        };
    }

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {

                WhereClause: `where fld_line_status='Dispatched'`,
                RecordCount: 'Top 20 *'
            },
            "GetCustomerOrderDetails"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    //console.log(obj.data)
                    this.setState({
                        InvoiceData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))


    }


    onPost = () => {

        Notiflix.Loading.Dots("Please wait...");

        PostApiCall.postRequest(
            {

                RecordCount: '*',
                WhereClause: `where upper(SearchFiled) LIKE isnull('${this.state.SearchField}',upper(SearchFiled))
				and convert(varchar,fld_created_on,103)  BETWEEN isnull(${this.state.FromDate},'1990-01-01 00:00')
				and isnull(${this.state.ToDate},'getdate()')
				`,



            },
            "GetCustomerOrderDetails"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    // //console.log(obj.data)
                    this.setState({
                        InvoiceData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))
    }


    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    render() {
        const data = {
            columns: [
                {
                    label: "S.No.",
                    field: "sn",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Order Number",
                    field: "orderno",
                    sort: "asc",
                    width: 270,
                },
                {
                    label: "Order Date",
                    field: "order",
                    sort: "asc",
                    width: 200,
                },
                {
                    label: "Customer Name",
                    field: "name",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Number of Items",
                    field: "items",
                    sort: "asc",
                    width: 150,
                },
                {
                    label: "Order Value",
                    field: "value",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Payment Status",
                    field: "paymentstatus",
                    sort: "asc",
                    width: 100,
                },
                {
                    label: "Order Status",
                    field: "orderstatus",
                    sort: "asc",
                    width: 100,
                },

                {
                    label: "Action",
                    field: "action",
                    sort: "disabled",
                    width: 100,
                },
            ],
            rows: this.state.InvoiceData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                if (
                    this.state.searchInput !== "" &&
                    (data.fld_first_name
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase()) ||
                        `${data.fld_email_address ? data.fld_email_address.toLowerCase() : ""
                            }`.includes(this.state.searchInput.toLowerCase()) ||
                        `${data.fld_phone_number ? data.fld_phone_number.toLowerCase() : ""
                            }`.includes(this.state.searchInput.toLowerCase()) ||
                        `${data.CityName
                            ? data.CityName.toLowerCase()
                            : ""
                            }`.includes(this.state.searchInput.toLowerCase()))
                ) {
                    return data;
                }
            }).map((data, i) => {
                return {
                    sn: (i + 1),
                    orderno: (data.fld_order_number),
                    order: (data.fld_order_date),
                    name: (data.customer_name),
                    items: (data.total_items),
                    value: (data.fld_order_amount),
                    paymentstatus: (data.fld_payment_status),
                    orderstatus: (data.fld_order_status),
                    action: <i
                        className="fas fa-eye btn"
                        style={{ fontSize: "15px", paddingTop: '2px' }}
                        onClick={() => {
                            localStorage.setItem("InvoiveDetails", JSON.stringify(data.fld_order_id));
                            window.location.href = "/view_invoice";
                        }}
                    ></i>
                }
            })
        }



        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}

                        <div className="row">
                            <div className="col-md-6">


                                <div>
                                    <ul class="breadcrumbs">
                                        <li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>                                        <li>Invoice Management</li>

                                        <li>Invoice List</li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>

                            </div>


                        </div>
                        <Row>
                            <Col xl="12">
                                <Card className="pagebackground">
                                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                        <div className="row my-1">
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <h4 className="FilterCard" style={{ marginTop: '10px' }}>Filter Data By</h4>
                                                </div>
                                            </div>


                                        </div>
                                    </div>


                                    <Card style={{ padding: ' 0 20px' }}>
                                        <div className="row my-1 ">
                                            {/* <div className="col-2">
												<div className="form-group">
													<h4 className="FilterCard" style={{ marginTop: '20px',color:'#495057' }}>Filter Data By</h4>
												</div>
											</div> */}

                                            <div className="col-5">
                                                <div className="form-group ">

                                                    <div class="input-group">
                                                        <div class="input-group-prepend gridinput">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <span className=""><img src={Filtericon} alt="block user" className="btnicons"></img></span></span>
                                                        </div>
                                                        <input type="text"
                                                            value={this.state.SearchField}
                                                            onChange={(text) => {
                                                                this.setState({
                                                                    SearchField: text.target.value
                                                                })
                                                            }}
                                                            class="form-control gridinput" placeholder="Enter either Name / Email Address / Contact No. / City / State / Country" aria-label="Search" aria-describedby="basic-addon1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">

                                                <div className="form-group ">
                                                    <DatePicker
                                                        placeholderText="From Date"
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={this.state.FromDate}
                                                        onChange={(date) => {
                                                            this.setState({
                                                                FromDate: date
                                                            })
                                                        }}
                                                        isClearable
                                                        className="form-control gridinput"
                                                        maxDate={new Date()}
                                                    />
                                                    <span class="login-icon-change-pass" style={{ bottom: '30px' }}>
                                                        <img src={Calendericon} alt="datepicker" className="btnicons"></img>
                                                    </span>

                                                </div>
                                            </div>
                                            <div className="col-2">

                                                <div className="form-group">

                                                    <DatePicker
                                                        placeholderText="To Date"
                                                        dateFormat="dd/MM/yyyy"
                                                        selected={this.state.ToDate}
                                                        onChange={(date) => {
                                                            this.setState({
                                                                ToDate: date
                                                            })
                                                        }}
                                                        isClearable
                                                        className="form-control gridinput"
                                                        maxDate={new Date()}
                                                    />
                                                    <span class="login-icon-change-pass" style={{ bottom: '30px' }}>
                                                        <img src={Calendericon} alt="datepicker" className="btnicons"></img>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="col-3">
                                                <a
                                                    onClick={() => {
                                                        if (this.state.SearchField != null || this.state.FromDate != null || this.state.ToDate != null) {
                                                            this.onPost()
                                                        }
                                                        else {
                                                            Notiflix.Notify.Failure('Please select one search criteria')

                                                        }

                                                    }}

                                                    style={{

                                                        marginTop: "15px",
                                                        // background: 'white',
                                                        color: 'white',
                                                        marginLeft: '0px',
                                                        marginRight: '4px'
                                                    }}
                                                    className="btn align-items-center btn Bechofy-btn "
                                                >
                                                    Filter & Display
                                                </a>

                                                <a
                                                    onClick={() => {

                                                        Notiflix.Loading.Dots("Please wait...");

                                                        PostApiCall.postRequest(
                                                            {

                                                                WhereClause: '',
                                                                RecordCount: '*'
                                                            },
                                                            "GetInvoiceList"
                                                        ).then((results) =>
                                                            // const objs = JSON.parse(result._bodyText)
                                                            results.json().then((obj) => {
                                                                if (results.status == 200 || results.status == 201) {
                                                                    // //console.log(obj.data)
                                                                    this.setState({
                                                                        InvoiceData: obj.data,

                                                                    });
                                                                    Notiflix.Loading.Remove();
                                                                }
                                                            }))
                                                    }}

                                                    style={{

                                                        marginTop: "15px",
                                                        // background: 'white',
                                                        color: 'white',
                                                        marginLeft: '0px',
                                                        marginRight: '4px'
                                                    }}
                                                    className="btn align-items-center btn Bechofy-btn "
                                                >
                                                    List All Invoices
                                                </a>
                                                <a className="btn align-items-center btn Bechofy-btn "
                                                    style={{

                                                        marginTop: "15px",
                                                        // background: 'white',


                                                    }}
                                                    onClick={() => {
                                                        window.location.reload();
                                                    }}
                                                >
                                                    <span className=""><img src={Refreshicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

                                                </a>

                                            </div>


                                        </div>
                                    </Card>

                                    <Card >
                                        <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                            <div className="row my-1">
                                                <div className="col-2">
                                                    <div className="form-group">
                                                        <h4 className="FilterCard" style={{ marginTop: '10px' }}>Invoice List</h4>
                                                    </div>
                                                </div>

                                                <div className="col-10">
                                                    <div className="position-relative">

                                                        <a style={{
                                                            float: "right",
                                                            marginTop: "10px",
                                                            background: 'white',
                                                            color: 'grey',
                                                            marginRight: '20px'
                                                        }}
                                                            className="btn align-items-center btn Bechofy-btn "
                                                        >
                                                            <span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
                                                            Download List (.csv)
                                                        </a>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        <CardBody className="pt-0">
                                            <Row>


                                                <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                                    <div class="input-group mb-3">


                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">
                                                                <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                            onChange={(e) => this.seachBarHandler(e)}
                                                            value={this.state.searchInput}

                                                        />
                                                    </div>
                                                </MDBCol>

                                                <Col md="12">

                                                    {this.state.InvoiceData.length != 0 ?
                                                        <MDBDataTable
                                                            hover
                                                            // scrollY
                                                            striped
                                                            bordered
                                                            data={data}
                                                            seachTop={false}
                                                            entriesOptions={[25, 50, 100]}

                                                        >

                                                            <MDBTableHead columns={data.columns} />
                                                            <MDBTableBody rows={data.rows} />
                                                        </MDBDataTable>
                                                        :
                                                        <p>No Data Found</p>}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Card>

                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                        {/* </Card>

							</Col>
						</Row> */}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}
export default invoicesList;