import React, { Component } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CustomInput,
	CardTitle,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import PhotoUploader from "../../components/PhotoUploader";
import CKEditor from "ckeditor4-react";
import Notiflix from "notiflix";
import EmptyBox from "../../assets/images/users/UploadPlaceHolder.png";

// import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import Parser from "html-react-parser";
// import {XSquare} from 'react-feather';

var arr = [];
var arr2 = [];

const ImgUpload = ({ onChange, src }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas">
		<div className="img-wrap img-upload">
			<img
				for="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
	</label>
);

class categoryManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			enabled: false,
			startDate: new Date(),
			endDate: new Date(),
			imageValue: "",
			isImageValid: null,
			imagePreviewUrl:
				"https://www.iconsdb.com/icons/preview/pink/dropbox-xxl.png",
			Photos: [
				{
					image:
						"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
					value: "",
					data: "",
				},
				{
					image:
						"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
					value: "",
					data: "",
				},
				{
					image:
						"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
					value: "",
					data: "",
				},
				{
					image:
						"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
					value: "",
					data: "",
				},
				{
					image:
						"https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png",
					value: "",
					data: "",
				},
			],

			ImageApiUrl: `${JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress} + /AddImage`,

			imagedata: "",
			imageCount: 0,

			CategoryName: "",
			CategoryData: [],
			SubCatgeoryData: [],
			SubCategory: "",
			AttributData: [],
			ItemName: "",
			HSNCode: "",
			GSTRate: "",
			GSTData: [
				{ value: "5", label: "5" },
				{ value: "10", label: "10" },
				{ value: "12", label: "12" },
				{ value: "15", label: "15" },
				{ value: "18", label: "18" },
			],
			BrandName: "",
			BrandData: [{ value: "1", label: "Bechofy" }],
			VendorName: "",
			VendorData: [{ value: "1", label: "Bechofy" }],
			MarketedBy: "",

			ManufacturedBy: "",
			ManufacturedByData: [{ value: "1", label: "Bechofy" }],
			Importedby: "",
			CountryOrigindata: [],
			CountryOrigin: "",
			UnitofMeasurementData: [
				{ value: "1", label: "gm" },
				{ value: "2", label: "kg" },
				{ value: "3", label: "ml" },
				{ value: "4", label: "ltr" },
			],
			UOM: "",
			Status: "Yes",
			SKU: "",
			Barcode: "",
			VariantName: "",
			ShortDescription: "",
			LongDescription: "",
			AttributeValue: [],
			StockInHand: 0,
			CostPrice: 0,
			MRP: 0,
			SellingPrice: 0,
			Websitediscountprice: 0,
			ItemStatus: "Active",
			ProductID: "",
			PRoductData: [],
			SaveVariantData: [],
			FormStatus: "",
			fields: "",
			finaldata: "",
			name: "",
			groupArray: [],
			AttributeMappingId: null,
			VariantimageId: null,
			VariantPriceId: null,
			VariantID: null,
			Variantsdata: "",
			ProductScopeid: "",
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		if (localStorage.getItem("ProductDetails") != "") {
			var det = localStorage.getItem("ProductDetails");
			var variantdt = JSON.parse(det);

			this.setState({
				ProductID: det,
			});

			this.setState({
				CategoryName: variantdt.fld_categoryid,
				SubCategory: variantdt.fld_subcategoryid,
				ItemName: variantdt.fld_itemname,
				HSNCode: variantdt.fld_hsncode,
				GSTRate: variantdt.fld_Gstrate,
				VendorName: variantdt.fld_vendorid,
				ManufacturedBy: variantdt.fld_manufacturedByid,
				BrandName: variantdt.fld_brandid,
				MarketedBy: variantdt.fld_marketedbyId,
				Status: variantdt.fld_imported_product,
				CountryOrigin: variantdt.fld_origin_country_id,
				UOM: variantdt.fld_uom,
				Importedby: variantdt.fld_importedbyId,
				FormStatus: variantdt.fld_formstatus,
				ProductID: variantdt.fld_productid,
				SKU: variantdt.fld_sku,
				Barcode: variantdt.fld_barcode,
				VariantName: variantdt.fld_variantname,
				StockInHand: variantdt.fld_stock_in_hand,
				Websitediscountprice: variantdt.fld_website_discount_price,
				SellingPrice: variantdt.fld_selling_price,
				CostPrice: variantdt.fld_cost_price,
				MRP: variantdt.fld_mrp,
				VariantID: variantdt.fld_variantid,
				AttributeMappingId: variantdt.fld_attributemappingid,
				VariantimageId: variantdt.fld_variantimageid,
				VariantPriceId: variantdt.fld_variantpriceid,
			});

			PostApiCall.postRequest(
				{
					categoryid: variantdt.fld_categoryid,
				},
				"GetSubcategoryDropdown"
			).then((results1) =>
				results1.json().then((obj1) => {
					if (results1.status == 200 || results1.status == 201) {
						this.setState({
							SubCatgeoryData: obj1.data,
						});

						Notiflix.Loading.Remove();
					}
				})
			);

			PostApiCall.postRequest(
				{
					id: variantdt.fld_subcategoryid,
				},
				"GetCategoryAttributeValue"
			).then((results2) =>
				results2.json().then((obj2) => {
					if (results2.status == 200 || results2.status == 201) {
						this.setState({
							AttributData: obj2.data,
						});

						Notiflix.Loading.Remove();
					}
				})
			);

			new Promise((resolve, reject) => {
				setTimeout(resolve, 200);
			}).then(() => {
				this.setState({
					LongDescription: variantdt.fld_long_description,
					ShortDescription: variantdt.fld_short_description,
				});
			});

			PostApiCall.postRequest(
				{
					variantid: variantdt.fld_variantid,
				},
				"GetVariantImage"
			).then((results8) =>
				results8.json().then((obj8) => {
					if (results8.status == 200 || results8.status == 201) {
						//console.log(obj8.data);

						var photo = [...this.state.Photos];
						for (var i = 0; i < Object.keys(obj8.data).length; i++) {
							photo[
								obj8.data[i].fld_imageurl.split("-")[1].split(".")[0]
							].image = obj8.data[i].fld_imageurl;
							photo[
								obj8.data[i].fld_imageurl.split("-")[1].split(".")[0]
							].value = obj8.data[i].fld_imageurl;
						}
						this.setState({
							Photos: photo,
						});
					}
				})
			);
		}

		Notiflix.Loading.Dots("Please wait...");

		GetApiCall.getRequest("Get_categorydropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					CategoryData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetCountry").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					CountryOrigindata: obj.data,
				});
			})
		);
	}

	onChangeCategory(text) {
		this.setState(
			{
				CategoryName: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						categoryid: this.state.CategoryName,
					},
					"GetSubcategoryDropdown"
				).then((results1) =>
					// const objs = JSON.parse(result._bodyText)
					results1.json().then((obj1) => {
						if (results1.status == 200 || results1.status == 201) {
							// console.log(obj1.data)

							this.setState({
								SubCatgeoryData: obj1.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);

		//         fld_attributename: "color"
		// label: "s,m,xl,xxl"
		// value: 1
	}

	onChangesubCategory(text) {
		this.setState(
			{
				SubCategory: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						id: this.state.SubCategory,
					},
					"GetCategoryAttributeValue"
				).then((results2) =>
					// const objs = JSON.parse(result._bodyText)
					results2.json().then((obj2) => {
						if (results2.status == 200 || results2.status == 201) {
							// console.log(obj2.data)
							// console.log(obj2.data.fld_attributename)

							this.setState({
								AttributData: obj2.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}

	//  onImagePickerHanlder=(id,file,isvalid)=>{

	//   this.setState({...this.state,imageValue:file,
	//      isImageValid: isvalid})
	//   }

	photoUpload = (e) => {
		e.preventDefault();
		if (e.target.files[0].size < 100000) {
			const reader = new FileReader();
			const file = e.target.files[0];
			reader.onloadend = () => {
				this.setState({
					file: file,
					imagePreviewUrl: reader.result,
					ImageData: file,
				});
			};
			reader.readAsDataURL(file);
		} else {
			Notiflix.Notify.Failure("File too large, upload file less than 100 kb.");
		}
	};

	onSaveData() {
		//   console.log( this.state.AttributeMappingId)
		if (this.state.CategoryName != "") {
			if (this.state.SubCategory != "") {
				if (this.state.ItemName != "") {
					if (this.state.HSNCode != "") {
						if (this.state.GSTRate != "") {
							if (this.state.VendorName != "") {
								if (this.state.ManufacturedBy != "") {
									if (this.state.BrandName != "") {
										if (this.state.MarketedBy != "") {
											if (this.state.Status == "Yes") {
												if (this.state.Importedby != "") {
													if (this.state.CountryOrigin != "") {
														if (this.state.UOM != "") {
															this.onPostVariant();
														} else {
															Notiflix.Notify.Failure(
																"Please select unit of measurement"
															);
														}
													} else {
														Notiflix.Notify.Failure(
															"Please select country of origin"
														);
													}
												} else {
													Notiflix.Notify.Failure("Please select imported by");
												}
											} else {
												this.onPostVariant();
											}
										} else {
											Notiflix.Notify.Failure("Please select marketed by");
										}
									} else {
										Notiflix.Notify.Failure("Please select brand name");
									}
								} else {
									Notiflix.Notify.Failure("Please select manufactured by");
								}
							} else {
								Notiflix.Notify.Failure("Please select vendor name");
							}
						} else {
							Notiflix.Notify.Failure("Please select GST rate");
						}
					} else {
						Notiflix.Notify.Failure("Please enter HSN code");
					}
				} else {
					Notiflix.Notify.Failure("Please enter item name");
				}
			} else {
				Notiflix.Notify.Failure("Please select sub category name");
			}
		} else {
			Notiflix.Notify.Failure("Please select  category name");
		}
	}

	onPostVariant = () => {
		if (this.state.SKU != "") {
			if (this.state.VariantName != "") {
				if (this.state.ShortDescription != "") {
					if (this.state.ShortDescription.split(" ").length <= 300) {
						if (this.state.LongDescription != "") {
							if (this.state.ShortDescription.split(" ").length <= 700) {
								if (this.state.StockInHand != "") {
									if (this.state.MRP != "") {
										if (this.state.CostPrice != "") {
											if (this.state.SellingPrice != "") {
												if (this.state.Websitediscountprice != "") {
													this.onPostItem();
												} else {
													Notiflix.Notify.Failure(
														"Please enter website discounted Price"
													);
												}
											} else {
												Notiflix.Notify.Failure("Please enter Selling Price");
											}
										} else {
											Notiflix.Notify.Failure("Please enter Cost Price");
										}
									} else {
										Notiflix.Notify.Failure("Please enter MRP");
									}
								} else {
									Notiflix.Notify.Failure("Please enter stock in hand");
								}
							} else {
								Notiflix.Notify.Failure(
									"Please enter long Description with less than 700 characters"
								);
							}
						} else {
							Notiflix.Notify.Failure("Please enter long Description");
						}
					} else {
						Notiflix.Notify.Failure(
							"Please enter short description with less than 300 characters."
						);
					}
				} else {
					Notiflix.Notify.Failure("Please enter ShortDescription");
				}
			} else {
				Notiflix.Notify.Failure("Please enter variant name");
			}
		} else {
			Notiflix.Notify.Failure("Please enter sku");
		}
	};

	onSaveVariant() {
		if (this.state.SKU != "") {
			if (this.state.VariantName != "") {
				if (this.state.ShortDescription != "") {
					if (this.state.ShortDescription.split(" ").length <= 300) {
						if (this.state.LongDescription != "") {
							if (this.state.ShortDescription.split(" ").length <= 700) {
								if (this.state.StockInHand != "") {
									if (this.state.imageCount != 0) {
										if (this.state.MRP != "") {
											if (this.state.CostPrice != "") {
												if (this.state.SellingPrice != "") {
													if (this.state.Websitediscountprice != "") {
														// console.log('text')
														this.onPostVariantData();
													} else {
														Notiflix.Notify.Failure(
															"Please enter website discounted Price"
														);
													}
												} else {
													Notiflix.Notify.Failure("Please enter Selling Price");
												}
											} else {
												Notiflix.Notify.Failure("Please enter Cost Price");
											}
										} else {
											Notiflix.Notify.Failure("Please enter MRP");
										}
									} else {
										Notiflix.Notify.Failure(
											"Please upload atleast one product image."
										);
									}
								} else {
									Notiflix.Notify.Failure("Please enter stock in hand");
								}
							} else {
								Notiflix.Notify.Failure(
									"Please enter long Description with less than 700 characters"
								);
							}
						} else {
							Notiflix.Notify.Failure("Please enter long Description");
						}
					} else {
						Notiflix.Notify.Failure(
							"Please enter short description with less than 300 characters."
						);
					}
				} else {
					Notiflix.Notify.Failure("Please enter ShortDescription");
				}
			} else {
				Notiflix.Notify.Failure("Please enter variant name");
			}
		} else {
			Notiflix.Notify.Failure("Please enter sku");
		}
	}

	onPostItem = () => {
		// console.log('hiii')
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				productid: this.state.ProductID,
				categoryname: this.state.CategoryName,
				subcategoryname: this.state.SubCategory,
				categoryid: this.state.CategoryName,
				subcategoryid: this.state.SubCategory,
				itemname: this.state.ItemName,
				hsncode: this.state.HSNCode,
				Gstrate: this.state.GSTRate,
				vendorname: this.state.VendorName,
				manufactouredby: this.state.ManufacturedBy,
				brandname: this.state.BrandName,
				marketedby: this.state.MarketedBy,
				importedProduct: this.state.Status,
				importedBy: this.state.Importedby,
				originCountryId: this.state.CountryOrigin,
				uom: this.state.UOM,
				status: this.state.ItemStatus,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
				formstatus: "Completed",
			},
			"AddProductMaster"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					// console.log(obj.data)
					this.setState({
						ProductScopeid: JSON.parse(JSON.stringify(obj.data[0])).ProductId,
					});
					this.onPostVariantData();
				} else {
					this.onPostVariantData();
				}
			})
		);
	};

	onPostVariantData = () => {
		// console.log('varient'+ '.'+this.state.ProductScopeid)
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		PostApiCall.postRequest(
			{
				productid: this.state.ProductScopeid,
				varientid: this.state.VariantID,
				sku: this.state.SKU,
				barcode: this.state.Barcode,
				longdescripiton: this.state.LongDescription,
				variantname: this.state.VariantName,
				shortdescripiton: this.state.ShortDescription,
				stockInHand: this.state.StockInHand,
				status: this.state.ItemStatus,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
			},
			"AddVarient"
		).then((resultcategory1) =>
			resultcategory1.json().then((obj1) => {
				if (resultcategory1.status == 200 || resultcategory1.status == 201) {
					//console.log(obj1);

					this.onPostPrice(obj1);
				} else {
					this.onPostPrice(obj1);
				}
			})
		);
	};

	onPostPrice(obj1) {
		// console.log('price'+ '.'+this.state.ProductScopeid)

		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		PostApiCall.postRequest(
			{
				variantpriceid: this.state.VariantPriceId,
				variantid: JSON.parse(JSON.stringify(obj1.data[0])).VariantId,
				mrp: this.state.MRP,
				costPrice: this.state.CostPrice,
				sellingPrice: this.state.SellingPrice,
				websiteDiscountPrice: this.state.Websitediscountprice,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
			},
			"AddVariantPrice"
		).then((resultcategory2) =>
			resultcategory2.json().then((obj2) => {
				if (resultcategory2.status == 200 || resultcategory2.status == 201) {
					//console.log(obj2);
					this.onPostAttribute(obj1);
				} else {
					this.onPostAttribute(obj1);
				}
			})
		);
	}

	onPostAttribute(obj1) {
		// console.log(this.state.ProductID)
		//console.log("Attribute");
		Notiflix.Loading.Dots("Please wait...");
		//console.log("gu");
		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);
		var addattribute = "";
		for (var i = 0; i < this.state.AttributeValue.length; i++) {
			if (i == 0) {
				addattribute = this.state.AttributeValue[i].value;
			} else {
				addattribute = addattribute + ", " + this.state.AttributeValue[i].value;
			}
			//console.log(addattribute);
		}
		//console.log("h");
		//console.log(addattribute);
		PostApiCall.postRequest(
			{
				attributemappingid: this.state.AttributeMappingId,
				productid: this.state.ProductScopeid,
				variantid: JSON.parse(JSON.stringify(obj1.data[0])).VariantId,
				attributeValue: addattribute,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
			},
			"AddAttributeMasterMapping"
		)
	}

	onPostUploadImage(obj1) {
		//console.log("image");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		var empty = 0;

		var count = 0;

		for (var i = 0; i < this.state.Photos.length; i++) {
			////consolee.log(this.state.Photos);

			if (this.state.Photos[i].value != "" && this.state.Photos[i].data != "") {
				const form1 = new FormData();

				// //console.log(this.state.Photos[i].data)

				form1.append("file", this.state.Photos[i].data);
				form1.append("foldername", "ProductImages");
				form1.append(
					"filename",
					JSON.parse(JSON.stringify(obj1.data[0])).VariantId + "-" + i
				);

				fetch(this.state.ImageApiUrl, {
					method: "POST",
					body: form1,
					// eslint-disable-next-line no-loop-func
				}).then((image1) => {
					image1
						.json()
						.then((data1) => ({
							data: data1,
							status: image1.status,
						}))
						.then((res1) => {
							//console.log(res1.data);

							//console.log(res1.data.Message);

							PostApiCall.postRequest(
								{
									variantimageid: this.state.VariantimageId,
									variantid: JSON.parse(JSON.stringify(obj1.data[0])).VariantId,
									imageurl:
										"http://demo.globaltrendz.online/Bechofy/images/ProductImages/" +
										res1.data.Message.split(",")[2].split("=")[1].trim(),
									createdOn: moment().format("YYYY-MM-DD"),
									updatedOn: moment().format("YYYY-MM-DD"),
									updatedBy: details[0].fld_userid,
								},
								"AddVariantImage"
							).then((results2) =>
								// const objs = JSON.parse(result._bodyText)
								results2.json().then((obj2) => {
									if (results2.status == 200 || results2.status == 201) {
										count = count + 1;

										if (count == this.state.Photos.length) {
											Notiflix.Loading.Remove();

											Notiflix.Notify.Success(
												"Product variant added successfully."
											);

											window.location.href = "/item_management";
										}
									}
								})
							);
						});
				});
			} else if (
				this.state.Photos[i].value != "" &&
				this.state.Photos[i].data == ""
			) {
				//console.log("if2");

				PostApiCall.postRequest(
					{
						variantimageid: this.state.VariantimageId,
						variantid: JSON.parse(JSON.stringify(obj1.data[0])).VariantId,
						imageurl: this.state.Photos[i].value,
						createdOn: moment().format("YYYY-MM-DD"),
						updatedOn: moment().format("YYYY-MM-DD"),
						updatedBy: details[0].fld_userid,
					},
					"AddVariantImage"
				).then((results2) =>
					// const objs = JSON.parse(result._bodyText)
					results2.json().then((obj2) => {
						if (results2.status == 200 || results2.status == 201) {
							count = count + 1;
							empty = empty + 1;
							if (count == this.state.Photos.length) {
								Notiflix.Loading.Remove();

								Notiflix.Notify.Success("Product variant added successfully.");

								window.location.href = "/item_management";
							}
						}
					})
				);
			} else {
				count = count + 1;

				if (count == this.state.Photos.length) {
					Notiflix.Loading.Remove();

					Notiflix.Notify.Success("Product variant updated successfully.");
					window.location.href = "/item_management";
				}
			}
		}
	}

	//  attributeValue = (value) => {
	//   let key = value.target.name
	//   let val = value.target.value

	//   let obj = {key,val}

	//   let prev = localStorage.getItem("attriVal")

	//   if(!prev){

	//     localStorage.setItem("attriVal",JSON.stringify([obj]))
	//   }else{
	//    let data = JSON.parse(prev)
	//    localStorage.setItem("attriVal",JSON.stringify([...data,obj]) )

	//    if(JSON.parse(localStorage.getItem("attriVal")).length > 0 ){
	//      let val = JSON.parse(localStorage.getItem("attriVal"))
	//      let name = [...new Set(val.map((o)=>JSON.stringify(o)))].map((string)=>JSON.parse(string))

	//      name.filter((item,i)=>{
	//      console.log(item.key.indexOf(item.key)===i)
	//       if(item.key.indexOf(item.key)===i){
	//          console.log(item)
	//        }
	//     } )
	//    }

	//   }

	//  }

	groupBy = (objectArray, property) => {
		return objectArray.reduce((acc, obj) => {
			const key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}
			// Add object to list for given key's value
			acc[key].push(obj);
			return acc;
		}, {});
	};

	render() {
		// console.log(this.state.AttributeValue)

		let temp = [];

		this.state.AttributData.map((data, i) => {
			temp.push(data.fld_attributename);
		});
		temp = [...new Set(temp)];
		// console.log(temp)
		// console.log(this.state)

		let groupTemp = this.groupBy(this.state.AttributData, "fld_attributename");
		//  console.log(groupTemp)
		let KeysArray = Object.keys(groupTemp);
		let ValuesArray = Object.values(groupTemp);

		//  console.log(KeysArray)
		//  console.log(ValuesArray)

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs
							title={"Bechofy / Product Management"}
							breadcrumbItem={"Add New Product"}
						/>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"
										style={{ background: "#777f80" }}
									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Product Management
													</h5>
												</div>
											</Col>
										</Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Basic Information
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row  ">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col col-6">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Category Name
																				</label>
																				<select
																					value={this.state.CategoryName}
																					onChange={this.onChangeCategory.bind(
																						this
																					)}
																					className="custom-select my-1 mr-sm-2"
																					disabled={
																						this.state.FormStatus == "Completed"
																							? true
																							: false
																					}
																				>
																					<option value="">
																						Select Category
																					</option>
																					{this.state.CategoryData.map(
																						(Category) => (
																							<option
																								key={Category.value}
																								value={Category.value}
																							>
																								{Category.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col col-6">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Sub Category Name
																				</label>
																				<select
																					value={this.state.SubCategory}
																					onChange={this.onChangesubCategory.bind(
																						this
																					)}
																					className="custom-select my-1 mr-sm-2"
																					disabled={
																						this.state.FormStatus == "Completed"
																							? true
																							: false
																					}
																				>
																					<option value="">
																						Select Sub Category
																					</option>
																					{this.state.SubCatgeoryData.map(
																						(subcat) => (
																							<option
																								key={subcat.value}
																								value={subcat.value}
																							>
																								{subcat.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>

																			<div className="col col-12">
																				<div className="form-group">
																					<label for="CategoryName">
																						Item Name
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.ItemName}
																						onChange={(text) => {
																							this.setState({
																								ItemName: text.target.value,
																							});
																						}}
																						disabled={
																							this.state.FormStatus ==
																								"Completed"
																								? true
																								: false
																						}
																					/>
																				</div>
																			</div>

																			<div className="col-4">
																				<div className="form-group">
																					<label for="CategoryName">
																						HSN Code
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.HSNCode}
																						onChange={(text) => {
																							this.setState({
																								HSNCode: text.target.value,
																							});
																						}}
																						disabled={
																							this.state.FormStatus ==
																								"Completed"
																								? true
																								: false
																						}
																					/>
																				</div>
																			</div>
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					GST Rate
																				</label>
																				<select
																					value={this.state.GSTRate}
																					onChange={(text) => {
																						this.setState({
																							GSTRate: text.target.value,
																						});
																					}}
																					disabled={
																						this.state.FormStatus == "Completed"
																							? true
																							: false
																					}
																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">Select GST</option>
																					{this.state.GSTData.map((gst) => (
																						<option
																							key={gst.value}
																							value={gst.value}
																						>
																							{gst.label}
																						</option>
																					))}
																				</select>
																			</div>
																			<div className="col-4">
																				<div className="form-group">
																					<label for="CategoryName">
																						Vendor Name
																						<span className="mandatory">*</span>
																					</label>
																					<select
																						value={this.state.VendorName}
																						onChange={(text) => {
																							this.setState({
																								VendorName: text.target.value,
																							});
																						}}
																						disabled={
																							this.state.FormStatus ==
																								"Completed"
																								? true
																								: false
																						}
																						className="custom-select my-1 mr-sm-2"
																					>
																						<option value="">
																							Select Vendor{" "}
																						</option>
																						{this.state.VendorData.map(
																							(gst) => (
																								<option
																									key={gst.value}
																									value={gst.value}
																								>
																									{gst.label}
																								</option>
																							)
																						)}
																					</select>
																				</div>
																			</div>
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Manufactured By
																				</label>
																				<select
																					value={this.state.ManufacturedBy}
																					onChange={(text) => {
																						this.setState({
																							ManufacturedBy: text.target.value,
																						});
																					}}
																					disabled={
																						this.state.FormStatus == "Completed"
																							? true
																							: false
																					}
																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Manufactured By{" "}
																					</option>
																					{this.state.ManufacturedByData.map(
																						(gst) => (
																							<option
																								key={gst.value}
																								value={gst.value}
																							>
																								{gst.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Brand Name
																				</label>
																				<select
																					value={this.state.BrandName}
																					onChange={(text) => {
																						this.setState({
																							BrandName: text.target.value,
																						});
																					}}
																					disabled={
																						this.state.FormStatus == "Completed"
																							? true
																							: false
																					}
																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Brand{" "}
																					</option>
																					{this.state.BrandData.map((gst) => (
																						<option
																							key={gst.value}
																							value={gst.value}
																						>
																							{gst.label}
																						</option>
																					))}
																				</select>
																			</div>
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Marketed By
																				</label>
																				<select
																					value={this.state.MarketedBy}
																					onChange={(text) => {
																						this.setState({
																							MarketedBy: text.target.value,
																						});
																					}}
																					disabled={
																						this.state.FormStatus == "Completed"
																							? true
																							: false
																					}
																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Marketed By
																					</option>
																					{this.state.ManufacturedByData.map(
																						(gst) => (
																							<option
																								key={gst.value}
																								value={gst.value}
																							>
																								{gst.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>

																			<div className="col-12">
																				<div className="form-group my-1 mr-sm-2">
																					<label for="CategoryName">
																						Is This Product Imported?
																						<span className="mandatory">*</span>
																					</label>
																					<br />
																					<label class="radio-inline">
																						<input
																							type="radio"
																							name="optradio3"
																							//  disabled={!this.state.IsVisible}
																							checked={
																								this.state.Status == "Yes"
																									? true
																									: false
																							}
																							disabled={
																								this.state.FormStatus ==
																									"Completed"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									Status: "Yes",
																								});
																							}}
																						/>{" "}
																						Yes
																					</label>
																					<label
																						class="radio-inline"
																						style={{ marginLeft: "10px" }}
																					>
																						<input
																							type="radio"
																							name="optradio3"
																							disabled={
																								this.state.FormStatus ==
																									"Completed"
																									? true
																									: false
																							}
																							//  disabled={!this.state.IsVisible}
																							checked={
																								this.state.Status == "No"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									Status: "No",
																								});
																							}}
																						/>{" "}
																						No
																					</label>
																				</div>
																			</div>

																			<div
																				className="col col-4"
																				style={{
																					display:
																						this.state.Status == "Yes"
																							? ""
																							: "none",
																				}}
																			>
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Imported By
																				</label>
																				<select
																					value={this.state.Importedby}
																					onChange={(text) => {
																						this.setState({
																							Importedby: text.target.value,
																						});
																					}}
																					disabled={
																						this.state.FormStatus == "Completed"
																							? true
																							: false
																					}
																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Imported By{" "}
																					</option>
																					{this.state.ManufacturedByData.map(
																						(gst) => (
																							<option
																								key={gst.value}
																								value={gst.value}
																							>
																								{gst.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>

																			<div
																				className="col col-4"
																				style={{
																					display:
																						this.state.Status == "Yes"
																							? ""
																							: "none",
																				}}
																			>
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Country of Origin
																				</label>
																				<select
																					className="custom-select my-1 mr-sm-2"
																					disabled={
																						this.state.FormStatus == "Completed"
																							? true
																							: false
																					}
																					value={this.state.CountryOrigin}
																					onChange={(text) => {
																						this.setState({
																							CountryOrigin: text.target.value,
																						});
																					}}
																				>
																					<option value="">
																						Select Country
																					</option>
																					{this.state.CountryOrigindata.map(
																						(country) => (
																							<option
																								key={country.label}
																								value={country.label}
																							>
																								{country.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>

																			<div
																				className="col col-4"
																				style={{
																					display:
																						this.state.Status == "Yes"
																							? ""
																							: "none",
																				}}
																			>
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Unit of Measurement
																				</label>
																				<select
																					value={this.state.UOM}
																					onChange={(text) => {
																						this.setState({
																							UOM: text.target.value,
																						});
																					}}
																					disabled={
																						this.state.FormStatus == "Completed"
																							? true
																							: false
																					}
																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Unit of Measurement
																					</option>
																					{this.state.UnitofMeasurementData.map(
																						(unit) => (
																							<option
																								key={unit.label}
																								value={unit.label}
																							>
																								{unit.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Variant Information
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row  ">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Item Sku
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.SKU}
																						onChange={(text) => {
																							this.setState({
																								SKU: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Barcode
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.Barcode}
																						onChange={(text) => {
																							this.setState({
																								Barcode: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-6">
																				<div className="form-group">
																					<label for="CategoryName">
																						Variant Name
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.VariantName}
																						onChange={(text) => {
																							this.setState({
																								VariantName: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>

																			<div className="col-12">
																				<div class="form-group">
																					<label for="sw-arrows-first-name">
																						Short Description(maximum 300
																						Character)
																						<span className="mandatory">*</span>
																					</label>

																					<div class="niceeditors">
																						<CKEditor
																							config={{
																								extraPlugins:
																									"justify,font,colorbutton",
																							}}
																							data={this.state.ShortDescription}
																							onChange={(text) => {
																								this.setState({
																									ShortDescription: text.editor.getData(),
																								});
																							}}
																						//
																						/>
																					</div>
																				</div>
																			</div>

																			<div className="col-12">
																				<div class="form-group">
																					<label for="sw-arrows-first-name">
																						Item Description(maximum 700
																						Character)
																						<span className="mandatory">*</span>
																					</label>

																					<div class="niceeditors">
																						<CKEditor
																							config={{
																								extraPlugins:
																									"justify,font,colorbutton",
																							}}
																							data={this.state.LongDescription}
																							onChange={(text) => {
																								this.setState({
																									LongDescription: text.editor.getData(),
																								});
																							}}
																						/>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<h2>Attribute Information</h2>

															<div className="row">
																{KeysArray.map((attri, aindex) => (
																	<div className="col-3">
																		<div className="form-group">
																			<label for="CategoryName">{attri}</label>

																			<select
																				className="custom-select my-1 mr-sm-2"
																				// value={attri.selectedvalue}
																				onChange={(e) => {
																					let tempObj = {
																						name: attri,
																						value: e.target.value,
																					};
																					let index = this.state.AttributeValue.findIndex(
																						(data) => {
																							return tempObj.name == data.name;
																						}
																					);
																					let TempAttributeArray = [
																						...this.state.AttributeValue,
																					];

																					if (index !== -1) {
																						TempAttributeArray.splice(index, 1);
																						TempAttributeArray.push(tempObj);
																					} else {
																						TempAttributeArray.push(tempObj);
																					}
																					this.setState({
																						...this.state,
																						AttributeValue: TempAttributeArray,
																					});
																				}}
																			>
																				<option value="">Select</option>
																				{ValuesArray[aindex].map((data, i) => {
																					return (
																						<option
																							key={data.label}
																							value={data.value}
																						>
																							{data.label}
																						</option>
																					);
																				})}
																				))
																			</select>
																		</div>
																	</div>
																))}

																<div className="col-6">
																	<div className="form-group">
																		<label for="CategoryName">
																			Stock In Hand
																			<span className="mandatory">*</span>
																		</label>
																		<input
																			type="text"
																			id="CategoryName"
																			className="form-control my-1 mr-sm-2"
																			value={this.state.StockInHand}
																			onChange={(text) => {
																				this.setState({
																					StockInHand: text.target.value,
																				});
																			}}
																		/>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div class="col-md-12">
																	<div class="form-group mb-3">
																		<label
																			for="validationCustom01"
																			class="productimg"
																		>
																			Product Images (200 X 200 PX)
																		</label>
																		<div class="div1" class="row">
																			{this.state.Photos.map((photo, index) => (
																				<div
																					style={{
																						marginLeft: "1%",
																						marginRight: "2%",
																					}}
																				>
																					<label className="custom-file-upload fas">
																						<div className="img-wrap img-upload">
																							<img
																								for="photo-upload"
																								src={photo.image}
																								style={{
																									width: "100%",
																									height: "100%",
																									borderRadius: "5%",
																								}}
																							/>
																						</div>
																						{/* <XSquare class='product-img'
                                                                        onClick={()=>{
                                                                          var arr = [...this.state.Photos]
                                                                          arr[index].image =  'https://www.pngrepo.com/png/46739/170/add-to-shopping-bag.png'
                                                                          arr[index].value =  ''
                                                                          arr[index].data =  ''
                                                                          this.setState({
                                                                            Photos : arr
                                                                          })
                                                                        }}
                                                                        ></XSquare> */}
																						<input
																							id="photo-upload"
																							accept="image/*"
																							type="file"
																							onChange={(e) => {
																								if (
																									e.target.files[0] != undefined
																								) {
																									if (
																										e.target.files[0].size <
																										400000
																									) {
																										var arr = [
																											...this.state.Photos,
																										];
																										const reader = new FileReader();
																										const file =
																											e.target.files[0];
																										reader.onload = (e) => {
																											arr[index].image =
																												e.target.result;
																											arr[index].value =
																												e.target.result;
																											arr[index].data = file;

																											// this.props.setstaffimage(e.target.result);
																											this.setState({
																												Photos: arr,
																												imageCount:
																													this.state
																														.imageCount + 1,
																											});
																										};
																										reader.readAsDataURL(file);
																									} else {
																										Notiflix.Notify.Failure(
																											"File too large, upload file less than 400 kb."
																										);
																									}
																								}
																							}}
																						/>
																					</label>
																				</div>
																			))}
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card className="overflow-hidden">
												<div className="Bechofy-bg-soft-pink">
													<Row>
														<Col xs="3">
															<div className="Bechofy-text p-3">
																<h5 className="Bechofy-text">
																	Price{" "}
																</h5>
															</div>
														</Col>
													</Row>
												</div>
												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row  ">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						MRP
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.MRP}
																						onChange={(text) => {
																							this.setState({
																								MRP: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Cost Price
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.CostPrice}
																						onChange={(text) => {
																							this.setState({
																								CostPrice: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Selling Price
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.SellingPrice}
																						onChange={(text) => {
																							this.setState({
																								SellingPrice: text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																			<div className="col-3">
																				<div className="form-group">
																					<label for="CategoryName">
																						Website Discounted Price
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={
																							this.state.Websitediscountprice
																						}
																						onChange={(text) => {
																							this.setState({
																								Websitediscountprice:
																									text.target.value,
																							});
																						}}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Show on Website
																			<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.ItemStatus == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						ItemStatus: "Active",
																					});
																				}}
																			/>{" "}
																			Yes
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.ItemStatus == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						ItemStatus: "Inactive",
																					});
																				}}
																			/>{" "}
																			No
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{ float: "right", marginTop: "5px" }}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.onSaveData.bind(this)}
																	>
																		Save & Add More
																	</button>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>
										</Col>
									</Row>
									{/* -row */}
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default categoryManagement;
