import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";


import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "react-confirm-alert/src/react-confirm-alert.css";

import "react-datepicker/dist/react-datepicker.css";


import Searchicon from '../../assets/images/dashboardimages/search.png'


import Editicon from '../../assets/images/dashboardimages/pencil.png'
import addicon from '../../assets/images/dashboardimages/product.png'
import Drawer from "react-modern-drawer";
import "react-responsive-modal/styles.css";
import "react-modern-drawer/dist/index.css";
import "../../pages/DragDroplist/DragDropList.css";
import DragDropList from "../DragDroplist/DragDropList";
import Sequentialize from "../../assets/images/dashboardimages/Sequentialize.png";


// import DatePicker from 'react-date-picker';

class DesignImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MaterialData: [],
            open: false,
            view: false,
            PublishDate: "",
            Id: "",
            searchInput: "",
            ViewClick: "true",
            ProductData: [],
            VariantData: [],

            Vertical: null,
            SearchField: null,

            PVPMData: [],
            IsOpen: false,


        };
    }

    // onCloseModal = () => {
    //     this.setState({ open: false });
    //   };

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        Notiflix.Loading.Dots("Please wait...");

        localStorage.removeItem('PSMDetails')







        PostApiCall.postRequest(
            {

                whereClause: '',
                recordCount: '*'
            },
            "GetDesign"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    console.log(obj.data)
                    this.setState({
                        PVPMData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))


    }







    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    toggleDrawer = () => {
        this.setState({
            IsOpen: !this.state.IsOpen,
        });
    };
    render() {
        const { text, suggestions } = this.state;





        // =========VariantList=============
        const PVPMData = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Variant Name",
                    field: "VN",
                    sort: "disabled",
                    width: 400,
                },
                {
                    label: "Design Name",
                    field: "DesignName",
                    sort: "disabled",
                    width: 150,
                },

                {
                    label: "Design Image",
                    field: "DesignImage",
                    sort: "disabled",
                    width: 100,
                },



                // {
                //     label: "Action",
                //     field: "action",
                //     sort: "disabled",
                //     width: 100,
                // },
            ], // end for variant
            rows: this.state.PVPMData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                } if (
                    this.state.searchInput !== "" &&
                    (data.fld_design_name
                        .toLowerCase()
                        .includes(this.state.searchInput.toLowerCase()) ||
                        `${data.variantName != null ? data.variantName.toLowerCase() : ""
                            }`.includes(this.state.searchInput.toLowerCase()))) {
                    return data
                }

            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    VN: (
                        data.variantName
                    ),
                    DesignName: (
                        data.fld_design_name

                    ),

                    DesignImage: (
                        <img src={data.image1} alt="" style={{ width: '50px' }} />
                    ),


                    // action: (
                    //     <span>
                    //         <img src={Editicon} alt="View user" className="btnicons" onClick={() => {
                    //             localStorage.setItem(
                    //                 "VPMDetails",
                    //                 JSON.stringify(data)
                    //             );
                    //             window.location.href = "/add_designimage/update";
                    //         }}></img>

                    //     </span>
                    // )



                }
            })
        }




        return (
            <React.Fragment>

                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}

                        <Breadcrumbs breadcrumbItem={"Design Image Master"} urlPath={"/design_image"} />

                        <Row>
                            <Col xl="12">
                                <Card>
                                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px', }}>
                                        <div className="row my-1">
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <h4 className="FilterCard" style={{ marginTop: '10px' }}>Design Image List</h4>
                                                </div>
                                            </div>
                                            {/* <div className="col-7">
                                                <div className="Bechofy-text position-relative">
                                                    <h5
                                                        className="Bechofy-text sequ-btn-tooltip"
                                                        id="upload-card"
                                                    >
                                                        <a
                                                            onClick={this.toggleDrawer.bind(this)}
                                                            className="align-items-center mt-1 Bechofy-btn vertical-sequentialize-btn"
                                                            id="app-title"
                                                        >
                                                            <span>
                                                                <img
                                                                    src={Sequentialize}
                                                                    alt="block user"
                                                                    className="btnicons"
                                                                    style={{ marginRight: "5px" }}
                                                                ></img>{" "}
                                                            </span>
                                                            Sequentialize
                                                        </a>
                                                       
                                                    </h5>
                                                </div>
                                            </div> */}
                                            <div className="col-3">
                                                <div className="position-relative">


                                                    <button
                                                        onClick={() => {

                                                            window.location.href = "/add_designimage/create";
                                                        }}

                                                        className="btn align-items-center btn Bechofy-btn AddNewBtn"
                                                    >
                                                        Add / Update Design Image{" "}
                                                        <span className=""><img src={addicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

                                                    </button>




                                                </div>
                                            </div>


                                        </div>
                                    </div>





                                    <CardBody className="pt-0">
                                        <Row>


                                            <MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
                                                <div class="input-group mb-3">


                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">
                                                            <span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
                                                    </div>
                                                    <input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
                                                        onChange={(e) => this.seachBarHandler(e)}
                                                        value={this.state.searchInput}

                                                    />
                                                </div>
                                            </MDBCol>

                                            <Col md="12">

                                                {this.state.PVPMData.length !== 0 ?
                                                    <MDBDataTable
                                                        hover
                                                        // scrollY
                                                        striped
                                                        bordered
                                                        data={PVPMData}
                                                        seachTop={false}
                                                        entriesOptions={[25, 50, 100]}

                                                    >

                                                        <MDBTableHead columns={PVPMData.columns} />
                                                        <MDBTableBody rows={PVPMData.rows} />
                                                    </MDBDataTable>
                                                    :
                                                    <p>
                                                        No Data Found
                                                    </p>
                                                }


                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>


                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                        {/* </Card>

							</Col>
						</Row> */}
                    </Container>
                </div >
                <Drawer
                    open={this.state.IsOpen}
                    onClose={this.toggleDrawer.bind(this)}
                    direction="right"
                    lockBackgroundScroll="no"
                    width={450}
                    className="sidebar-verticalmaster drawer"
                >
                    <DragDropList
                        verticalData={this.state.PVPMData}
                        verticalDropdown={this.state.VerticleData}
                        for="Vertical"
                        htmlFor="verticle"
                    />
                </Drawer>
            </React.Fragment >
        );
    }
}
export default DesignImage;
