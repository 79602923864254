import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { MDBDataTableV5, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Link } from "react-router-dom";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class StaticPageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MaterialData: [],
      open: false,

      PublishDate: "",
      Id: "",
      searchInput: "",
      ViewClick: "true",
      OfferData: [],
    };
  }

  // onCloseModal = () => {
  //     this.setState({ open: false });
  //   };

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    Notiflix.Loading.Dots("");

    GetApiCall.getRequest("GetPageList").then((resultdes) =>
      resultdes.json().then((obj) => {
        console.log(obj.data);
        this.setState({
          OfferData: obj.data,
        });

        Notiflix.Loading.Remove();
      })
    );
  }

  seachBarHandler = (e) => {
    this.setState({ ...this.state, searchInput: e.target.value });
  };
  render() {
    const data = {
      columns: [
        {
          label: "S.No.",
          field: "serial",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Page Name",
          field: "pagename",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Page Heading",
          field: "pageheading",
          sort: "disabled",
          width: 150,
        },
        {
          label: "Page Content",
          field: "pagecontent",
          sort: "disabled",
          width: 400,
        },
        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 100,
        },
      ],

      rows: this.state.OfferData.filter((data) => {
        if (this.state.searchInput == "") {
          return data;
        }
        if (
          this.state.searchInput !== "" &&
          (`${data.fld_name ? data.fld_name.toLowerCase() : ""}`.includes(
            this.state.searchInput.toLowerCase()
          ) ||
            `${
              data.fld_caption ? data.fld_caption.toLowerCase() : ""
            }`.includes(this.state.searchInput.toLowerCase()) ||
            `${data.fld_code ? data.fld_code.toLowerCase() : ""}`.includes(
              this.state.searchInput.toLowerCase()
            ))
          //   ||

          //   `${data.fld_pricepercent?data.fld_pricepercent.toLowerCase():''}`.includes(this.state.searchInput.toLowerCase())
        ) {
          return data;
        }
      }).map((data, i) => {
        return {
          serial: i + 1,
          offername: data.fld_name,
          offercaption: data.fld_caption,
          code: data.fld_code,
          price: data.fld_pricepercent,
          max: data.fld_maxdisprice,
          minapplicableamount: data.fld_minapplyamnt,
          maxapplicableamount: data.fld_maxapplyamnt,
          start: moment(data.fld_startdate).format("DD/MM/YYYY"),
          end: moment(data.fld_enddate).format("DD/MM/YYYY"),
          offerstatus: data.fld_showonwebsite,
          action: (
            <td className="text-center actionuser">
              <i
                className="fas fa-edit btn"
                style={{ fontSize: "15px", marginTop: "-11px" }}
                onClick={() => {
                  localStorage.setItem(
                    "PageDetails",
                    JSON.stringify({ ...data })
                  );
                  window.location.href = "/edit-offer";
                }}
              ></i>
              <span className="btn" style={{ marginTop: "-9px" }}>
                <i
                  className="fa fa-trash py-auto "
                  aria-hidden="true"
                  style={{ fontSize: "15px" }}
                  onClick={() => {
                    confirmAlert({
                      title: "Confirm to Delete",
                      message: "Are you sure you want to delete page.",
                      buttons: [
                        {
                          label: "Yes",
                          onClick: () => {
                            Notiflix.Loading.Dots("");

                            PostApiCall.postRequest(
                              {
                                offer_id: data.fld_offerid,
                                type:
                                  this.state.CategoryName != "" &&
                                  this.state.SubCategory == ""
                                    ? "Category"
                                    : this.state.SubCategory != "" &&
                                      this.state.ItemName == ""
                                    ? "SubCategory"
                                    : this.state.ItemName != ""
                                    ? "Variant"
                                    : "Vertical",
                                typeid:
                                  this.state.CategoryName != "" &&
                                  this.state.SubCategory == ""
                                    ? this.state.CategoryName
                                    : this.state.SubCategory != "" &&
                                      this.state.ItemName == ""
                                    ? this.state.SubCategory
                                    : this.state.ItemName != ""
                                    ? this.state.ItemName
                                    : this.state.VerticalName,
                                name: this.state.OfferName,
                                caption: this.state.OfferCaption,
                                pricepercent: this.state.OfferPrice,
                                maximumdiscountprice:
                                  this.state.MaximumDiscountPrice,
                                minapplyamnt:
                                  this.state.MinimumAmountApplicable,
                                maxapplyamnt:
                                  this.state.MaximumAmountApplicable,
                                minimumproduct: this.state.MinimumProducts,
                                freegift: this.state.FreeGifts,
                                firstorder: this.state.FirstOrder,
                                allorder: this.state.AllOrders,
                                onetime: this.state.OneTime,
                                freeshipping: this.state.FreeShipping,
                                refferaldiscount: this.state.RefferalDiscount,
                                description: this.state.OfferDescription,
                                code: this.state.OfferCode,
                                startdate: this.state.StartDate,
                                enddate: this.state.EndDate,
                                termscondition: this.state.Terms,
                                showonwebsite: "Delete",
                                userid: JSON.parse(
                                  localStorage.getItem("LoginDetail")
                                )[0].fld_userid,
                              },
                              "AddOffer"
                            ).then((results) =>
                              // const objs = JSON.parse(result._bodyText)
                              results.json().then((obj) => {
                                if (
                                  results.status == 200 ||
                                  results.status == 201
                                ) {
                                  Notiflix.Loading.Remove();
                                  Notiflix.Notify.Success(
                                    "Page successfully deleted."
                                  );
                                  window.location.reload();
                                } else {
                                  Notiflix.Loading.Remove();
                                  Notiflix.Notify.Failure(
                                    "Something went wrong, try again later."
                                  );
                                }
                              })
                            );
                          },
                        },
                        {
                          label: "No",
                          // onClick: () => alert('Click No')
                        },
                      ],
                    });
                  }}
                ></i>
              </span>
            </td>
          ),
        };
      }),
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={"Bechofy / Static Pages"}
              breadcrumbItem={"Static Pages List"}
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div
                    className="Bechofy-bg-soft-pink"
                    style={{ background: "#777f80" }}
                  >
                    <Row>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <h5 className="Bechofy-text text-white">
                            Static Pages
                          </h5>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="Bechofy-text p-3">
                          <a
                            onClick={() => {
                              window.location.href = "/add_static_pages";
                              
                            }}
                            style={{
                              float: "right",
                              marginTop: "-5px",
                              color: "#777f80",
                              backgroundColor: "#fff",
                            }}
                            className="btn align-items-center btn Bechofy-btn "
                          >
                            Add New Page{" "}
                            <i className="fa fa-plus" aria-hidden="true"></i>
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col xl="12">
                      <Card className="overflow-hidden">
                        <CardBody className="pt-0">
                          <Row></Row>
                        </CardBody>
                        <CardBody className="pt-0">
                          <Row>
                            <MDBCol md="12" style={{ marginBottom: "10px" }}>
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Search by Page Name"
                                aria-label="Search"
                                onChange={(e) => this.seachBarHandler(e)}
                                value={this.state.searchInput}
                              />
                            </MDBCol>
                            <Col md="12">
                              {this.state.OfferData.length === 0 ? (
                                <MDBDataTableV5
                                  hover
                                  // scrollY
                                  striped
                                  bordered
                                  data={data}
                                  seachTop={false}
                                  entriesOptions={[10, 25, 50, 100]}
                                >
                                  <MDBTableHead columns={data.columns} />
                                  <MDBTableBody rows={data.rows} />
                                </MDBDataTableV5>
                              ) : (
                                <p>No Data Found</p>
                              )}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                      {/* <WelcomeComp /> */}
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default StaticPageList;
