import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import { MDBCol, MDBDataTable, MDBTableBody, MDBTableHead } from "mdbreact";


import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";


import "react-confirm-alert/src/react-confirm-alert.css";

import "react-datepicker/dist/react-datepicker.css";

import Filtericon from '../../assets/images/dashboardimages/filter.png';
import Searchicon from '../../assets/images/dashboardimages/search.png';

import Refreshicon from '../../assets/images/dashboardimages/refresh.png';

import Editicon from '../../assets/images/dashboardimages/pencil.png';
import addicon from '../../assets/images/dashboardimages/product.png';
import varianticon from '../../assets/images/dashboardimages/variant.png';


import variantlist from '../../assets/images/dashboardimages/variantlist.png';

import itemicon from '../../assets/images/dashboardimages/item.png';
import Backicon from '../../assets/images/dashboardimages/undo.png';

import GtLogo from "../../assets/images/bechofylogo.png";


// import DatePicker from 'react-date-picker';

class ProductList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			MaterialData: [],
			open: false,
			view: false,
			PublishDate: "",
			Id: "",
			searchInput: "",
			ViewClick: "true",
			ProductData: [],
			VariantData: [],
			Category: null,
			SubCategory: null,
			Brand: null,
			Vertical: null,
			SearchField: null,
			ViewProductList: true,
			ViewVariantList: false,
			suggestions: [],
			BrandData: [],
			VerticalData: [],
			CategoryData: [],
			SubCatgeoryData: []
		};
	}

	// onCloseModal = () => {
	//     this.setState({ open: false });
	//   };

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		localStorage.removeItem("VariantImageID")
		localStorage.removeItem("VariantIdForImage")

		localStorage.removeItem("itemDetails")
		localStorage.removeItem("VariantDetails")
		Notiflix.Loading.Dots("Please wait...");


		GetApiCall.getRequest("GetBrandDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					BrandData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);



		GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					VerticalData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		// ======Get Item List==========

		PostApiCall.postRequest(
			{

				whereClause: '',
				recordCount: '*'
			},
			"GetProductMaster"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					//console.log(obj.data)
					this.setState({
						ProductData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))


		// ========GetVariantList=========





	}



	onChangeCategory(text) {
		this.setState(
			{
				Category: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						categoryid: this.state.Category,
					},
					"Get_subcategoryDropdown"
				).then((results1) =>
					results1.json().then((obj1) => {
						if (results1.status == 200 || results1.status == 201) {
							this.setState({
								SubCatgeoryData: obj1.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}



	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	render() {
		const { text, suggestions } = this.state;


		const itemdata = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},

				{
					label: "Item Name",
					field: "itemname",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Classification",
					field: "classification",
					sort: "disabled",
					width: 150,
				},


				{
					label: "GST (%)",
					field: "gst",
					sort: "disabled",
					width: 150,
				},


				// {
				// 	label: "Status",
				// 	field: "status",
				// 	sort: "disabled",
				// 	width: 150,
				// },

				{
					label: "Action",
					field: "actions",
					sort: "disabled",
					width: 100,
				},
			], // end for variant
			rows: this.state.ProductData.filter((data) => {
				if (this.state.searchInput == "") {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_itemname
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_verticlename ? data.fld_verticlename.toLowerCase() : ""
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_categoryname ? data.fld_categoryname.toLowerCase() : ""
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_subcategoryname
							? data.fld_subcategoryname.toLowerCase()
							: ""
							}`.includes(this.state.searchInput.toLowerCase()))
				) {
					return data;
				}
			}).map((data, i) => {
				return {
					serial: (i + 1),
					itemname: (
						<div>
							<span className="ClassificationtextLeft"><b>Item Name</b></span>
							<span className="ClassificationtextRight">{data.fld_itemname}</span>
							<br />

							<span className="ClassificationtextLeft"><b>HSN</b></span>
							<span className="ClassificationtextRight">{data.fld_hsncode}</span>
							<br />
							<span className="ClassificationtextLeft"><b>Variant</b></span>
							<span className="ClassificationtextRight">
								<a onClick={() => {
									Notiflix.Loading.Dots("Please wait...");
									PostApiCall.postRequest(
										{

											whereClause: `where fld_productid=${data.fld_productid}`,
											recordCount: '*'
										},
										"GetVariantData"
									).then((results) =>
										// const objs = JSON.parse(result._bodyText)
										results.json().then((obj) => {
											if (results.status == 200 || results.status == 201) {
												console.log(obj.data)
												this.setState({
													VariantData: obj.data,
													ViewVariantList: true,
													ViewProductList: false

												});
												Notiflix.Loading.Remove();
											}
										}))

								}} style={{ color: 'green' }}>
									({data.VariantCount} Variant)
								</a>

							</span>

						</div>

					),
					classification: (
						<div className="tablealign">

							<span className="ClassificationtextLeft"><b>Vertical</b></span>
							<span className="ClassificationtextRight">{data.fld_verticlename}</span>

							<br />

							<span className="ClassificationtextLeft"><b>Category</b></span>
							<span className="ClassificationtextRight">{data.fld_categoryname}</span>

							<br />
							<span className="ClassificationtextLeft"><b>Sub Category</b></span>
							<span className="ClassificationtextRight">{data.fld_subcategoryname}</span>

						</div>
					),

					gst: (data.fld_Gstrate),
					actions: (
						<span>


							<img src={Editicon} alt="Edit user" style={{ marginLeft: '2px' }} className="btnicons" onClick={() => {
								localStorage.setItem("itemDetails", JSON.stringify(data.fld_productid));
								window.location.href = "/add_item";
							}}></img>


						</span>
					)

				}
			})
		}



		// =========VariantList=============
		const variantdata = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Variant Image",
					field: "itemimage",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Variant Name",
					field: "itemname",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Classification",
					field: "classification",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Price",
					field: "price",
					sort: "disabled",
					width: 150,
				},


				{
					label: "GST (%)",
					field: "gst",
					sort: "disabled",
					width: 150,
				},


				{
					label: "Status",
					field: "status",
					sort: "disabled",
					width: 150,
				},

				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			], // end for variant
			rows: this.state.VariantData.filter((data) => {
				if (this.state.searchInput == "") {
					return data;
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_itemname
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_verticlename ? data.fld_verticlename.toLowerCase() : ""
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_categoryname ? data.fld_categoryname.toLowerCase() : ""
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_subcategoryname
							? data.fld_subcategoryname.toLowerCase()
							: ""
							}`.includes(this.state.searchInput.toLowerCase()))
				) {
					return data;
				}
			}).map((data, i) => {
				return {
					serial: (i + 1),
					itemimage: (
						<img src={data.VariantImage} alt="Variantimage" style={{ width: '50px' }}></img>
					),
					itemname: (
						<div className="tablealign">
							<span className="ClassificationtextLeft"><b>Variant</b></span>
							<span className="ClassificationtextRight">{data.fld_variantname}</span> <br />
							<span className="ClassificationtextLeft"><b>Weight</b></span>
							<span className="ClassificationtextRight">{data.weight}</span>
							<br />
							<span className="ClassificationtextLeft"><b>Brand </b></span>
							<span className="ClassificationtextRight">{data.fld_brand_name}</span>
						</div>

					),
					classification: (
						<div className="tablealign">

							<span className="ClassificationtextLeft"><b>Vertical</b></span>
							<span className="ClassificationtextRight">{data.fld_verticlename}</span>

							<br />

							<span className="ClassificationtextLeft"><b>Category</b></span>
							<span className="ClassificationtextRight">{data.fld_categoryname}</span>

							<br />
							<span className="ClassificationtextLeft"><b>Sub Category</b></span>
							<span className="ClassificationtextRight">{data.fld_subcategoryname}</span>


						</div>
					),
					price: (
						<div className="tablealign">

							<span className="ClassificationtextLeft"><b>MRP</b></span>
							<span className="ClassificationtextRight">{'₹' + parseFloat(data.fld_mrp).toFixed(2)}</span>

							<br />

							<span className="ClassificationtextLeft"><b>Cost Price</b></span>
							<span className="ClassificationtextRight">{'₹' + parseFloat(data.fld_cost_price).toFixed(2)}</span>

							<br />
							<span className="ClassificationtextLeft"><b>Selling Price</b></span>
							<span className="ClassificationtextRight">{'₹' + parseFloat(data.fld_selling_price).toFixed(2)}</span>

						</div>
					),

					gst: (data.fld_Gstrate),

					status: (data.fld_status),
					action: (
						<span>
							<img src={Editicon} alt="View user" className="btnicons" onClick={() => {
								localStorage.setItem("VariantDetails", JSON.stringify(data.fld_variantid));
								window.location.href = "/addnewvariant";
							}}></img>
							<img src={Refreshicon} alt="Edit user" style={{ marginLeft: '2px' }} className="btnicons" onClick={() => {
								localStorage.setItem("itemDetails", JSON.stringify(data.fld_productid));
								var customer = localStorage.getItem('ClientDetails');
								var CustomerDetails = JSON.parse(customer)
								var url = CustomerDetails[0].fld_product_share_url.replace(':verticalid', data.fld_verticleid).replace(':variantid', data.fld_variantid)
								window.open(url, '_blank')
							}}></img>
						</span>
					)



				}
			})
		}




		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}

						<div className="row">
							<div className="col-md-6">

								<div>
									<ul class="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>
										<li>Product Management</li>
										<li>Product List</li>
									</ul>
								</div>
							</div>
							<div className="col-md-6">
								<img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>

							</div>


						</div>
						<Row>
							<Col xl="12">
								<Card className="pagebackground">
									<div className="Bechofy-bg-soft-pink" style={{ height: '60px', }}>
										<div className="row my-1">
											<div className="col-2">
												<div className="form-group">
													<h4 className="FilterCard" style={{ marginTop: '10px' }}>Filter Data By</h4>
												</div>
											</div>

											<div className="col-10">
												<div className="position-relative">
													{/* <button
														onClick={() => {
															localStorage.removeItem("ProductDetails");
															window.location.href = "/add_item";
														}}

														className="btn align-items-center btn Bechofy-btn AddNewBtn"
													>
														Upload Excel{" "}
														<span className=""><img src={Uploadicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

													</button> */}

													<button
														onClick={() => {
															localStorage.removeItem("VariantDetails")
															window.location.href = "/addnewvariant";
														}}

														className="btn align-items-center btn Bechofy-btn AddNewBtn"
													>
														Add New Variant{" "}
														<span className=""><img src={varianticon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

													</button>



													<button
														onClick={() => {
															localStorage.removeItem("itemDetails")
															window.location.href = '/add_item'
														}}


														className="btn align-items-center btn Bechofy-btn AddNewBtn"
													>
														Add New Item{" "}
														<span className=""><img src={addicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

													</button>


												</div>
											</div>


										</div>
									</div>


									<Card style={{ padding: ' 0 20px' }}>
										<div className="row my-1 ">
											{/* <div className="col-2">
												<div className="form-group">
													<h4 className="FilterCard" style={{ marginTop: '20px',color:'#495057' }}>Filter Data By</h4>
												</div>
											</div> */}

											<div className="col-12">
												<div className="form-group " style={{ position: 'relative' }}>

													<div class="input-group">
														<div class="input-group-prepend gridinput">
															<span class="input-group-text" id="basic-addon1">
																<span className=""><img src={Filtericon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text"
															value={this.state.SearchField}
															onChange={(text) => {
																this.setState({
																	SearchField: text.target.value
																}, () => {

																	// Notiflix.Loading.Dots("Please wait...");

																	PostApiCall.postRequest(
																		{

																			whereClause: `where upper(SearchData) LIKE isnull('${this.state.SearchField}',upper(SearchData))`,
																		},
																		"Get_SearchVariant_itemData"
																	).then((results) =>
																		// const objs = JSON.parse(result._bodyText)
																		results.json().then((obj) => {
																			if (results.status == 200 || results.status == 201) {

																				this.setState({
																					suggestions: obj.data,
																					// ViewVariantList: false,
																					// ViewProductList: true
																				});
																				Notiflix.Loading.Remove();
																			}
																		}))


																})
															}}
															class="form-control gridinput" placeholder="Enter either Item Code / Brand / Items Name / Variant Name" aria-label="Search" aria-describedby="basic-addon1" />
													</div>

													<div class="card search-result" style={{ display: this.state.SearchField == null ? 'none' : 'block', zIndex: '99' }}
														onMouseLeave={() => {
															this.setState({
																SearchField: ''
															})
														}}
													>
														{/* <div class="card-header">

          </div> */}
														<div class="card-body ">
															<ul class="list-group list-group-flush">
																{
																	suggestions.filter((data, index) => {
																		if (data.fld_variantname.toLowerCase().includes(this.state.SearchField.toLowerCase()) && this.state.SearchField !== '') {
																			return data
																		}
																	}).map((data, index) => {
																		return (
																			<li class="list-group-item">
																				<img src={data.fld_imageurl} alt='' width="30" height="30" />
																				<h5>{data.fld_variantname}</h5>
																				<h6>{data.weight}</h6>
																				<p>{data.fld_website_discount_price}</p>

																				{/* <a href="#" class="btn btn-success">Cart</a> */}
																			</li>
																		)
																	})}
															</ul>

														</div>
														<div class="card-footer text-muted">
															{/* VIEW ALL PRODUCTS  */}
														</div>
													</div>
												</div>
											</div>
											<div className="col-2">

												<div className="form-group ">
													<select className="form-control"
														placeholder="Select Brand"
														value={this.state.Brand}
														onChange={(text) => {
															this.setState({
																Brand: text.target.value
															})
														}}
													>
														<option>Select Brand</option>
														{this.state.BrandData.map((gst) => (
															<option
																key={gst.value}
																value={gst.value}
															>
																{gst.label}
															</option>
														))}

													</select>

												</div>
											</div>
											<div className="col-2">

												<div className="form-group">

													<select className="form-control"
														placeholder="Select Vertical"
														value={this.state.Vertical}
														onChange={(text) => {
															this.setState({
																Vertical: text.target.value
															},
																() => {
																	Notiflix.Loading.Dots("Please wait...");

																	PostApiCall.postRequest(
																		{
																			whereClause: `where fld_vertical_id=${this.state.Vertical}`,
																		},
																		"Get_categorydropdown"
																	).then((results1) =>
																		results1.json().then((obj1) => {
																			if (results1.status == 200 || results1.status == 201) {
																				this.setState({
																					CategoryData: obj1.data,
																				});

																				Notiflix.Loading.Remove();
																			}
																		})
																	);
																}
															)
														}}

													>
														<option value="">
															Select Vertical
														</option>
														{this.state.VerticalData.map(
															(Verticle) => (
																<option
																	key={Verticle.value}
																	value={Verticle.value}
																>
																	{Verticle.label}
																</option>
															)
														)}

													</select>
												</div>
											</div>

											<div className="col-2">

												<div className="form-group">

													<select className="form-control"
														placeholder="Select Vertical"
														value={this.state.Category}
														onChange={this.onChangeCategory.bind(
															this
														)}
														class="custom-select my-1 mr-sm-2"

													>
														<option value="">
															Select Category
														</option>
														{this.state.CategoryData.map(
															(Category) => (
																<option
																	key={Category.value}
																	value={Category.value}
																>
																	{Category.label}
																</option>
															)
														)}


													</select>
												</div>
											</div>

											<div className="col-2">

												<div className="form-group">

													<select className="form-control"
														placeholder="Select Vertical"
														value={this.state.SubCategory}
														onChange={(text) => {
															this.setState({
																SubCategory: text.target.value
															})
														}}
													>
														<option>Select Sub Category</option>
														{this.state.SubCatgeoryData.map(
															(subcat) => (
																<option
																	key={subcat.value}
																	value={subcat.value}
																>
																	{subcat.label}
																</option>
															)
														)}

													</select>
												</div>
											</div>

											<div className="col-4">
												<button
													onClick={() => {

														Notiflix.Loading.Dots("Please wait...");

														PostApiCall.postRequest(
															{

																recordCount: '*',
																whereClause: `where upper(SearchFiled) LIKE isnull('${this.state.SearchField}',upper(SearchFiled))
																AND fld_brandid = isnull(${this.state.Brand},fld_brandid)
																AND fld_verticleid = isnull(${this.state.Vertical},fld_verticleid)
																AND fld_categoryid = isnull(${this.state.Category},fld_categoryid)
																AND fld_subcategoryid = isnull(${this.state.SubCategory},fld_subcategoryid)
															`,



															},
															"GetProductMaster"
														).then((results) =>
															// const objs = JSON.parse(result._bodyText)
															results.json().then((obj) => {
																if (results.status == 200 || results.status == 201) {
																	// //console.log(obj.data)
																	this.setState({
																		ProductData: obj.data,
																		ViewVariantList: false,
																		ViewProductList: true
																	});
																	Notiflix.Loading.Remove();
																}
															}))
													}}



													className="btn align-items-center btn Bechofy-btn FilterCardBtn3"
												>
													<span className=""><img src={itemicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

													Get Item List
												</button>

												<button
													onClick={() => {

														Notiflix.Loading.Dots("Please wait...");

														PostApiCall.postRequest(
															{

																recordCount: '*',
																whereClause: `where upper(SearchFiled) LIKE isnull('${this.state.SearchField}',upper(SearchFiled))
										AND fld_brandid = isnull(${this.state.Brand},fld_brandid)
										AND fld_verticleid = isnull(${this.state.Vertical},fld_verticleid)
										AND fld_categoryid = isnull(${this.state.Category},fld_categoryid)
										AND fld_subcategoryid = isnull(${this.state.SubCategory},fld_subcategoryid)
									`,



															},
															"GetVariantData"
														).then((results) =>
															// const objs = JSON.parse(result._bodyText)
															results.json().then((obj) => {
																if (results.status == 200 || results.status == 201) {
																	// //console.log(obj.data)
																	this.setState({
																		VariantData: obj.data,
																		ViewVariantList: true,
																		ViewProductList: false
																	});
																	Notiflix.Loading.Remove();
																}
															}))
													}}

													className="btn align-items-center btn Bechofy-btn FilterCardBtn3"
												>
													<span className=""><img src={variantlist} alt="block user" className="btnicons" style={{ marginRight: '5px' }}

													></img> </span>
													Get Variant List
												</button>
												<button className="btn align-items-center btn Bechofy-btn FilterCardBtn4"

													onClick={() => {
														window.location.reload();
													}}
												>
													<span className=""><img src={Refreshicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
													Refresh
												</button>

											</div>


										</div>
									</Card>

									<Card >
										<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
											<div className="row my-1">
												<div className="col-4">
													<div className="form-group" style={{ display: this.state.ViewProductList == true ? 'block' : 'none' }}>
														<h4 className="FilterCard" style={{ marginTop: '10px' }}>Item List</h4>
													</div>
													<div className="form-group" style={{ display: this.state.ViewVariantList == true ? '' : 'none' }}>
														<h4 className="FilterCard" style={{ marginTop: '10px' }}>Variant List</h4>
													</div>
												</div>

												<div className="col-md-8" style={{ display: this.state.ViewVariantList == true ? '' : 'none' }}><button className="btn align-items-center btn Bechofy-btn AddNewBtn"
													onClick={() => {
														Notiflix.Loading.Dots("Please wait...");
														PostApiCall.postRequest(
															{

																whereClause: '',
																recordCount: 'Top 20 *'
															},
															"GetProductMaster"
														).then((results) =>
															// const objs = JSON.parse(result._bodyText)
															results.json().then((obj) => {
																if (results.status == 200 || results.status == 201) {
																	// //console.log(obj.data)
																	this.setState({
																		ProductData: obj.data,
																		ViewVariantList: false,
																		ViewProductList: true

																	});
																	Notiflix.Loading.Remove();
																}
															}))

													}}
												>
													Back to Item list
													<span className=""><img src={Backicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

												</button>
												</div>




											</div>
										</div>
										<CardBody className="pt-0">
											<Row>


												<MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
													<div class="input-group mb-3">


														<div class="input-group-prepend">
															<span class="input-group-text" id="basic-addon1">
																<span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
															onChange={(e) => this.seachBarHandler(e)}
															value={this.state.searchInput}

														/>
													</div>
												</MDBCol>

												<Col md="12">

													{this.state.ProductData.length !== 0 && this.state.ViewProductList == true ?
														<MDBDataTable
															hover
															// scrollY
															striped
															bordered
															data={itemdata}
															seachTop={false}
															entriesOptions={[25, 50, 100]}

														>

															<MDBTableHead columns={itemdata.columns} />
															<MDBTableBody rows={itemdata.rows} />
														</MDBDataTable>
														:
														<p style={{ display: this.state.ViewProductList == false ? 'none' : 'block' }}>
															No Data Found
														</p>
													}
													{this.state.VariantData.length !== 0 && this.state.ViewVariantList == true ?
														<MDBDataTable
															hover
															// scrollY
															striped
															bordered
															data={variantdata}
															seachTop={false}
															entriesOptions={[25, 50, 100]}

														>

															<MDBTableHead columns={variantdata.columns} />
															<MDBTableBody rows={variantdata.rows} />
														</MDBDataTable>
														:
														<p style={{ display: this.state.ViewVariantList == false ? 'none' : 'block' }}>
															No Data Found
														</p>
													}

												</Col>
											</Row>
										</CardBody>
									</Card>
								</Card>

								{/* <WelcomeComp /> */}
							</Col>
						</Row>
						{/* </Card>

							</Col>
						</Row> */}
					</Container>
				</div >
			</React.Fragment >
		);
	}
}
export default ProductList;
