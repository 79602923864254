import React, { Component } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from "notiflix";
// import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import varianticon from '../../assets/images/dashboardimages/variant.png'
// import {XSquare} from 'react-feather';
import GtLogo from "../../assets/images/bechofylogo.png";

var arr = [];
var arr2 = [];

const ImgUpload = ({ onChange, src }) => (
	<label htmlFor="photo-upload" className="custom-file-upload fas">
		<div className="img-wrap img-upload">
			<img
				for="photo-upload"
				src={src}
				style={{ width: "100%", height: "100%", borderRadius: "5%" }}
			/>
		</div>
		<input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
	</label>
);

class AddNewItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			enabled: false,

			CategoryName: "",
			CategoryData: [],
			SubCatgeoryData: [],
			SubCategory: "",
			AttributData: [],
			ItemName: "",
			HSNCode: "",
			GSTRate: "",
			GSTData: [],
			BrandName: "",
			BrandData: [],
			VendorName: "",
			VendorData: [],
			MarketedBy: "",

			ManufacturedBy: "",
			ManufacturedByData: [],
			Importedby: "",
			CountryOrigindata: [],
			CountryOrigin: "",
			UnitofMeasurementData: [],
			UOM: "",
			Status: "Yes",
			SKU: "",


			ItemStatus: "Active",


			FormStatus: "",

			name: "",
			VerticalName: null,
			VerticalData: [],
			MarketedByByData: []

			// BrandData: [],
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});




		if (localStorage.getItem("itemDetails") != null) {

			PostApiCall.postRequest(
				{

					whereClause: `where fld_productid=${JSON.parse(localStorage.getItem("itemDetails"))}`,
					recordCount: '*'
				},
				"GetProductMaster"
			).then((results) =>
				// const objs = JSON.parse(result._bodyText)
				results.json().then((obj) => {
					if (results.status == 200 || results.status == 201) {
						console.log(obj.data)
						this.setState({
							ProductData: obj.data,

						});
						if (obj.data.length != 0) {
							this.setState({

								CategoryName: obj.data[0].fld_categoryid,
								SubCategory: obj.data[0].fld_subcategoryid,

								ItemName: obj.data[0].fld_itemname,
								HSNCode: obj.data[0].fld_hsncode,
								GSTRate: obj.data[0].fld_Gstrate,
								BrandName: obj.data[0].fld_brandid,
								VendorName: obj.data[0].fld_vendor_id,
								MarketedBy: obj.data[0].fld_marketedbyId,
								ManufacturedBy: obj.data[0].fld_manufacturer_id,
								Status: obj.data[0].fld_imported_product,
								Importedby: obj.data[0].fld_importedbyId,
								CountryOrigin: obj.data[0].fld_origin_country_id,
								UOM: obj.data[0].fld_uom,
								ProductID: obj.data[0].fld_productid,
								VerticalName: obj.data[0].fld_verticleid,
								ItemStatus: obj.data[0].fld_status


							})

							Notiflix.Loading.Dots("Please wait...");

							PostApiCall.postRequest(
								{
									categoryid: obj.data[0].fld_categoryid,
								},
								"Get_subcategoryDropdown"
							).then((results1) =>
								results1.json().then((obj1) => {
									if (results1.status == 200 || results1.status == 201) {
										this.setState({
											SubCatgeoryData: obj1.data,
										});

										Notiflix.Loading.Remove();
									}
								})
							);

							Notiflix.Loading.Dots("Please wait...");

							PostApiCall.postRequest(
								{
									whereClause: `where fld_vertical_id=${obj.data[0].fld_verticleid}`,
								},
								"Get_categorydropdown"
							).then((results1) =>
								results1.json().then((obj1) => {
									if (results1.status == 200 || results1.status == 201) {
										this.setState({
											CategoryData: obj1.data,
										});

										Notiflix.Loading.Remove();
									}
								})
							);



						}
						Notiflix.Loading.Remove();
					}
				}))
		}




		Notiflix.Loading.Dots("Please wait...");


		PostApiCall.postRequest(
			{
				whereClause: "where fld_vendor='True'"
			},
			"Get_VendorDropdown"
		).then((results1) =>
			results1.json().then((obj1) => {
				if (results1.status == 200 || results1.status == 201) {
					this.setState({
						VendorData: obj1.data,
					});

					Notiflix.Loading.Remove();
				}
			})
		);


		PostApiCall.postRequest(
			{
				whereClause: "where fld_Manufacturer='True'"
			},
			"Get_VendorDropdown"
		).then((results1) =>
			results1.json().then((obj1) => {
				if (results1.status == 200 || results1.status == 201) {
					this.setState({
						ManufacturedByData: obj1.data,
					});

					Notiflix.Loading.Remove();
				}
			})
		);


		PostApiCall.postRequest(
			{
				whereClause: "where fld_marketer='True'"
			},
			"Get_VendorDropdown"
		).then((results1) =>
			results1.json().then((obj1) => {
				if (results1.status == 200 || results1.status == 201) {
					this.setState({
						MarketedByByData: obj1.data,
					});

					Notiflix.Loading.Remove();
				}
			})
		);


		PostApiCall.postRequest(
			{
				WhereClause: `where fld_status='Active'`,
				RecordCount: 'fld_uom as label,fld_uomid as value'
			},
			"GetUOM"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						UnitofMeasurementData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))



		Notiflix.Loading.Dots("");

		PostApiCall.postRequest(
			{
				WhereClause: `where fld_status='Active'`,
				RecordCount: 'fld_gstpercentage as label,fld_gstpercentage as value'
			},
			"GetGST"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {

					this.setState({
						GSTData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))




		GetApiCall.getRequest("GetVerticleDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					VerticalData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);



		GetApiCall.getRequest("GetBrandDropdown").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					BrandData: obj.data,
				});
				Notiflix.Loading.Remove();
			})
		);

		GetApiCall.getRequest("GetCountry").then((resultdes) =>
			resultdes.json().then((obj) => {
				this.setState({
					CountryOrigindata: obj.data,
				});
			})
		);
	}

	onChangeCategory(text) {
		this.setState(
			{
				CategoryName: text.target.value,
			},
			() => {
				Notiflix.Loading.Dots("Please wait...");

				PostApiCall.postRequest(
					{
						categoryid: this.state.CategoryName,
					},
					"Get_subcategoryDropdown"
				).then((results1) =>
					results1.json().then((obj1) => {
						if (results1.status == 200 || results1.status == 201) {
							this.setState({
								SubCatgeoryData: obj1.data,
							});

							Notiflix.Loading.Remove();
						}
					})
				);
			}
		);
	}

	onChangesubCategory(text) {
		this.setState(
			{
				SubCategory: text.target.value,
			});
	}


	onSaveData() {
		// //console.log(this.state.imageCount);
		if (this.state.CategoryName != "") {
			if (this.state.SubCategory != "") {
				if (this.state.ItemName != "") {
					if (this.state.HSNCode != "") {
						if (this.state.GSTRate != "") {
							if (this.state.VendorName != "") {
								if (this.state.ManufacturedBy != "") {
									if (this.state.BrandName != "") {
										if (this.state.MarketedBy != "") {
											if (this.state.Status == "Yes") {
												if (this.state.Importedby != "") {
													if (this.state.CountryOrigin != "") {
														if (this.state.UOM != "") {
															this.onPostItem();
														} else {
															Notiflix.Notify.Failure(
																"Please select unit of measurement"
															);
														}
													} else {
														Notiflix.Notify.Failure(
															"Please select country of origin"
														);
													}
												} else {
													Notiflix.Notify.Failure("Please select imported by");
												}
											} else {
												this.onPostItem();
											}
										} else {
											Notiflix.Notify.Failure("Please select marketed by");
										}
									} else {
										Notiflix.Notify.Failure("Please select brand name");
									}
								} else {
									Notiflix.Notify.Failure("Please select manufactured by");
								}
							} else {
								Notiflix.Notify.Failure("Please select vendor name");
							}
						} else {
							Notiflix.Notify.Failure("Please select GST rate");
						}
					} else {
						Notiflix.Notify.Failure("Please enter HSN code");
					}
				} else {
					Notiflix.Notify.Failure("Please enter item name");
				}
			} else {
				Notiflix.Notify.Failure("Please select sub category name");
			}
		} else {
			Notiflix.Notify.Failure("Please select  category name");
		}
	}




	onPostItem = () => {
		Notiflix.Loading.Dots("Please wait...");

		var login = localStorage.getItem("LoginDetail");
		var details = JSON.parse(login);

		PostApiCall.postRequest(
			{
				productid: this.state.ProductID,
				categoryname: this.state.CategoryName,
				subcategoryname: this.state.SubCategory,
				categoryid: this.state.CategoryName,
				subcategoryid: this.state.SubCategory,
				itemname: this.state.ItemName,
				hsncode: this.state.HSNCode,
				Gstrate: this.state.GSTRate,
				vendorname: this.state.VendorName,
				manufactouredby: this.state.ManufacturedBy,
				brandname: this.state.BrandName,
				marketedby: this.state.MarketedBy,
				importedProduct: this.state.Status,
				importedBy: this.state.Importedby,
				originCountryId: this.state.CountryOrigin,
				uom: this.state.UOM,
				status: this.state.ItemStatus,
				createdOn: moment().format("YYYY-MM-DD"),
				updatedOn: moment().format("YYYY-MM-DD"),
				updatedBy: details[0].fld_userid,
				formstatus: "Completed",
				verticleid: this.state.VerticalName

			},
			"AddProductMaster"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Success('Item is Successfully Added')
					window.location.reload();
				} else {
					Notiflix.Loading.Remove();
					Notiflix.Notify.Failure(obj.data)

				}
			})
		);
	};



	// onPostAttribute(obj1){
	//   //console.log('attribute')

	//   Notiflix.Loading.Dots('Please wait...');

	//   var login=localStorage.getItem('LoginDetail');
	//   var details=JSON.parse(login)
	//   var addattribute= ''
	//   for(var i=0;i<this.state.AttributeValue.length;i++){

	//   if(i == 0)
	//        {
	//         addattribute = this.state.AttributeValue[i].value
	//        }else{
	//         addattribute = addattribute + ', '+this.state.AttributeValue[i].value
	//        }

	//  }

	//  PostApiCall.postRequest({
	//     attributemappingid:this.state.AttributeMappingId,
	//     productid:this.state.ProductID,
	//     variantid:(JSON.parse(JSON.stringify(obj1.data[0]))).VariantId,
	//     attributeValue:addattribute,
	//     createdOn:moment().format("YYYY-MM-DD"),
	//     updatedOn:moment().format("YYYY-MM-DD"),
	//     updatedBy :details[0].fld_userid

	//   },"AddAttributeMasterMapping").then((resultcategory3 =>
	//   resultcategory3.json().then(obj3 => {
	//       if(resultcategory3.status == 200 || resultcategory3.status == 201){
	//         // //console.log(obj3)
	//             this.onPostUploadImage(obj1);

	//       }else
	//         {
	//           this.onPostUploadImage(obj1);
	//         }
	//   })
	//   ))

	// }



	groupBy = (objectArray, property) => {
		return objectArray.reduce((acc, obj) => {
			const key = obj[property];
			if (!acc[key]) {
				acc[key] = [];
			}
			// Add object to list for given key's value
			acc[key].push(obj);
			return acc;
		}, {});
	};

	render() {


		let temp = [];

		this.state.AttributData.map((data, i) => {
			temp.push(data.fld_attributename);
		});
		temp = [...new Set(temp)];
		let groupTemp = this.groupBy(this.state.AttributData, "fld_attributename");

		let KeysArray = Object.keys(groupTemp);
		let ValuesArray = Object.values(groupTemp);

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>


						<div className="row">
							<div className="col-md-6">
								{/* <Breadcrumbs
									title={"Bechofy / Product Management"}
									breadcrumbItem={"Add New Product"}
								/> */}
								<div>
									<ul class="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>										<li><a href="/item-management">Product List</a></li>
										<li>Add New Product</li>
									</ul>
								</div>

							</div>
							<div className="col-md-6">
								<img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>

							</div>


						</div>

						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">
									<div
										className="Bechofy-bg-soft-pink"

									>
										<Row>
											<Col xs="6">
												<div className="Bechofy-text p-3">
													<h5 className="Bechofy-text text-white">
														Add New Product
													</h5>
												</div>
											</Col>
											<Col xs="6">
												<button
													onClick={() => {
														localStorage.removeItem("VariantDetails")
														window.location.href = "/addnewvariant";
													}}

													className="btn align-items-center btn Bechofy-btn AddNewBtn"
												>
													Add New Variant{" "}
													<span className=""><img src={varianticon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

												</button>
											</Col>
										</Row>
									</div>
									<Row>
										<Col xl="12">
											<Card className="overflow-hidden">


												<CardBody className="pt-0">
													<Row>
														<Col xs="12">
															<div className="form my-4">
																<div className="row  ">
																	<div className="col col-12">
																		<div className="row">
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Vertical
																				</label>
																				<select
																					value={this.state.VerticalName}
																					onChange={(text) => {
																						this.setState({
																							VerticalName: text.target.value
																						},
																							() => {
																								Notiflix.Loading.Dots("Please wait...");

																								PostApiCall.postRequest(
																									{
																										whereClause: `where fld_vertical_id=${this.state.VerticalName}`,
																									},
																									"Get_categorydropdown"
																								).then((results1) =>
																									results1.json().then((obj1) => {
																										if (results1.status == 200 || results1.status == 201) {
																											this.setState({
																												CategoryData: obj1.data,
																											});

																											Notiflix.Loading.Remove();
																										}
																									})
																								);
																							}
																						)
																					}}
																					className="custom-select my-1 mr-sm-2"

																				>
																					<option value="">
																						Select Vertical
																					</option>
																					{this.state.VerticalData.map(
																						(Verticle) => (
																							<option
																								key={Verticle.value}
																								value={Verticle.value}
																							>
																								{Verticle.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Category
																				</label>
																				<select
																					value={this.state.CategoryName}
																					onChange={this.onChangeCategory.bind(
																						this
																					)}
																					className="custom-select my-1 mr-sm-2"

																				>
																					<option value="">
																						Select Category
																					</option>
																					{this.state.CategoryData.map(
																						(Category) => (
																							<option
																								key={Category.value}
																								value={Category.value}
																							>
																								{Category.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Sub Category
																				</label>
																				<select
																					value={this.state.SubCategory}
																					onChange={this.onChangesubCategory.bind(
																						this
																					)}
																					className="custom-select my-1 mr-sm-2"

																				>
																					<option value="">
																						Select Sub Category
																					</option>
																					{this.state.SubCatgeoryData.map(
																						(subcat) => (
																							<option
																								key={subcat.value}
																								value={subcat.value}
																							>
																								{subcat.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>

																			<div className="col col-12">
																				<div className="form-group">
																					<label for="CategoryName">
																						Item Name
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.ItemName}
																						onChange={(text) => {
																							this.setState({
																								ItemName: text.target.value,
																							});
																						}}
																						disabled={
																							this.state.FormStatus ==
																								"Completed"
																								? true
																								: false
																						}
																					/>
																				</div>
																			</div>

																			<div className="col-4">
																				<div className="form-group">
																					<label for="CategoryName">
																						HSN Code
																						<span className="mandatory">*</span>
																					</label>
																					<input
																						type="text"
																						id="CategoryName"
																						className="form-control my-1 mr-sm-2"
																						value={this.state.HSNCode}
																						onChange={(text) => {
																							this.setState({
																								HSNCode: text.target.value,
																							});
																						}}
																						disabled={
																							this.state.FormStatus ==
																								"Completed"
																								? true
																								: false
																						}
																					/>
																				</div>
																			</div>
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					GST Rate
																				</label>
																				<select
																					value={this.state.GSTRate}
																					onChange={(text) => {
																						this.setState({
																							GSTRate: text.target.value,
																						});
																					}}

																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">Select GST</option>
																					{this.state.GSTData.map((gst) => (
																						<option
																							key={gst.value}
																							value={gst.value}
																						>
																							{gst.label}
																						</option>
																					))}
																				</select>
																			</div>
																			<div className="col-4">
																				<div className="form-group">
																					<label for="CategoryName">
																						Vendor Name
																						<span className="mandatory">*</span>
																					</label>
																					<select
																						value={this.state.VendorName}
																						onChange={(text) => {
																							this.setState({
																								VendorName: text.target.value,
																							});
																						}}
																						disabled={
																							this.state.FormStatus ==
																								"Completed"
																								? true
																								: false
																						}
																						className="custom-select my-1 mr-sm-2"
																					>
																						<option value="">
																							Select Vendor{" "}
																						</option>
																						{this.state.VendorData.map(
																							(gst) => (
																								<option
																									key={gst.value}
																									value={gst.value}
																								>
																									{gst.label}
																								</option>
																							)
																						)}
																					</select>
																				</div>
																			</div>
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Manufactured By
																				</label>
																				<select
																					value={this.state.ManufacturedBy}
																					onChange={(text) => {
																						this.setState({
																							ManufacturedBy: text.target.value,
																						});
																					}}

																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Manufactured By{" "}
																					</option>
																					{this.state.ManufacturedByData.map(
																						(gst) => (
																							<option
																								key={gst.value}
																								value={gst.value}
																							>
																								{gst.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Brand Name
																				</label>
																				<select
																					value={this.state.BrandName}
																					onChange={(text) => {
																						this.setState({
																							BrandName: text.target.value,
																						});
																					}}

																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Brand{" "}
																					</option>
																					{this.state.BrandData.map((gst) => (
																						<option
																							key={gst.value}
																							value={gst.value}
																						>
																							{gst.label}
																						</option>
																					))}
																				</select>
																			</div>
																			<div className="col col-4">
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Marketed By
																				</label>
																				<select
																					value={this.state.MarketedBy}
																					onChange={(text) => {
																						this.setState({
																							MarketedBy: text.target.value,
																						});
																					}}

																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Marketed By
																					</option>
																					{this.state.MarketedByByData.map(
																						(gst) => (
																							<option
																								key={gst.value}
																								value={gst.value}
																							>
																								{gst.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>

																			<div className="col-3">
																				<div className="form-group my-1 mr-sm-2">
																					<label for="CategoryName">
																						Is This Product Imported?
																						<span className="mandatory">*</span>
																					</label>
																					<br />
																					<label class="radio-inline">
																						<input
																							type="radio"
																							name="optradio3"
																							//  disabled={!this.state.IsVisible}
																							checked={
																								this.state.Status == "Yes"
																									? true
																									: false
																							}
																							disabled={
																								this.state.FormStatus ==
																									"Completed"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									Status: "Yes",
																								});
																							}}
																						/>{" "}
																						Yes
																					</label>
																					<label
																						class="radio-inline"
																						style={{ marginLeft: "10px" }}
																					>
																						<input
																							type="radio"
																							name="optradio3"
																							disabled={
																								this.state.FormStatus ==
																									"Completed"
																									? true
																									: false
																							}
																							//  disabled={!this.state.IsVisible}
																							checked={
																								this.state.Status == "No"
																									? true
																									: false
																							}
																							onChange={() => {
																								this.setState({
																									Status: "No",
																								});
																							}}
																						/>{" "}
																						No
																					</label>
																				</div>
																			</div>

																			<div
																				className="col col-3"
																				style={{
																					display:
																						this.state.Status == "Yes"
																							? ""
																							: "none",
																				}}
																			>
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Imported By
																				</label>
																				<select
																					value={this.state.Importedby}
																					onChange={(text) => {
																						this.setState({
																							Importedby: text.target.value,
																						});
																					}}

																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Imported By{" "}
																					</option>
																					{this.state.ManufacturedByData.map(
																						(gst) => (
																							<option
																								key={gst.value}
																								value={gst.value}
																							>
																								{gst.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>

																			<div
																				className="col col-3"

																			>
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Country of Origin
																				</label>
																				<select
																					className="custom-select my-1 mr-sm-2"

																					value={this.state.CountryOrigin}
																					onChange={(text) => {
																						this.setState({
																							CountryOrigin: text.target.value,
																						});
																					}}
																				>
																					<option value="">
																						Select Country
																					</option>
																					{this.state.CountryOrigindata.map(
																						(country) => (
																							<option
																								key={country.value}
																								value={country.value}
																							>
																								{country.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>

																			<div
																				className="col col-3"

																			>
																				<label
																					className="my-1 mr-2"
																					for="inlineFormCustomSelectPref"
																				>
																					Unit of Measurement
																				</label>
																				<select
																					value={this.state.UOM}
																					onChange={(text) => {
																						this.setState({
																							UOM: text.target.value,
																						});
																					}}

																					className="custom-select my-1 mr-sm-2"
																				>
																					<option value="">
																						Select Unit of Measurement
																					</option>
																					{this.state.UnitofMeasurementData.map(
																						(unit) => (
																							<option
																								key={unit.label}
																								value={unit.label}
																							>
																								{unit.label}
																							</option>
																						)
																					)}
																				</select>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>

											<Card>
												<CardBody className="py-1 my-1">
													<Row>
														<Col xs="12">
															<div className="row">
																<div className="col-6">
																	<div className="form-group my-1 mr-sm-2">
																		<label for="CategoryName">
																			Show on Website
																			<span className="mandatory">*</span>
																		</label>
																		<br />
																		<label class="radio-inline">
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.ItemStatus == "Active"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						ItemStatus: "Active",
																					});
																				}}
																			/>{" "}
																			Yes
																		</label>
																		<label
																			class="radio-inline"
																			style={{ marginLeft: "10px" }}
																		>
																			<input
																				type="radio"
																				name="optradio"
																				//  disabled={!this.state.IsVisible}
																				checked={
																					this.state.ItemStatus == "Inactive"
																						? true
																						: false
																				}
																				onChange={() => {
																					this.setState({
																						ItemStatus: "Inactive",
																					});
																				}}
																			/>{" "}
																			No
																		</label>
																	</div>
																</div>
																<div className="col-6">
																	<button
																		style={{
																			float: "right",
																			marginTop: "5px",
																		}}
																		className="btn align-items-center Bechofy-btn "
																		onClick={this.onSaveData.bind(this)}
																	>
																		Save
																	</button>

																</div>
															</div>
														</Col>
													</Row>
												</CardBody>
											</Card>


										</Col>
									</Row>
									{/* -row */}
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default AddNewItem;
