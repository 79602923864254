import React from "react";
import { Component } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PostApiCall from "../../Api";


class ItemDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ItemDetails: [],
            OutOfStockItems: [],
            InactiveItems: [],
            ItemsWithoutImages: [],
            ItemsWithoutPrice: [],
            Loading: true
        }
    }

    componentDidMount() {
        PostApiCall.postRequest({
            whereClause: `where isnull(fld_qty,0) = 0`
        }, "GetItemStockDetails").then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    console.log(obj.data)
                    this.setState({
                        OutOfStockItems: obj.data,
                        Loading: false
                    });

                }
            }))
    }



    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>


                        <Tabs>
                            <TabList>
                                <Tab
                                    onClick={() => {
                                        this.setState({
                                            Loading: true
                                        }, () => {
                                            PostApiCall.postRequest({
                                                whereClause: `where isnull(fld_qty,0) = 0`
                                            }, "GetItemStockDetails").then((results) =>
                                                results.json().then((obj) => {
                                                    if (results.status == 200 || results.status == 201) {
                                                        //console.log(obj.data)
                                                        this.setState({
                                                            OutOfStockItems: obj.data,
                                                            Loading: false
                                                        });

                                                    }
                                                }))
                                        })

                                    }}
                                >Out of Stock Items</Tab>
                                <Tab
                                    onClick={() => {
                                        this.setState({
                                            Loading: true
                                        }, () => {
                                            PostApiCall.postRequest({
                                                whereClause: `where fld_status = 'Inactive'`
                                            }, "GetItemsDetails").then((results) =>
                                                results.json().then((obj) => {
                                                    if (results.status == 200 || results.status == 201) {
                                                        //console.log(obj.data)
                                                        this.setState({
                                                            InactiveItems: obj.data,
                                                            Loading: false
                                                        });

                                                    }
                                                }))
                                        })

                                    }}
                                >Inactive Items</Tab>
                                <Tab
                                    onClick={() => {
                                        this.setState({
                                            Loading: true
                                        }, () => {
                                            PostApiCall.postRequest({
                                                whereClause: `where not exists (select * from Product_Variant_Image pvi where pvi.fld_variantid = udv.fld_variantid)`
                                            }, "GetItemsDetails").then((results) =>
                                                results.json().then((obj) => {
                                                    if (results.status == 200 || results.status == 201) {
                                                        //console.log(obj.data)
                                                        this.setState({
                                                            ItemsWithoutImages: obj.data,
                                                            Loading: false
                                                        });

                                                    }
                                                }))
                                        })

                                    }}
                                >Items without Images</Tab>
                                <Tab
                                    onClick={() => {
                                        this.setState({
                                            Loading: true
                                        }, () => {
                                            PostApiCall.postRequest({
                                                whereClause: `where exists (select 1 from Product_Variant pv where pv.fld_mrp is null)`
                                            }, "GetItemsDetails").then((results) =>
                                                results.json().then((obj) => {
                                                    if (results.status == 200 || results.status == 201) {
                                                        // console.log(obj.data)
                                                        this.setState({
                                                            ItemsWithoutPrice: obj.data,
                                                            Loading: false
                                                        });

                                                    }
                                                }))
                                        })

                                    }}
                                >Items without Pricing</Tab>
                            </TabList>

                            <TabPanel>
                                <Row>

                                    <Col xs="12">
                                        {this.state.OutOfStockItems.length != 0 ?
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="centered" style={{ width: '10px' }}>S.No.</th>
                                                        <th className="centered" style={{ width: '300px' }}>Item Name</th>
                                                        <th className="centered" style={{ width: '300px' }}>Category</th>
                                                        <th className="centered">SubCategory</th>
                                                        <th className="centered" style={{ width: '145px' }}>Price</th>
                                                        <th className="centered" style={{ width: '160px' }}>Status</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.OutOfStockItems.map((data, i) => {
                                                        return (

                                                            <tr>
                                                                <td className="centered">{i + 1}</td>
                                                                <td className="centered">{data.fld_variantname}</td>
                                                                <td className="centered">{data.fld_categoryname}</td>
                                                                <td className="centered">{data.fld_subcategoryname}</td>

                                                                <td className="rightAlign">{parseFloat(data.fld_selling_price).toFixed(2)}</td>
                                                                <td className="centered">{data.fld_status}</td>

                                                            </tr>

                                                        )
                                                    })}
                                                </tbody>

                                            </table>
                                            :
                                            <div className="NoItemFoundInTab">
                                                {this.state.Loading == false ?
                                                    <p>Stocks Upto date</p>
                                                    :
                                                    <div class="spinner-border" role="status">
                                                        <span class="visually-hidden"></span>
                                                    </div>
                                                }

                                            </div>
                                        }
                                    </Col>

                                </Row>
                            </TabPanel>
                            <TabPanel>
                                <Row>

                                    <Col xs="12">
                                        {this.state.InactiveItems.length != 0 ?
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="centered" style={{ width: '10px' }}>S.No.</th>
                                                        <th className="centered" style={{ width: '300px' }}>Item Name</th>
                                                        <th className="centered" style={{ width: '300px' }}>Category</th>
                                                        <th className="centered">SubCategory</th>
                                                        <th className="centered" style={{ width: '145px' }}>Price</th>
                                                        <th className="centered" style={{ width: '160px' }}>Status</th>

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.InactiveItems.map((data, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="centered">{i + 1}</td>
                                                                <td className="centered">{data.fld_variantname}</td>
                                                                <td className="centered">{data.fld_categoryname}</td>
                                                                <td className="centered">{data.fld_subcategoryname}</td>

                                                                <td className="rightAlign">{parseFloat(data.fld_selling_price).toFixed(2)}</td>
                                                                <td className="centered">{data.fld_status}</td>

                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                            :
                                            <div className="NoItemFoundInTab">
                                                {this.state.Loading == false ?
                                                    <p>All the items are active on Website</p>
                                                    :
                                                    <div class="spinner-border" role="status">
                                                        <span class="visually-hidden"></span>
                                                    </div>
                                                }

                                            </div>
                                        }
                                    </Col>

                                </Row>
                            </TabPanel>
                            <TabPanel>
                                <Row>

                                    <Col xs="12">
                                        {this.state.ItemsWithoutImages.length != 0 ?
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="centered" style={{ width: '10px' }}>S.No.</th>
                                                        <th className="centered" style={{ width: '300px' }}>Item Name</th>
                                                        <th className="centered" style={{ width: '300px' }}>Category</th>
                                                        <th className="centered">SubCategory</th>
                                                        <th className="centered" style={{ width: '145px' }}>Price</th>
                                                        <th className="centered" style={{ width: '160px' }}>Status</th>

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.ItemsWithoutImages.map((data, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="centered">{i + 1}</td>
                                                                <td className="centered">{data.fld_variantname}</td>
                                                                <td className="centered">{data.fld_categoryname}</td>
                                                                <td className="centered">{data.fld_subcategoryname}</td>

                                                                <td className="rightAlign">{parseFloat(data.fld_selling_price).toFixed(2)}</td>
                                                                <td className="centered">{data.fld_status}</td>

                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                            :
                                            <div className="NoItemFoundInTab">
                                                {this.state.Loading == false ?
                                                    <p>All the items have images on Website</p>
                                                    :
                                                    <div class="spinner-border" role="status">
                                                        <span class="visually-hidden"></span>
                                                    </div>
                                                }

                                            </div>
                                        }
                                    </Col>

                                </Row>
                            </TabPanel>
                            <TabPanel>
                                <Row>

                                    <Col xs="12">
                                        {this.state.ItemsWithoutPrice.length != 0 ?
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="centered" style={{ width: '10px' }}>S.No.</th>
                                                        <th className="centered" style={{ width: '300px' }}>Item Name</th>
                                                        <th className="centered" style={{ width: '300px' }}>Category</th>
                                                        <th className="centered">SubCategory</th>
                                                        <th className="centered" style={{ width: '145px' }}>Price</th>
                                                        <th className="centered" style={{ width: '160px' }}>Status</th>

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {this.state.ItemsWithoutPrice.map((data, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="centered">{i + 1}</td>
                                                                <td className="centered">{data.fld_variantname}</td>
                                                                <td className="centered">{data.fld_categoryname}</td>
                                                                <td className="centered">{data.fld_subcategoryname}</td>

                                                                <td className="rightAlign">{parseFloat(data.fld_selling_price).toFixed(2)}</td>
                                                                <td className="centered">{data.fld_status}</td>

                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                            :
                                            <div className="NoItemFoundInTab">
                                                {this.state.Loading == false ?
                                                    <p>The price for all the items are Upto date.</p>
                                                    :
                                                    <div class="spinner-border" role="status">
                                                        <span class="visually-hidden"></span>
                                                    </div>
                                                }

                                            </div>
                                        }
                                    </Col>

                                </Row>
                            </TabPanel>
                        </Tabs>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

export default ItemDetails;