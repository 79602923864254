import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";


import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import "react-confirm-alert/src/react-confirm-alert.css";

import "react-datepicker/dist/react-datepicker.css";


import Searchicon from '../../assets/images/dashboardimages/search.png'


import Editicon from '../../assets/images/dashboardimages/pencil.png'
import addicon from '../../assets/images/dashboardimages/product.png'
import { ThirdPartyDraggable } from "@fullcalendar/interaction";



// import DatePicker from 'react-date-picker';

class PriceCalculation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MaterialData: [],
            open: false,
            view: false,
            PublishDate: "",
            Id: "",
            searchInput: "",
            ViewClick: "true",
            ProductData: [],
            VariantData: [],
            Variant: null,

            Vertical: null,
            SearchField: null,
            Coating: null,
            DesignData: [],
            CoatingData: [],
            Design: '',
            BoardData: [],
            BoardName: null,
            NumRegex: /^[0-9]*$/,
            Quantity: null,
            PriceCalculatedData: [],
            innerCoatingData: [{
                label: 'Yes', value: '1002'
            },
            {
                label: 'No', value: '0'
            }],
            innerCoating: null,
            GST: null


        };
    }

    // onCloseModal = () => {
    //     this.setState({ open: false });
    //   };

    componentDidMount() {
        Notiflix.Loading.Init({
            svgColor: "#777f80",
        });


        Notiflix.Loading.Dots("Please wait...");





        GetApiCall.getRequest("GetProductDropdown").then((resultdes) =>
            resultdes.json().then((obj) => {
                this.setState({
                    ProductData: obj.data,
                });
            })
        );
        PostApiCall.postRequest(
            {
                recordCount: 'fld_coating_name as label,fld_coating_id as value',
                whereClause: `where fld_status='Active'`,

            },
            "GetCoatingdata"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    this.setState({
                        CoatingData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))

        PostApiCall.postRequest(
            {
                recordCount: 'fld_board_name as label,fld_board_id as value',
                whereClause: `where fld_status='Active'`,

            },
            "GetBoard"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    this.setState({
                        BoardData: obj.data,

                    });
                    Notiflix.Loading.Remove();
                }
            }))




    }


    calculatePrice = () => {
        PostApiCall.postRequest({
            quantity: this.state.Quantity,
            outercoatingid: this.state.Coating,
            innercoatingid: this.state.innerCoating == 0 ? null : this.state.innerCoating,
            varientid: this.state.Variant,
            boardid: this.state.BoardName,
        }, "GetPriceV2").then((resultdes) =>
            resultdes.json().then((obj1) => {

                console.log(obj1.data)
                this.setState({
                    PriceCalculatedData: obj1.data,
                }

                );
            })
        );

    }




    seachBarHandler = (e) => {
        this.setState({ ...this.state, searchInput: e.target.value });
    };
    render() {
        const { text, suggestions } = this.state;





        // =========VariantList=============
        const pricedata = {
            columns: [
                {
                    label: "S.No.",
                    field: "serial",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Board Cost",
                    field: "BoardCost",
                    sort: "disabled",
                    width: 400,
                },
                {
                    label: "Printing Cost",
                    field: "PrintingCost",
                    sort: "disabled",
                    width: 400,
                },
                {
                    label: "Sheet Required",
                    field: "sheetrequired",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Weight per Sheet ",
                    field: "weightpersheet",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Sheet Size",
                    field: "sheetsize",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Sheet Weight",
                    field: "sheetweight",
                    sort: "disabled",
                    width: 150,
                },

                {
                    label: "Outer Coating",
                    field: "outercoating",
                    sort: "disabled",
                    width: 150,
                },

                {
                    label: "Inner Coating",
                    field: "innercoating",
                    sort: "disabled",
                    width: 150,
                },

                {
                    label: "Pasting Cost",
                    field: "pastingcost",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Die Cost",
                    field: "die_cost",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Punching cost",
                    field: "punchcost",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Packaging",
                    field: "Packaging",
                    sort: "disabled",
                    width: 150,
                },

                {
                    label: "Unit Cost",
                    field: "UnitCost",
                    sort: "disabled",
                    width: 150,
                },
                {
                    label: "Sample Cost",
                    field: "samplecost",
                    sort: "disabled",
                    width: 150,
                },

                {
                    label: "Total Cost",
                    field: "TotalCost",
                    sort: "disabled",
                    width: 150,
                },


            ], // end for variant
            rows: this.state.PriceCalculatedData.filter((data) => {
                if (this.state.searchInput == "") {
                    return data;
                }
                // if (
                //     this.state.searchInput !== "" &&
                //     (data.fld_itemname
                //         .toLowerCase()
                //         .includes(this.state.searchInput.toLowerCase()) ||
                //         `${data.fld_verticlename ? data.fld_verticlename.toLowerCase() : ""
                //             }`.includes(this.state.searchInput.toLowerCase()) ||
                //         `${data.fld_categoryname ? data.fld_categoryname.toLowerCase() : ""
                //             }`.includes(this.state.searchInput.toLowerCase()) ||
                //         `${data.fld_subcategoryname
                //             ? data.fld_subcategoryname.toLowerCase()
                //             : ""
                //             }`.includes(this.state.searchInput.toLowerCase()))
                // ) {
                //     return data;
                // }
            }).map((data, i) => {
                return {
                    serial: (i + 1),
                    BoardCost: (
                        "₹ " + parseFloat(data.board_codt).toFixed(2)
                    ),
                    PrintingCost: (
                        "₹ " + parseFloat(data.printing).toFixed(2)

                    ),
                    sheetrequired: (
                        parseFloat(data.sheetrequired).toFixed(2) + " nos"
                    ),
                    weightpersheet: (
                        +  parseFloat(data.weightpersheet).toFixed(2) + " kg"
                    ),
                    sheetsize: (
                        + parseFloat(data.sheetsize).toFixed(2) + " cms"

                    ),
                    sheetweight: (
                        +  parseFloat(data.sheetweight).toFixed(2) + " kg"
                    ),
                    outercoating: (
                        "₹ " + parseFloat(data.outercoating).toFixed(2)
                    ),
                    innercoating: (
                        "₹ " + parseFloat(data.innercoating).toFixed(2)

                    ),
                    pastingcost: (
                        "₹ " + parseFloat(data.pastingcost).toFixed(2)
                    ),
                    die_cost: (
                        "₹ " + parseFloat(data.die_cost).toFixed(2)
                    ),
                    punchcost: (
                        "₹ " + parseFloat(data.punchcost).toFixed(2)

                    ),
                    Packaging: (
                        "₹ " + parseFloat(data.packaging).toFixed(2)
                    ),
                    UnitCost: (
                        "₹ " + parseFloat(data.price_per_peace).toFixed(2)
                    ),
                    TotalCost: (
                        "₹ " + parseFloat(data.price).toFixed(2)
                    ),
                    samplecost: (
                        "₹ " + parseFloat(data.samplecost).toFixed(2)
                    )




                }
            })
        }





        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {/* Render Breadcrumb */}

                        <Breadcrumbs breadcrumbItem={"Price Calculation Master"} urlPath={"/price_calculation"} />

                        <Row>
                            <Col xl="12">
                                <Card className="pagebackground">
                                    <div className="Bechofy-bg-soft-pink" style={{ height: '60px', }}>
                                        <div className="row my-1">
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <h4 className="FilterCard" style={{ marginTop: '10px' }}>Calculate Price By</h4>
                                                </div>
                                            </div>




                                        </div>
                                    </div>


                                    <Card style={{ padding: '5px 20px' }} >
                                        <div className="row my-1 ">


                                            <div className="col-3">

                                                <div className="form-group">

                                                    <select className="form-control"
                                                        placeholder="Select Vertical"
                                                        value={this.state.Item}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Item: text.target.value
                                                            },
                                                                () => {
                                                                    Notiflix.Loading.Dots("Please wait...");

                                                                    PostApiCall.postRequest(
                                                                        {

                                                                            whereClause: `where fld_productid=${this.state.Item
                                                                                }`,
                                                                            recordCount: 'fld_variantid as value,fld_variantname as label'
                                                                        },
                                                                        "GetVariantData"
                                                                    ).then((results) =>
                                                                        // const objs = JSON.parse(result._bodyText)
                                                                        results.json().then((obj) => {
                                                                            if (results.status == 200 || results.status == 201) {
                                                                                this.setState({
                                                                                    VariantData: obj.data,
                                                                                }
                                                                                );

                                                                                Notiflix.Loading.Remove();
                                                                            }
                                                                        })
                                                                    );
                                                                }
                                                            )
                                                        }}

                                                    >
                                                        <option value="">
                                                            Select Item
                                                        </option>
                                                        {this.state.ProductData.map(
                                                            (product) => (
                                                                <option
                                                                    key={product.value}
                                                                    value={product.value}
                                                                >
                                                                    {product.label}
                                                                </option>
                                                            )
                                                        )}

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-3">

                                                <div className="form-group">

                                                    <select className="form-control"
                                                        placeholder="Select Variant"
                                                        value={this.state.Variant}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Variant: text.target.value
                                                            }, () => {
                                                                PostApiCall.postRequest(
                                                                    {

                                                                        whereClause: `where fld_variant_id=${this.state.Variant}`,
                                                                        recordCount: 'fld_design_id as value,fld_design_name as label'
                                                                    },
                                                                    "GetDesign"
                                                                ).then((results) =>
                                                                    // const objs = JSON.parse(result._bodyText)
                                                                    results.json().then((obj) => {
                                                                        if (results.status == 200 || results.status == 201) {
                                                                            this.setState({
                                                                                DesignData: obj.data,

                                                                            });
                                                                            Notiflix.Loading.Remove();
                                                                        }
                                                                    }))


                                                                Notiflix.Loading.Dots("Please wait...");

                                                                PostApiCall.postRequest(
                                                                    {

                                                                        whereClause: `where fld_variantid=${this.state.Variant
                                                                            }`,
                                                                        recordCount: 'fld_variantid,fld_Gstrate'
                                                                    },
                                                                    "GetVariantData"
                                                                ).then((results) =>
                                                                    // const objs = JSON.parse(result._bodyText)
                                                                    results.json().then((obj) => {
                                                                        if (results.status == 200 || results.status == 201) {
                                                                            this.setState({
                                                                                GST: obj.data[0].fld_Gstrate,
                                                                            });

                                                                            Notiflix.Loading.Remove();
                                                                        }
                                                                    })
                                                                );
                                                            })
                                                        }}


                                                    >
                                                        <option value="">
                                                            Select Variant
                                                        </option>
                                                        {this.state.VariantData.map(
                                                            (variant) => (
                                                                <option
                                                                    key={variant.value}
                                                                    value={variant.value}
                                                                >
                                                                    {variant.label}
                                                                </option>
                                                            )
                                                        )}


                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-3">

                                                <div className="form-group">

                                                    <select className="form-control"
                                                        placeholder="Select Variant"
                                                        value={this.state.Design}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Design: text.target.value
                                                            })
                                                        }}


                                                    >
                                                        <option value="">
                                                            Select Design
                                                        </option>
                                                        {this.state.DesignData.map(
                                                            (design) => (
                                                                <option
                                                                    key={design.value}
                                                                    value={design.value}
                                                                >
                                                                    {design.label}
                                                                </option>
                                                            )
                                                        )}


                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-3">

                                                <div className="form-group">

                                                    <select className="form-control"
                                                        placeholder="Select Variant"
                                                        value={this.state.Coating}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                Coating: text.target.value
                                                            })
                                                        }}


                                                    >
                                                        <option value="">
                                                            Select Outer Coating
                                                        </option>
                                                        {this.state.CoatingData.map(
                                                            (coating) => (
                                                                <option
                                                                    key={coating.value}
                                                                    value={coating.value}
                                                                >
                                                                    {coating.label}
                                                                </option>
                                                            )
                                                        )}


                                                    </select>
                                                </div>
                                            </div>


                                            <div className="col-3">

                                                <div className="form-group">

                                                    <select className="form-control"
                                                        placeholder="Select Variant"
                                                        value={this.state.innerCoating}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                innerCoating: text.target.value
                                                            })
                                                        }}


                                                    >
                                                        <option value="">
                                                            Select Inner Coating
                                                        </option>
                                                        {this.state.innerCoatingData.map(
                                                            (innercoating) => (
                                                                <option
                                                                    key={innercoating.value}
                                                                    value={innercoating.value}
                                                                >{innercoating.label}
                                                                </option>
                                                            )
                                                        )}


                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-3">

                                                <div className="form-group">

                                                    <select className="form-control"
                                                        placeholder="Select Variant"
                                                        value={this.state.BoardName}
                                                        onChange={(text) => {
                                                            this.setState({
                                                                BoardName: text.target.value
                                                            })
                                                        }}


                                                    >
                                                        <option value="">
                                                            Select Board
                                                        </option>
                                                        {this.state.BoardData.map(
                                                            (board) => (
                                                                <option
                                                                    key={board.value}
                                                                    value={board.value}
                                                                >
                                                                    {board.label}
                                                                </option>
                                                            )
                                                        )}


                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-3">

                                                <div className="form-group">
                                                    <input className="form-control" value={this.state.Quantity} placeholder="Enter Quantity"
                                                        onChange={(text) => {
                                                            if (this.state.NumRegex.test(text.target.value)) {

                                                                this.setState({
                                                                    Quantity: text.target.value
                                                                })
                                                            }
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="position-relative">


                                                    <button
                                                        onClick={() => {
                                                            if (this.state.Item != null) {
                                                                if (this.state.Variant != null) {
                                                                    if (this.state.Design != null) {
                                                                        if (this.state.Coating != null) {
                                                                            if (this.state.innerCoating != null) {
                                                                                if (this.state.BoardName != null) {
                                                                                    if (this.state.Quantity != null) {
                                                                                        this.calculatePrice()

                                                                                    }
                                                                                    else {
                                                                                        Notiflix.Notify.Failure("Please enter the quantity");
                                                                                    }


                                                                                }
                                                                                else {
                                                                                    Notiflix.Notify.Failure("Please select the board name");
                                                                                }

                                                                            }
                                                                            else {
                                                                                Notiflix.Notify.Failure("Please select the inner coating");
                                                                            }
                                                                        }
                                                                        else {
                                                                            Notiflix.Notify.Failure("Please select the outer coating");
                                                                        }
                                                                    }
                                                                    else {
                                                                        Notiflix.Notify.Failure("Please select the design");
                                                                    }
                                                                } else {
                                                                    Notiflix.Notify.Failure("Please select the variant");
                                                                }
                                                            } else {
                                                                Notiflix.Notify.Failure("Please select the item");
                                                            }
                                                        }}

                                                        className="btn align-items-center btn Bechofy-btn AddNewBtn"
                                                    >
                                                        Calculate Price{" "}

                                                    </button>




                                                </div>
                                            </div>






                                        </div>
                                    </Card>

                                    <Card >
                                        <div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
                                            <div className="row my-1">
                                                <div className="col-4">
                                                    <div className="form-group" >
                                                        <h4 className="FilterCard" style={{ marginTop: '10px' }}>Price List</h4>
                                                    </div>

                                                </div>






                                            </div>
                                        </div>
                                        <CardBody className="pt-0">
                                            <Row>



                                                <Col md="12 mt-2" >

                                                    {this.state.PriceCalculatedData.length !== 0 ?
                                                        <MDBDataTable
                                                            hover
                                                            // scrollY
                                                            striped
                                                            bordered
                                                            data={pricedata}
                                                            seachTop={false}
                                                            entriesOptions={[25, 50, 100]}

                                                        >

                                                            <MDBTableHead columns={pricedata.columns} />
                                                            <MDBTableBody rows={pricedata.rows} />
                                                        </MDBDataTable>
                                                        :
                                                        <p>
                                                            No Data Found
                                                        </p>
                                                    }


                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Card>

                                {/* <WelcomeComp /> */}
                            </Col>
                        </Row>
                        {/* </Card>

							</Col>
						</Row> */}
                    </Container>
                </div >
            </React.Fragment >
        );
    }
}
export default PriceCalculation;
