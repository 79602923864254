import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";

class AddCP extends Component {
  constructor(props) {
    super(props);
    const { action } = props.match.params;
    this.state = {
      Status: "Yes",
      CoatingName: null,
      BasePrice: null,

      AdditonalCost: null,
      DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
      Action: action,
      VariantData: [],
      CoatingPriceID: null,
      Sheet: null,
      VariantName: null,
      NoofUps: null,
      DieCost: null,
      PastingCost: null,
      SampleCost: null,
      MVPId: null,
      SheetData: [],
      ProductName: null,
      ProductData: [],
      PrintingBasePrice: null,
      PrintingNoOfSheets: null,
      PrintingAdditionalCostPerSheet: null,
      PunchingBasePrice: null,
      PunchingNoOfSheets: null,
      PunchingAdditionalCostPerSheet: null,
      ItemName: "",
      Sheetsize: "",
      VariantName: "",
    };
  }

  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: "#777f80",
    });

    Notiflix.Loading.Dots("Please wait...");

    GetApiCall.getRequest("GetProductDropdown").then((resultdes) =>
      resultdes.json().then((obj) => {
        this.setState({
          ProductData: obj.data,
        });
      })
    );

    PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "fld_sheet_size as label,fld_sheet_id as value",
      },
      "GetPrintingSheet"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          console.log(obj.data);
          this.setState({
            SheetData: obj.data,
          });
          Notiflix.Loading.Remove();
        }
      })
    );

    var det = localStorage.getItem("VPMDetails");
    var VPMData = JSON.parse(det);

    if (VPMData != null) {
      console.log(VPMData);
      if (this.state.Action == "update") {
        console.log(VPMData);
        this.setState(
          {
            Sheet: VPMData.fld_sheet_size_id,
            VariantName: VPMData.fld_variant_id,
            NoofUps: VPMData.fld_no_of_ups,
            DieCost: VPMData.fld_die_cost,
            PastingCost: VPMData.fld_pasting_cost,
            SampleCost: VPMData.fld_sample_cost,
            MVPId: VPMData.fld_feeding_id,
            GSM: VPMData.fld_gsm,
            Status: VPMData.fld_status == "Active" ? "Yes" : "No",
            ProductName: VPMData.productid,
          },
          () => {
            Notiflix.Loading.Dots("Please wait...");

            PostApiCall.postRequest(
              {
                whereClause: `where fld_productid=${this.state.ProductName}`,
                recordCount: "fld_variantid as value,fld_variantname as label",
              },
              "GetVariantData"
            ).then((results) =>
              results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  console.log(obj.data);
                  this.setState({
                    VariantData: obj.data,
                  });
                  Notiflix.Loading.Remove();
                }
              })
            );
          }
        );
      }
    }
  }

  // ==Image Upload onChange Function=======

  onSaveData() {
    // console.log(this.state.VariantName)
    if (this.state.VariantName != null) {
      if (this.state.Sheet != null) {
        if (this.state.NoofUps != null) {
          if (this.state.GSM != null) {
            if (this.state.DieCost != null) {
              if (this.state.PastingCost != null) {
                if (this.state.SampleCost != null) {
                  this.onPost();
                } else {
                  Notiflix.Notify.Failure("Please enter sample cost");
                }
              } else {
                Notiflix.Notify.Failure("Please enter pasting cost");
              }
            } else {
              Notiflix.Notify.Failure("Please enter die cost");
            }
          } else {
            Notiflix.Notify.Failure("Please enter GSM");
          }
        } else {
          Notiflix.Notify.Failure("Please enter the no of Ups");
        }
      } else {
        Notiflix.Notify.Failure("Please enter the sheet size");
      }
    } else {
      Notiflix.Notify.Failure("Please select variant name");
    }
  }

  onPost = () => {
    Notiflix.Loading.Dots("Please wait...");
    PostApiCall.postRequest(
      {
        feedingid: this.state.MVPId,
        variantid: this.state.VariantName,
        sheetsizeid: this.state.Sheet,

        status: this.state.Status == "Yes" ? "Active" : "Inactive",
        noofups: this.state.NoofUps,
        gsm: this.state.GSM,
        diecost: this.state.DieCost,
        boxsize: null,
        pastingcost: this.state.PastingCost,
        samplecost: this.state.SampleCost,
        productItemName: this.state.ItemName,
        printingbaseprice: this.state.PrintingBasePrice,
        printingnoofsheets: this.state.PrintingNoOfSheets,
        printingadditionalcostpersheet:
          this.state.PrintingAdditionalCostPerSheet,
        punchingbaseprice: this.state.PunchingBasePrice,
        punchingnoofsheets: this.state.PunchingNoOfSheets,
        punchingadditionalcostpersheet:
          this.state.PunchingAdditionalCostPerSheet,
      },
      "ProductVariantPriceMapping"
    ).then((resultcategory) =>
      resultcategory.json().then((obj) => {
        if (resultcategory.status == 200 || resultcategory.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Coating Price successfully added.");
          //window.location.href = "/price_calculation_header";
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  };

  render() {
    console.log(this.state.VariantName);
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbItem={"Map Product Variant Price Master"}
              breadcrumbItem1={
                this.state.MVPId == null
                  ? "Map New Variant Price"
                  : "Update Variant Price"
              }
              urlPath={"/product_variant_price_mapping"}
              urlPath1={
                this.state.MVPId == null
                  ? "/add_product_variant_price/create"
                  : "/add_product_variant_price/update"
              }
            />
            <Row>
              <Col xl="12">
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col xs="3">
                        <div className="Bechofy-text p-3">
                          {this.state.MVPId == null ? (
                            <h5 className="Bechofy-text">
                              Map New Variant Price
                            </h5>
                          ) : (
                            <h5 className="Bechofy-text">
                              Update Variant Price
                            </h5>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col xs="12">
                        <div className="form my-4">
                          <div className="row  ">
                            <div className="col col-12">
                              <div className="row">
                                <div className="col col-3">
                                  <label
                                    className="my-1 mr-2"
                                    for="inlineFormCustomSelectPref"
                                  >
                                    Item Name
                                    <span className="mandatory">*</span>
                                  </label>
                                  <select
                                    value={this.state.ProductName}
                                    onChange={(text) => {
                                      this.setState(
                                        {
                                          ProductName: text.target.value,
                                        },
                                        () => {
                                          Notiflix.Loading.Dots(
                                            "Please wait..."
                                          );

                                          PostApiCall.postRequest(
                                            {
                                              whereClause: `where fld_productid=${this.state.ProductName}`,
                                              recordCount:
                                                "fld_variantid as value,fld_variantname as label",
                                            },
                                            "GetVariantData"
                                          ).then((results) =>
                                            results.json().then((obj) => {
                                              if (
                                                results.status == 200 ||
                                                results.status == 201
                                              ) {
                                                console.log(obj.data);
                                                this.setState({
                                                  VariantData: obj.data,
                                                  VariantName:
                                                    obj.data[0].value,
                                                  ItemName: obj.data[0].label,
                                                });
                                                Notiflix.Loading.Remove();
                                              }
                                            })
                                          );
                                        }
                                      );
                                    }}
                                    className="custom-select my-1 mr-sm-2"
                                  >
                                    <option value="">Select Item Name</option>
                                    {this.state.ProductData.map((product) => (
                                      <option
                                        key={product.value}
                                        value={product.value}
                                        onChange={(e) => {
                                          this.setState({
                                            ItemName: this.state.value,
                                          });
                                        }}
                                      >
                                        {product.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="SheetSize">
                                      Variant Name
                                      <span className="mandatory">*</span>
                                    </label>
                                    <select
                                      className="form-control"
                                      value={this.state.VariantName}
                                      onChange={(text) => {
                                        this.setState({
                                          VariantName: text.target.value,
                                        });
                                      }}
                                    >
                                      <option value="">
                                        Select Variant Name
                                      </option>
                                      {this.state.VariantData.map((data) => (
                                        <option
                                          key={data.value}
                                          value={data.value}
                                          onChange={(e) => {
                                            this.setState({
                                              VariantName: e.target.value,
                                            });
                                          }}
                                        //   selected={
                                        //     this.state.VariantName == data.value
                                        //   }
                                        >
                                          {data.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="SheetSize">
                                      Sheet Size
                                      <span className="mandatory">*</span>
                                    </label>
                                    <select
                                      className="form-control"
                                      value={this.state.Sheet}
                                      onChange={(text) => {
                                        this.setState({
                                          Sheet: text.target.value,
                                        });
                                      }}
                                    >
                                      <option>Select</option>
                                      {this.state.SheetData.map((data) => (
                                        <option
                                          key={data.value}
                                          value={data.value}
                                          onChange={(e) => {
                                            this.setState({
                                              Sheetsize: e.target.value,
                                            });
                                          }}
                                        >
                                          {data.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="BasePrice">
                                      No. of Ups
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="BasePrice"
                                      className="form-control"
                                      value={this.state.NoofUps}
                                      onChange={(text) => {
                                        if (
                                          this.state.DecimalRegex.test(
                                            text.target.value
                                          )
                                        ) {
                                          this.setState({
                                            NoofUps: text.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="Sheet">
                                      GSM
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="Sheet"
                                      className="form-control"
                                      value={this.state.GSM}
                                      onChange={(text) => {
                                        this.setState({
                                          GSM: text.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="AdditonalCost">
                                      Die Cost
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="AdditonalCost"
                                      className="form-control"
                                      value={this.state.DieCost}
                                      onChange={(text) => {
                                        if (
                                          this.state.DecimalRegex.test(
                                            text.target.value
                                          )
                                        ) {
                                          this.setState({
                                            DieCost: text.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="AdditonalCost">
                                      Pasting Cost
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="AdditonalCost"
                                      className="form-control"
                                      value={this.state.PastingCost}
                                      onChange={(text) => {
                                        if (
                                          this.state.DecimalRegex.test(
                                            text.target.value
                                          )
                                        ) {
                                          this.setState({
                                            PastingCost: text.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="AdditonalCost">
                                      Sample Cost
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="AdditonalCost"
                                      className="form-control"
                                      value={this.state.SampleCost}
                                      onChange={(text) => {
                                        if (
                                          this.state.DecimalRegex.test(
                                            text.target.value
                                          )
                                        ) {
                                          this.setState({
                                            SampleCost: text.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="Sheet">
                                      Printing
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="Sheet"
                                      disabled="true"
                                      className="form-control"
                                      value="Printing"
                                    />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="AdditonalCost">
                                      Printing Base Price
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="AdditonalCost"
                                      className="form-control"
                                      value={this.state.PrintingBasePrice}
                                      onChange={(text) => {
                                        if (
                                          this.state.DecimalRegex.test(
                                            text.target.value
                                          )
                                        ) {
                                          this.setState({
                                            PrintingBasePrice:
                                              text.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="AdditonalCost">
                                      Printing No Of Sheets
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="AdditonalCost"
                                      className="form-control"
                                      value={this.state.PrintingNoOfSheets}
                                      onChange={(text) => {
                                        if (
                                          this.state.DecimalRegex.test(
                                            text.target.value
                                          )
                                        ) {
                                          this.setState({
                                            PrintingNoOfSheets:
                                              text.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="AdditonalCost">
                                      Printing Additional Cost Per Sheet
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="AdditonalCost"
                                      className="form-control"
                                      value={
                                        this.state
                                          .PrintingAdditionalCostPerSheet
                                      }
                                      onChange={(text) => {
                                        if (
                                          this.state.DecimalRegex.test(
                                            text.target.value
                                          )
                                        ) {
                                          this.setState({
                                            PrintingAdditionalCostPerSheet:
                                              text.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="Sheet">
                                      Punching
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="Sheet"
                                      disabled="true"
                                      className="form-control"
                                      value="Punching"
                                    />
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="AdditonalCost">
                                      Punching Base Price
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="AdditonalCost"
                                      className="form-control"
                                      value={this.state.PunchingBasePrice}
                                      onChange={(text) => {
                                        if (
                                          this.state.DecimalRegex.test(
                                            text.target.value
                                          )
                                        ) {
                                          this.setState({
                                            PunchingBasePrice:
                                              text.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="AdditonalCost">
                                      Punching No Of Sheets
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="AdditonalCost"
                                      className="form-control"
                                      value={this.state.PunchingNoOfSheets}
                                      onChange={(text) => {
                                        if (
                                          this.state.DecimalRegex.test(
                                            text.target.value
                                          )
                                        ) {
                                          this.setState({
                                            PunchingNoOfSheets:
                                              text.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="AdditonalCost">
                                      Punching Additional Cost Per Sheet
                                      <span className="mandatory">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="AdditonalCost"
                                      className="form-control"
                                      value={
                                        this.state
                                          .PunchingAdditionalCostPerSheet
                                      }
                                      onChange={(text) => {
                                        if (
                                          this.state.DecimalRegex.test(
                                            text.target.value
                                          )
                                        ) {
                                          this.setState({
                                            PunchingAdditionalCostPerSheet:
                                              text.target.value,
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="form-group">
                                    <label for="BoardName">
                                      Status
                                      <span className="mandatory">*</span>
                                    </label>
                                    <br />
                                    <label class="radio-inline">
                                      <input
                                        type="radio"
                                        name="optradio"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Status == "Yes"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Status: "Yes",
                                          });
                                        }}
                                      />{" "}
                                      Yes
                                    </label>
                                    <label
                                      class="radio-inline"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <input
                                        type="radio"
                                        name="optradio"
                                        //  disabled={!this.state.IsVisible}
                                        checked={
                                          this.state.Status == "No"
                                            ? true
                                            : false
                                        }
                                        onChange={() => {
                                          this.setState({
                                            Status: "No",
                                          });
                                        }}
                                      />{" "}
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody className="py-1 my-1">
                    <Row>
                      <Col xs="12">
                        <div className="row">
                          <div className="col-3 offset-9">
                            <button
                              style={{ float: "right" }}
                              className="btn align-items-center Bechofy-btn "
                              onClick={this.onSaveData.bind(this)}
                            >
                              {this.state.MVPId == null ? (
                                <span>Save Variant Price</span>
                              ) : (
                                <span>Update Variant Price</span>
                              )}
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  {/*                
                </Card>
       
                              
                               
                               
                            </Col>
                               </Row> */}
                  {/* </Card> */}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default AddCP;
