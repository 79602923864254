import React, { Component } from "react";

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser, apiError } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/bechofylogo.png";
import logox from "../../assets/images/bechofylogo.png";

import Notiflix from "notiflix"; 
import PostApiCall from "../../AuthenticateUserAPI";
import ManageCustomerAPI from "../../ManageCustomerAPI";
// import GetApiCall from '../../GetApi';
import moment from "moment";
import Bechofylogo from '../../assets/images/bechofylogo.png'
import queryString from 'query-string';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPasswordVisible: false,
			DecimalRegex: /^(\d*\.?\d{0,2}|\.\d{0,9})$/,
			NumRegex: /^[0-9]*$/,
			AlphaNumericRegex: /^[a-zA-Z0-9]*$/,
			EmailRegex:
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			UrlRegex:
				/^(https:\/\/www\.|httpss:\/\/www\.|https:\/\/|httpss:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,

			Email: "",
			Password: "",
			history: useHistory,
			clientId: null,
			clientLogo: null,
			clientName: null,
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});
		const parsed = queryString.parse(this.props.location.search);

		this.setState({
			clientId: parsed.client
		})

		// get clinet data


		ManageCustomerAPI.postRequest(
			{
				whereClause: `where fld_company_id = ${parsed.client}`,
			},
			"API/GetClientData"
		).then((resultcategory) =>
			resultcategory.json().then((obj) => {
				if (resultcategory.status == 200 || resultcategory.status == 201) {
					this.setState({
						clientLogo: obj.data[0].fld_storelogo,
						clientName: obj.data[0].fld_companyname
					})
					localStorage.setItem("ClientDetails", JSON.stringify(obj.data));
				}
			})
		);

		// end get clinet data

		// var login = localStorage.getItem("LoginDetail");
		// var details = JSON.parse(login);
		// if (details != null) {
		// 	this.props.history.push("/dashboard");
		// }
	}

	onClickLogin() {
		if (localStorage.getItem("ClientDetails") == null) {

			Notiflix.Notify.Failure("You are not authorized to access the page. Please access the page from main business domain.")

		} else {

			if (this.state.Email != "") {
				if (this.state.EmailRegex.test(this.state.Email)) {
					if (this.state.Password != "") {
						// window.location.href="/dashboard"
						Notiflix.Loading.Dots("");

						PostApiCall.postRequest(
							{
								email: this.state.Email,
								password: this.state.Password,
								action: "Login",
								actiondate: moment().format("lll"),
								// db_name:'greensworthnuts_db',
								// db_password:'hyBu122*',
								// datab_name:'greensworthnuts_db',

							},
							JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_apiaddress + "AuthenticateUser"
						).then((results) =>
							// const objs = JSON.parse(result._bodyText)
							results.json().then((obj) => {
								if (results.status == 200 || results.status == 201) {
									console.log(obj)

									localStorage.setItem(
										"lastlogin",
										JSON.stringify(moment().format("lll"))
									);
									console.log(obj)
									localStorage.setItem("access", JSON.stringify(obj.token));
									localStorage.setItem("LoginDetail", JSON.stringify(obj.data));

									Notiflix.Loading.Remove();
									window.location.href = "/dashboard";
								} else {
									Notiflix.Loading.Remove();
									Notiflix.Notify.Failure(obj.data);
								}
							})
						);
					} else {
						Notiflix.Notify.Failure("Please enter password.");
					}
				} else {
					Notiflix.Notify.Failure("Please enter valid email address.");
				}
			} else {
				Notiflix.Notify.Failure("Please enter email address.");
			}

		}
	}

	OnChangepwd(password) {
		this.setState({
			Password: password.target.value,
		});
	}

	render() {
		return (
			<React.Fragment>
				{/* <div className="home-btn d-none d-sm-block">
					<Link to="/" className="text-dark">
						<i className="bx bx-home h2"></i>
					</Link>
				</div> */}
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="Bechofy-bg-soft-pink">
										<Row>
											<Col className="col-7">
												<div className="Bechofy-muted-text p-4"
													style={{ color: '#fff' }}>
													<h5 className="Bechofy-muted-text" style={{ color: '#fff' }}>
														Welcome {this.state.clientName} !
													</h5>
													<p>Sign in to continue to Bechofy.</p>
												</div>
											</Col>
											<Col className="col-5 align-self-end">
												<img src={profile} alt="" className="img-fluid" />
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<div className="row">
											<div className="col-md-6">
												<Link to="/">
													<div className="avatar-md profile-user-wid mb-4">
														<span className="avatar-title rounded-circle bg-light">
															<img
																src={Bechofylogo}
																alt=""
																className="rounded-circle"
																height="34"
															/>
														</span>
													</div>
												</Link>
											</div>
											<div className="col-md-6">
												<Link to="/">
													<div
														className="avatar-md profile-user-wid mb-4 d-none d-lg-block"
														style={{
															position: "absolute",
															// right: "45px",
															top: "30px",
														}}
													>
														<img src={`data:image/jpeg;base64,${this.state.clientLogo}`} alt="logo" height="90px" width="205px" />
													</div>
												</Link>
											</div>
										</div>
										<div className="p-2">
											<AvForm
												className="form-horizontal"
												onValidSubmit={this.handleValidSubmit}
											>
												<div className="form-group">
													<div className="form-group">
														<label for="email">Email</label>
														<input
															value={this.state.Email}
															onChange={(text) => {
																this.setState({
																	Email: text.target.value,
																});
															}}
															type="email"
															className="form-control"
														/>
													</div>
													<div className="form-group">
														<label for="pwd">Password:</label>
														<input
															value={this.state.Password}
															type={
																this.state.isPasswordVisible
																	? "text"
																	: "password"
															}
															onChange={this.OnChangepwd.bind(this)}
															className="form-control"
														/>
														<span class="login-icon-change-pass">
															{/* <i class="icon-dual" data-feather="lock"></i>*/}
															<i
																style={{
																	color: this.state.isPasswordVisible
																		? "#777f80"
																		: "",
																}}
																className="fa fa-eye"
																onClick={() => {
																	this.setState({
																		isPasswordVisible:
																			!this.state.isPasswordVisible,
																	});
																}}
															/>
														</span>
													</div>
													{/* <div className="form-group form-check">
                                                    <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox"/> Remember me
                                                    </label>
                                                </div>
                                                <div className="mt-3">
                                                    <button className="btn Bechofy-btn btn-block waves-effect waves-light" type="submit">Log In</button>
                                                </div> */}
												</div>

												{/* <div className="form-group">
                                                    <AvField name="password" label="Password" value="123456" type="password" validate={{
            required: {value: true, errorMessage: 'Please enter a password'},
            pattern: {value: '^[A-Za-z0-9]+$', errorMessage: 'Your password must be composed only with letter and numbers'},
            minLength: {value: 6, errorMessage: 'Your password must be extact 6 characters'},
            maxLength: {value: 6, errorMessage: 'Your password must be extact 6 characters'}
          }} placeholder="Enter Password" />
                                                </div> */}

												{/* <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                    <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                                </div> */}

												<div className="mt-3">
													<button
														className="btn Bechofy-btn btn-block waves-effect waves-light"
														type="submit"
														onClick={this.onClickLogin.bind(this)}
													>
														Log In
													</button>
												</div>

												{/* <div className="mt-4 text-center">
                                                    <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                </div> */}
											</AvForm>
										</div>
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									{/* <p>Don't have an account ? <Link to="register" className="font-weight-medium Bechofy-text"> Signup now </Link> </p> */}
									<p>
										© {new Date().getFullYear()}  Bechofy.
										{/* <i className="mdi mdi-heart text-danger"></i> by
										<Link to='#' style={{ color: '#777f80' }}>
											<img src={Bechofylogo} alt="" style={{ width: '50px' }} /> <span>A product of Global Trendz.</span></Link> */}

									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

const mapStatetoProps = (state) => {
	const { error } = state.Login;
	return { error };
};

export default withRouter(
	connect(mapStatetoProps, { loginUser, apiError })(Login)
);
