import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment"
import Filtericon from '../../assets/images/dashboardimages/filter.png'
import Searchicon from '../../assets/images/dashboardimages/search.png'
import Downloadicon from '../../assets/images/dashboardimages/down-arrow.png'
import Calendericon from '../../assets/images/dashboardimages/calendar.png'
import Refreshicon from '../../assets/images/dashboardimages/refresh.png'
import { CSVLink } from "react-csv";
import Breadcrumbs from "../../components/Common/Breadcrumb";

// import DatePicker from 'react-date-picker';

class customerList extends Component {
	constructor(props) {
		super(props);
		this.csvLink = React.createRef()
		this.state = {
			open: false,
			UserData: [],
			Id: "",
			searchInput: "",
			FromDate: null,
			ToDate: null,
			OrderData: [],
			CustomerData: [],
			SearchField: null,
			fileData: [],
			ListAllCustomerData: "NotClicked"
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});


		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{

				WhereClause: '',
				RecordCount: 'Top 20 *'
			},
			"GetCustomerList"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					console.log(obj.data)
					this.setState({
						CustomerData: obj.data,

					});
					Notiflix.Loading.Remove();
				}
			}))


	}


	onPost = () => {

		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{

				RecordCount: '*',
				WhereClause: `where cast (fld_created_on as date) BETWEEN cast('${moment(this.state.FromDate == null ? null : this.state.FromDate).format('MM-DD-YYYY')}' as date)
    AND cast('${moment(this.state.ToDate == null ? null : this.state.ToDate).format('MM-DD-YYYY')}' as date)
    `,
			},
			"GetCustomerList"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					console.log(obj.data)
					this.setState({
						CustomerData: obj.data,
						ListAllCustomerData: "Clicked"
					});
					Notiflix.Loading.Remove();
				}
			}))
	}


	DownloadCSV = () => {

		Notiflix.Loading.Dots("Please wait...");
		PostApiCall.postRequest(
			{

				WhereClause: '',
				RecordCount: '*'
			},
			"GetCustomerList"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					if (obj.data.length != 0) {
						// this.props.setstate()

						this.setState({

							fileData: obj.data,

						}, () => {
							setTimeout(() => {
								this.csvLink.current.link.click();
							});
						});
					}
					Notiflix.Loading.Remove();
				}
			}))

	}

	onPostSearch() {
		Notiflix.Loading.Dots("Please wait...");

		PostApiCall.postRequest(
			{

				RecordCount: '*',
				WhereClause: `where upper(SearchFiled) LIKE isnull('%${this.state.SearchField == null ? "" : this.state.SearchField}%',upper(SearchFiled))
				`,
			},
			"GetCustomerList"
		).then((results) =>
			// const objs = JSON.parse(result._bodyText)
			results.json().then((obj) => {
				if (results.status == 200 || results.status == 201) {
					console.log(obj.data)
					this.setState({
						CustomerData: obj.data,
						ListAllCustomerData: "Clicked"

					});
					Notiflix.Loading.Remove();
				}
			}))
	}




	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 75,
				},
				{
					label: "Customer Name",
					field: "name",
					sort: "disabled",
					width: 600,
				},

				{
					label: "Email Address",
					field: "email",
					sort: "disabled",
					width: 600,
				},

				{
					label: "Contact No.",
					field: "contact",
					sort: "disabled",
					width: 300,
				},

				{
					label: "State",
					field: "state",
					sort: "disabled",
					width: 300,
				},
				{
					label: "City",
					field: "city",
					sort: "disabled",
					width: 300,
				},
				{
					label: "Registered On",
					field: "registeredOn",
					sort: "disabled",
					width: 200,
				},
				{
					label: "No. of Orders",
					field: "orderno",
					sort: "disabled",
					width: 100,
				},
				{
					label: "Source",
					field: "source",
					sort: "disabled",
					width: 100,
				},

				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.CustomerData.filter((data) => {
				if (this.state.searchInput == "" && this.state.ListAllCustomerData == "Clicked") {
					return data
				}
				if (
					this.state.searchInput !== "" &&
					(data.fld_name
						.toLowerCase()
						.includes(this.state.searchInput.toLowerCase()) ||
						// `${data.fld_email ? data.fld_email.toLowerCase() : ""
						// 	}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_phone_number ? data.fld_phone_number.toLowerCase() : ""
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_email_address ? data.fld_email_address.toLowerCase() : ""
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.CityName != null
							? data.CityName.toLowerCase()
							: null
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.FLD_Door_no != null
							? data.FLD_Door_no.toLowerCase()
							: null
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_landmark != null
							? data.fld_landmark.toLowerCase()
							: null
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.StateName != null
							? data.StateName.toLowerCase()
							: null
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_pincode != null
							? data.fld_pincode.toLowerCase()
							: null
							}`.includes(this.state.searchInput.toLowerCase()) ||
						`${data.fld_status
							? data.fld_status.toLowerCase()
							: ""
							}`.includes(this.state.searchInput.toLowerCase())

					)
				) {
					return data;
				}
			}).map((data, i) => {
				return {
					serial: (i + 1),
					name: (data.fld_name),
					email: (data.fld_email_address),
					contact: (data.fld_phone_number),
					state: (data.StateName),
					city: (data.CityName),
					registeredOn: (moment(data.fld_created_on).format("DD/MM/YYYY")),
					orderno: (data.total_Orders),
					source: (data.fld_status),
					action:
						<span className="">
							<a className="btn align-items-center btn Bechofy-btn " onClick={() => {
								localStorage.setItem("customerDetails", JSON.stringify(data.fld_customerid));
								window.location.href = "/customer_profile";
							}}>View Profile</a>
						</span>
				}
			})
		}



		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs breadcrumbItem={"Customer Management"} urlPath={"/customer-management"} />
						<Row>
							<Col xl="12">
								<Card className="pagebackground">
									<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
										<div className="row my-1">
											<div className="col-2">
												<div className="form-group">
													<h4 className="FilterCard" style={{ marginTop: '10px' }}>Filter Data By</h4>
												</div>
											</div>


										</div>
									</div>


									<Card style={{ padding: ' 0 20px' }}>
										<div className="row my-1 ">


											<div className="col-4">
												<div className="form-group ">

													<div class="input-group">
														<div class="input-group-prepend gridinput">
															<span class="input-group-text" id="basic-addon1">
																<span className=""><img src={Filtericon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text"
															value={this.state.SearchField}
															onChange={(text) => {
																this.setState({
																	SearchField: text.target.value
																})
															}}
															class="form-control gridinput" placeholder="Enter either name / mobile number / email / address" aria-label="Search" aria-describedby="basic-addon1" />
													</div>
												</div>
											</div>
											<div className="col-1">
												<a
													onClick={() => {
														if (this.state.SearchField != null) {
															this.onPostSearch()
														}
														else {
															Notiflix.Notify.failure('Please enter the name of Customer')

														}

													}}

													style={{

														marginTop: "15px",
														// background: 'white',
														color: 'white',
														marginLeft: '0px',
														marginRight: '4px'
													}}
													className="btn align-items-center btn Bechofy-btn "
												>
													Search
												</a>
											</div>
											<div className="col-2">

												<div className="form-group ">
													<DatePicker
														placeholderText="From Date"
														dateFormat="dd/MM/yyyy"
														selected={this.state.FromDate}
														onChange={(date) => {
															this.setState({
																FromDate: date
															})
														}}
														isClearable
														className="form-control gridinput"
														maxDate={new Date()}
													/>
													<span class="login-icon-change-pass" style={{ bottom: '30px' }}>
														<img src={Calendericon} alt="datepicker" className="btnicons"></img>
													</span>

												</div>
											</div>
											<div className="col-2">

												<div className="form-group">

													<DatePicker
														placeholderText="To Date"
														dateFormat="dd/MM/yyyy"
														selected={this.state.ToDate}
														onChange={(date) => {
															this.setState({
																ToDate: date
															})
														}}
														isClearable
														className="form-control gridinput"
														maxDate={new Date()}
													/>
													<span class="login-icon-change-pass" style={{ bottom: '30px' }}>
														<img src={Calendericon} alt="datepicker" className="btnicons"></img>
													</span>
												</div>
											</div>

											<div className="col-3">
												<a
													onClick={() => {
														if (this.state.SearchField != null || this.state.FromDate != null || this.state.ToDate != null) {
															this.onPost()
														}
														else {
															Notiflix.Notify.Failure('Please select one search criteria')

														}

													}}

													style={{

														marginTop: "15px",
														// background: 'white',
														color: 'white',
														marginLeft: '0px',
														marginRight: '4px'
													}}
													className="btn align-items-center btn Bechofy-btn "
												>
													Filter & Display
												</a>

												<a
													onClick={() => {

														Notiflix.Loading.Dots("Please wait...");

														PostApiCall.postRequest(
															{

																WhereClause: '',
																RecordCount: '*'
															},
															"GetCustomerList"
														).then((results) =>
															// const objs = JSON.parse(result._bodyText)
															results.json().then((obj) => {
																if (results.status == 200 || results.status == 201) {
																	//console.log(obj.data)
																	this.setState({
																		CustomerData: obj.data,
																		ListAllCustomerData: "Clicked"
																	});
																	Notiflix.Loading.Remove();
																}
															}))
													}}

													style={{

														marginTop: "15px",
														// background: 'white',
														color: 'white',
														marginLeft: '0px',
														marginRight: '4px'
													}}
													className="btn align-items-center btn Bechofy-btn "
												>
													List All Customers
												</a>
												<a className="btn align-items-center btn Bechofy-btn "
													style={{

														marginTop: "15px",
														// background: 'white',


													}}
													onClick={() => {
														window.location.reload();
													}}
												>
													<span className=""><img src={Refreshicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>

												</a>

											</div>


										</div>
									</Card>

									<Card >
										<div className="Bechofy-bg-soft-pink" style={{ height: '60px' }}>
											<div className="row my-1">
												<div className="col-2">
													<div className="form-group">
														<h4 className="FilterCard" style={{ marginTop: '10px' }}>Customer List</h4>
													</div>
												</div>

												<div className="col-10">
													<div className="position-relative">

														<a
															onClick={this.DownloadCSV.bind(this)}
															style={{
																float: "right",
																marginTop: "10px",
																background: 'white',
																color: 'grey',
																marginRight: '20px'
															}}
															className="btn align-items-center btn Bechofy-btn "
														>
															<span className=""><img src={Downloadicon} alt="block user" className="btnicons" style={{ marginRight: '5px' }}></img> </span>
															Download(.csv)
														</a>
													</div>


													<CSVLink
														data={this.state.fileData}
														filename="CustomerList.csv"
														className="hidden"
														ref={this.csvLink}
														target="_blank"
													/>
												</div>





											</div>
										</div>
										<CardBody className="pt-0">
											<Row>


												<MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
													<div class="input-group mb-3">


														<div class="input-group-prepend">
															<span class="input-group-text" id="basic-addon1">
																<span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
															onChange={(e) => this.seachBarHandler(e)}
															value={this.state.searchInput}

														/>
													</div>
												</MDBCol>

												<Col md="12">

													{this.state.CustomerData.length != 0 ?
														<MDBDataTable
															hover
															// scrollY
															striped
															bordered
															data={data}
															seachTop={false}
															entriesOptions={[10, 25, 50, 100]}

														>

															<MDBTableHead columns={data.columns} />
															<MDBTableBody rows={data.rows} />
														</MDBDataTable>
														:
														<p>No Data Found</p>}
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Card>

								{/* <WelcomeComp /> */}
							</Col>
						</Row>
						{/* </Card>

							</Col>
						</Row> */}
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default customerList;
