import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { Link } from "react-router-dom";

import Notiflix from "notiflix";
import PostApiCall from "../../Api";
import GetApiCall from "../../GETAPI";
import moment from "moment";
import Parser from "html-react-parser";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import GtLogo from '../../assets/images/bechofylogo.png'
import Searchicon from '../../assets/images/dashboardimages/search.png'
import Editicon from '../../assets/images/dashboardimages/pencil.png'
import deleteicon from '../../assets/images/dashboardimages/delete.png'
import Addicon from '../../assets/images/dashboardimages/add-user.png'


class userList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,

			UserData: [],
			Id: "",
			searchInput: "",
		};
	}

	componentDidMount() {
		Notiflix.Loading.Init({
			svgColor: "#777f80",
		});

		Notiflix.Loading.Dots("");

		GetApiCall.getRequest("GetUserList").then((resultdes) =>
			resultdes.json().then((obj) => {
				//console.log(obj.data);

				this.setState({
					UserData: obj.data,
				});

				Notiflix.Loading.Remove();
			})
		);
	}



	seachBarHandler = (e) => {
		this.setState({ ...this.state, searchInput: e.target.value });
	};
	render() {
		const data = {
			columns: [
				{
					label: "S.No.",
					field: "serial",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Name",
					field: "name",
					sort: "disabled",
					width: 400,
				},
				{
					label: "Designation",
					field: "designation",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Department",
					field: "department",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Mobile Number",
					field: "mobileno",
					sort: "disabled",
					width: 150,
				},
				{
					label: "Email",
					field: "email",
					sort: "disabled",
					width: 150,
				},
				{
					label: "User Type",
					field: "usertype",
					sort: "disabled",
					width: 150,
				},

				{
					label: "User Status",
					field: "status",
					sort: "disabled",
					width: 150,
				},

				{
					label: "Action",
					field: "action",
					sort: "disabled",
					width: 100,
				},
			],
			rows: this.state.UserData.filter((data) => {
				let name = [
					...data.fld_name
						.toLowerCase()
						.matchAll(this.state.searchInput.toLowerCase()),
				];
				let department = [
					...data.fld_department
						.toLowerCase()
						.matchAll(this.state.searchInput.toLowerCase()),
				];
				let designaition = [
					...data.fld_designation
						.toLowerCase()
						.matchAll(this.state.searchInput.toLowerCase()),
				];

				if (this.state.searchInput.length > 0 && name[0]) {
					return data;
				}

				if (this.state.searchInput.length > 0 && designaition[0]) {
					return data;
				}
				if (this.state.searchInput.length > 0 && department[0]) {
					return data;
				}

				if (
					this.state.searchInput.length > 0 &&
					data.fld_status.toLowerCase() === this.state.searchInput.toLowerCase()
				) {
					return data;
				}

				if (
					this.state.searchInput.length > 0 &&
					data.fld_usertype.toLowerCase() ===
					this.state.searchInput.toLowerCase()
				) {
					return data;
				}

				if (this.state.searchInput.length == 0) {
					return data;
				}
			}).map((data, i) => {
				return {
					serial: (i + 1),
					name: (data.fld_name),
					designation: (data.fld_designation),
					department: (data.fld_department),

					mobileno: (data.fld_mobile),
					email: (data.fld_email),
					usertype: (data.fld_usertype),
					status: (data.fld_status),

					action: (
						<div className="text-center actionuser">
							{/* <i className="fas fa-eye btn" style={{fontSize:'15px',marginTop:'-11px'}}></i> */}
							<img
								src={Editicon}

								style={{ fontSize: "15px", marginTop: "-11px", width: '2rem' }}
								onClick={() => {
									localStorage.setItem("userdetails", JSON.stringify(data));
									window.location.href = "/edit-user";
								}}
							/>
							<span className="btn" style={{ marginTop: "-9px" }}>
								<img src={deleteicon}

									aria-hidden="true"
									style={{ fontSize: "15px", width: '2rem' }}
									onClick={() => {
										confirmAlert({
											title: "Confirm to Delete",
											message: "Are you sure you want to delete user.",
											buttons: [
												{
													label: "Yes",
													onClick: () => {
														Notiflix.Loading.Dots("");

														PostApiCall.postRequest(
															{
																id: data.fld_userid,
																status: "Disabled",
															},
															"DeleteUser"
														).then((results) =>
															// const objs = JSON.parse(result._bodyText)
															results.json().then((obj) => {
																if (
																	results.status == 200 ||
																	results.status == 201
																) {
																	Notiflix.Loading.Remove();
																	Notiflix.Notify.Success(
																		"User successfully deleted."
																	);
																	window.location.reload();
																} else {
																	Notiflix.Loading.Remove();
																	Notiflix.Notify.Failure(
																		"Something went wrong, try again later."
																	);
																}
															})
														);
													},
												},
												{
													label: "No",
													// onClick: () => alert('Click No')
												},
											],
										});
									}}
								/>
							</span>
						</div>
					),
				};
			}),
		};

		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						{/* Render Breadcrumb */}
						<div className="row">

							<div className="col-md-6">
								<div>
									<ul class="breadcrumbs">
										<li><a href="https://store.bechofy.in/">store.bechofy.in</a></li>
										<li>User Management</li>
										<li>User List</li>
									</ul>
								</div>
							</div>
							<div className="col-md-6">
								<img src={GtLogo} alt="company logo" className="img-responsive companyLogo"></img>
							</div>


						</div>
						<Row>
							<Col xl="12">
								<Card className="overflow-hidden">


									<Card className="overflow-hidden">
										<div className="Bechofy-bg-soft-pink">
											<Row>
												<Col xs="6">
													<div className="Bechofy-text p-3">
														<h5 className="Bechofy-text">
															User List
														</h5>
													</div>
												</Col>
												<Col xs="6">
													<div className="Bechofy-text p-3">
														<Link
															to="/add-user"
															style={{
																float: "right",
																marginTop: "-5px",
																background: 'white',
																color: 'grey',
																marginRight: '10px'
															}}
															className="btn align-items-center btn Bechofy-btn "
														>
															Add New User{" "}
															<span className=""><img src={Addicon} alt="block user" className="btnicons" style={{ marginLeft: '5px' }}></img> </span>

														</Link>
													</div>
												</Col>
											</Row>
										</div>
										<CardBody className="pt-0">
											<Row></Row>
										</CardBody>
										<CardBody className="pt-0">
											<Row>
												<MDBCol md="12" style={{ marginBottom: "15px", marginTop: '15px' }} >
													<div class="input-group mb-3">


														<div class="input-group-prepend">
															<span class="input-group-text" id="basic-addon1">
																<span className=""><img src={Searchicon} alt="block user" className="btnicons"></img></span></span>
														</div>
														<input type="text" class="form-control" placeholder="Type the search phrase you wish to search within the grid" aria-label="Search" aria-describedby="basic-addon1"
															onChange={(e) => this.seachBarHandler(e)}
															value={this.state.searchInput}

														/>
													</div>
												</MDBCol>
												<Col md="12">
													<MDBDataTable
														hover
														// scrollY
														striped
														bordered
														data={data}
														seachTop={false}
														entriesOptions={[25, 50, 100]}

													>

														<MDBTableHead columns={data.columns} />
														<MDBTableBody rows={data.rows} />
													</MDBDataTable>
												</Col>
											</Row>
										</CardBody>
									</Card>

									{/* <WelcomeComp /> */}
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}
export default userList;
