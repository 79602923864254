import React, { Component } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";
import Bechofylogo from '../../assets/images/bechofylogo.png'

//i18n
import { withNamespaces } from 'react-i18next';
import Notiflix from "notiflix";

import PostApiCall from "../../Api";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isReport: false,
            Menu: [],
            SubMenu: [],
            Details: []

        }
    }

    componentDidMount() {

        Notiflix.Loading.Init({
            svgColor: '#777f80'

        });
        var matchingMenuItem = null;
        var ul = document.getElementById("navigation");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }

        // =====as admin and member======
        var login = localStorage.getItem('LoginDetail');
        var details = JSON.parse(login)
        //  //console.log(details)

        var lastlogin = localStorage.getItem('lastlogin');
        var LastDateTime = JSON.parse(lastlogin)

        // //console.log(LastDateTime)
        this.setState({
            Details: details[0],
            Logintime: LastDateTime
        })








        Notiflix.Loading.Dots('');


        PostApiCall.postRequest({

            staffid: 1,
            // staffid : details[0].fld_designation,

        }, "MenuList").then((results) =>


            results.json().then(obj => {
                // //console.log(results)
                if (results.status == 200 || results.status == 201) {

                    // //  //console.log(obj.data)
                    this.setState({
                        Menu: obj.data
                    })


                    PostApiCall.postRequest({

                        staffid: 1,
                        // staffid: details[0].fld_designation,

                    }, "SubmenuList").then((resultssub) =>

                        // const objs = JSON.parse(result._bodyText)
                        resultssub.json().then(objsub => {
                            if (resultssub.status == 200 || resultssub.status == 201) {

                                //   //console.log(objsub.data)
                                var data = []
                                var arr = new Array(Object.keys(obj.data).length).fill([])


                                if (obj.data.length == 0) {
                                    Notiflix.Loading.Remove()
                                }
                                for (var i = 0; i < Object.keys(obj.data).length; i++) {


                                    data = []
                                    for (var j = 0; j < Object.keys(objsub.data).length; j++) {

                                        if (obj.data[i].fld_menuid == objsub.data[j].fld_parentid) {

                                            data.push(objsub.data[j])
                                            arr[i] = data
                                            //   //console.log(arr)
                                            this.setState({
                                                SubMenu: arr
                                            })



                                        }

                                    }
                                    if (i == Object.keys(obj.data).length - 1) {
                                        localStorage.setItem('SubMenuRights', JSON.stringify(arr))
                                        Notiflix.Loading.Remove()
                                    }


                                }

                            } else {
                                Notiflix.Loading.Remove()
                                Notiflix.Notify.Failure(objsub.data)
                            }
                        }))
                } else {
                    Notiflix.Loading.Remove()
                    Notiflix.Notify.Failure(obj.data)
                }
            }))



    }











    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div className="topnav">
                    <div className="mx-auto">

                        <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
                            <div className="navbar-brand-box " >


                                <Link to="/dashboard" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={Bechofylogo} alt="" height="90" style={{ margin: '0px' }} />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={Bechofylogo} alt="" height="90" style={{ mdargin: '0px' }} />
                                    </span>
                                </Link>
                            </div>
                            <Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
                                <ul className="navbar-nav d-flex">
                                    {this.state.Menu && this.state.Menu.map((data, index) => {




                                        return <li className="nav-item dropdown">
                                            {data.fld_pagename != '/#' ?
                                                <Link to={`${data.fld_pagename}`} className="nav-link dropdown-toggle arrow-none" >
                                                    {this.props.t(`${data.fld_menuname}`)}
                                                </Link>
                                                : <li className="nav-item dropdown">

                                                    <a className="nav-link dropdown-toggle arrow-none" >
                                                        {this.props.t(`${data.fld_menuname}`)} <div className="arrow-down"></div>
                                                    </a>

                                                    <div
                                                        className={classname(
                                                            "dropdown-menu mega-dropdown-menu dropdown-mega-menu-md  px-2",
                                                            { show: this.state.uiState })}>

                                                        {this.state.SubMenu[index] == undefined ? <span></span> : this.state.SubMenu[index].length > 0 ?

                                                            <span>
                                                                {this.state.SubMenu[index] == undefined ? <span></span> :
                                                                    this.state.SubMenu[index].map((submenu, i) => (
                                                                        // submenu.fld_show == 1 && submenu.fld_access == 1 ? 
                                                                        <Link to={`${submenu.fld_pagename}`} className="dropdown-item">{this.props.t(`${submenu.fld_menuname}`)}</Link>
                                                                    ))}
                                                            </span> : <p></p>
                                                        }




                                                    </div>
                                                </li>}

                                        </li>
                                    })}




                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withNamespaces()(Navbar));
