import React, { Component } from "react";
import { Row, Col, CardBody, Card, Container } from "reactstrap";

import { Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/bechofylogo.png";
import Notiflix from "notiflix";
import logox from "../../assets/images/bechofylogo.png";
import PostApiCall from "../../Api";
import moment from 'moment';

class ChangePasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OldPassword: '',
      NewPassword: '',
      ConfirmPassword: '',
      OldPassword2: '',
      LoginDetail: [],

      isOldPasswordVisible: false,
      isNewPasswordVisible: false,
      isConPasswordVisible: false
    };
  }


  componentDidMount() {
    Notiflix.Loading.Init({
      svgColor: '#777f80'

    });

    var Oldpassword = localStorage.getItem('OldPassword');
    var passdetails = JSON.parse(Oldpassword)

    var login = localStorage.getItem('LoginDetail');
    var details = JSON.parse(login)
    //console.log(details)
    if (details != null) {
      this.setState({
        OldPassword2: passdetails,
        LoginDetail: details[0]
      })



    }



  }


  onClickReset() {

    if (this.state.OldPassword != '') {
      if (this.state.OldPassword == this.state.OldPassword2) {
        if (this.state.NewPassword != '') {

          if (this.state.ConfirmPassword != '') {
            if (this.state.NewPassword == this.state.ConfirmPassword) {

              Notiflix.Loading.Dots('');

              PostApiCall.postRequest({

                userid: this.state.LoginDetail.fld_userid,
                password: this.state.ConfirmPassword,
                salt: this.state.LoginDetail.fld_salt,
                updatedon: moment().format('lll'),
                updatedby: this.state.LoginDetail.fld_userid

              }, "ChangePassword").then((results) =>

                // const objs = JSON.parse(result._bodyText)
                results.json().then(obj => {


                  if (results.status == 200 || results.status == 201) {
                    Notiflix.Loading.Remove()
                    Notiflix.Notify.Success('Password Successfully updated')
                    localStorage.removeItem('LoginDetail')
                    window.location.href = '/'

                  }
                  else {
                    Notiflix.Loading.Remove()
                    Notiflix.Notify.Failure('something went wrong, try again later')
                  }
                }
                )
              )
            }

            else {
              Notiflix.Notify.Failure('New Password and Confirm Password do not match ')
            }

          }
          else {
            Notiflix.Notify.Failure('Confirm Password Cannot be empty ')
          }
        }

        else {
          Notiflix.Notify.Failure('New Password Cannot be empty');
        }
      }

      else {
        Notiflix.Notify.Failure('Incorrect Old Password');
      }
    }
    else {
      Notiflix.Notify.Failure('old Password Cannot be empty');
    }



  }



  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="Bechofy-bg-soft-pink">
                    <Row>
                      <Col className="col-7">
                        <div className="Bechofy-muted-text p-4">
                          <h5 className="Bechofy-muted-text">Welcome Back !</h5>
                          <p>Change Password to continue to Bechofy.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="#">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logox} alt="" className="rounded-circle" height="44" />

                          </span>

                        </div>
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <Link to="#">
                        <div className="avatar-md profile-user-wid mb-4 d-none d-lg-block" style={{
                          position: 'absolute',
                          left: '302px',
                          top: '-35px'
                        }}>
                          <img src={JSON.parse(localStorage.getItem("ClientDetails"))[0].fld_storelogo} alt="" height="34" />

                        </div>
                      </Link>
                    </div>



                    <div className="p-2">

                      <AvForm
                        className="form-horizontal mt-4"
                      >


                        <div className="form-group">
                          <label for="pwd">Old Password:</label>
                          <input
                            value={this.state.OldPassword} type={this.state.isOldPasswordVisible ? 'text' : "password"}
                            onChange={(pswd) => {
                              this.setState({
                                OldPassword: pswd.target.value
                              })
                            }}
                            className="form-control" id="pwd" />

                          <span class="login-icon-change-pass">
                            {/* <i class="icon-dual" data-feather="lock"></i>*/}
                            <i style={{ color: this.state.isOldPasswordVisible ? '#777f80' : '' }} className="fa fa-eye"
                              onClick={() => {
                                this.setState({
                                  isOldPasswordVisible: !this.state.isOldPasswordVisible
                                })
                              }}


                            />
                          </span>


                        </div>
                        <div className="form-group">
                          <label for="pwd">New Password:</label>
                          <input
                            value={this.state.NewPassword} type={this.state.isNewPasswordVisible ? 'text' : "password"}
                            onChange={(newpwd) => {
                              this.setState({
                                NewPassword: newpwd.target.value
                              })
                            }}
                            className="form-control" id="pwd" />
                          <span class="login-icon-change-pass">
                            {/* <i class="icon-dual" data-feather="lock"></i>*/}
                            <i style={{ color: this.state.isNewPasswordVisible ? '#777f80' : '' }} className="fa fa-eye"
                              onClick={() => {
                                this.setState({
                                  isNewPasswordVisible: !this.state.isNewPasswordVisible
                                })
                              }}


                            />
                          </span>
                        </div>


                        <div className="form-group">
                          <label for="pwd">Confirm Password:</label>
                          <input
                            value={this.state.ConfirmPassword} type={this.state.isConPasswordVisible ? 'text' : "password"}
                            onChange={(confirmpwd) => {
                              this.setState({
                                ConfirmPassword: confirmpwd.target.value
                              })
                            }}
                            className="form-control" id="pwd" />
                          <span class="login-icon-change-pass">
                            {/* <i class="icon-dual" data-feather="lock"></i>*/}
                            <i style={{ color: this.state.isConPasswordVisible ? '#777f80' : '' }} className="fa fa-eye"
                              onClick={() => {
                                this.setState({
                                  isConPasswordVisible: !this.state.isConPasswordVisible
                                })
                              }}


                            />
                          </span>
                        </div>


                        <Row className="form-group">
                          <Col className="text-right">
                            <button
                              className="btn Bechofy-btn w-md waves-effect waves-light"
                              type="submit"
                              onClick={this.onClickReset.bind(this)}
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link
                      to="pages-login"
                      className="font-weight-medium Bechofy-text"
                    >
                      Login
                    </Link>{" "}
                  </p>
                  <p>© {new Date().getFullYear()} Bechofy. Powered  <i className="mdi mdi-heart text-danger"></i> by Global Trendz</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
export default ChangePasswordPage;
